import React from 'react';

const External = ({ width = 14, height = 14, fill = '#003DA6' }) => (
  <svg
    className="external-link-icon"
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 32 32"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M27.5,3.5 L4.5,3.5 C3.946875,3.5 3.5,3.946875 3.5,4.5 L3.5,27.5 C3.5,28.053125 3.946875,28.5 4.5,28.5 L15.75,28.5 C15.8875,28.5 16,28.3875 16,28.25 L16,26.5 C16,26.3625 15.8875,26.25 15.75,26.25 L5.75,26.25 L5.75,5.75 L26.25,5.75 L26.25,15.75 C26.25,15.8875 26.3625,16 26.5,16 L28.25,16 C28.3875,16 28.5,15.8875 28.5,15.75 L28.5,4.5 C28.5,3.946875 28.053125,3.5 27.5,3.5 Z M24.0896885,25.7771545 L22.4584385,27.4084045 C22.3910205,27.4761565 22.3680701,27.5763873 22.3992897,27.6667248 C22.4305093,27.7570623 22.5104485,27.8217356 22.6053135,27.8334045 L28.2115635,28.4896545 C28.3709385,28.5084045 28.5084385,28.3740295 28.4896885,28.2115295 L27.8334385,22.6052795 C27.8084385,22.3990295 27.5553135,22.3115295 27.4084385,22.4584045 L25.7709385,24.0959045 L17.7646885,16.0896545 C17.6678135,15.9927795 17.5084385,15.9927795 17.4115635,16.0896545 L16.0865635,17.4146545 C15.9896885,17.5115295 15.9896885,17.6709045 16.0865635,17.7677795 L24.0896885,25.7771545 Z"
      transform="rotate(-90 16 16)"
    />
  </svg>
);

export default External;
