import React from 'react';
import { connect } from 'react-redux';

import MenuItem from './MenuItem';
import { PadLock } from './NavItem';

const SubTitle = ({
  memberLevels,
  role,
  isLoggedIn,
  title,
  locked = true,
  subsub = false,
  ...props
}) => {
  const { dispatch, ..._props } = props;
  return (
    <MenuItem type="subTitle" {..._props} subsub={subsub}>
      <PadLock locked={locked} isLoggedIn={isLoggedIn} role={role} memberLevels={memberLevels} />
      <span>{title}</span>
    </MenuItem>
  );
};

export default connect(
  state => {
    const { auth } = state;
    const { isLoggedIn, role } = auth;
    return { isLoggedIn, role };
  },
  null
)(SubTitle);
