/* eslint-disable no-param-reassign */
import { css } from '@emotion/react';

const m = {};
const p = {};
const sizes = ['0', '4', '8', '12', '20', '16', '24', '32', '40'];

[p, m].forEach(type => {
  sizes.forEach(size => {
    type[`r${size}`] = css`
      margin-right: ${size}px !important;
    `;
    type[`l${size}`] = css`
      margin-left: ${size}px !important;
    `;
    type[`t${size}`] = css`
      margin-top: ${size}px !important;
    `;
    type[`b${size}`] = css`
      margin-bottom: ${size}px !important;
    `;
    type[`x${size}`] = css`
      margin-left: ${size}px !important;
      margin-right: ${size}px !important;
    `;
    type[`y${size}`] = css`
      margin-top: ${size}px !important;
      margin-bottom: ${size}px !important;
    `;
    type[`xy${size}`] = css`
      margin: ${size}px !important;
    `;
  });
});

export default { m, p };
