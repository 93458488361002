import React from 'react';

const SvgTransit = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M27.975 0c4.436 0 8.046 3.614 8.046 8.055v3.304h2.824c3.662 0 6.642 2.984 6.642 6.652V47.33a.946.946 0 01-.947.947h-.593l5.127 6.172a.947.947 0 01-1.457 1.21l-6.132-7.382H14.808l-6.133 7.382a.947.947 0 01-1.457-1.21l5.128-6.172h-.937a.946.946 0 01-.946-.947V18.011c0-3.668 2.98-6.652 6.642-6.652h2.824V8.055c0-4.441 3.61-8.055 8.046-8.055zm0 1.893c-3.393 0-6.153 2.764-6.153 6.162v4.25a.946.946 0 01-.947.947h-3.77c-2.62 0-4.749 2.135-4.749 4.76v28.37h31.237v-28.37a4.759 4.759 0 00-4.748-4.76h-3.77a.946.946 0 01-.947-.946V8.055c0-3.398-2.76-6.162-6.153-6.162zm-9.94 34.077a2.846 2.846 0 012.84 2.846v2.828a2.846 2.846 0 01-2.84 2.846 2.846 2.846 0 01-2.84-2.846v-2.828a2.846 2.846 0 012.84-2.846zm19.879 0a2.846 2.846 0 012.84 2.846v2.828a2.846 2.846 0 01-2.84 2.846 2.846 2.846 0 01-2.84-2.846v-2.828a2.846 2.846 0 012.84-2.846zm-19.878 1.894a.94.94 0 00-.947.952v2.828a.94.94 0 00.947.953.94.94 0 00.946-.953v-2.828a.94.94 0 00-.946-.952zm19.878 0a.94.94 0 00-.947.952v2.828a.94.94 0 00.947.953.94.94 0 00.946-.953v-2.828a.94.94 0 00-.946-.952zm2.84-20.825c.523 0 .946.424.946.946v12.306a.946.946 0 01-.946.946H15.196a.946.946 0 01-.947-.946V17.985c0-.522.424-.946.947-.946zm-.947 1.893H16.142v10.412h23.665V18.932zm-19.601 1.224a.946.946 0 111.339 1.338l-2.84 2.84a.943.943 0 01-1.339 0 .946.946 0 010-1.338zm7.769-16.37a4.265 4.265 0 014.26 4.26 4.265 4.265 0 01-4.26 4.26 4.264 4.264 0 01-4.26-4.26c0-2.349 1.91-4.26 4.26-4.26zm0 1.894a2.369 2.369 0 00-2.367 2.366 2.369 2.369 0 002.367 2.366 2.369 2.369 0 002.366-2.366 2.369 2.369 0 00-2.366-2.366z"
    />
  </svg>
);

export default SvgTransit;
