import React from 'react';

const SvgTracking = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M28 0c15.44 0 28 12.56 28 28S43.44 56 28 56 0 43.44 0 28v-1.037h26.963V0zm1.037 2.094v26.943H2.094C2.641 42.854 14.052 53.926 28 53.926c14.296 0 25.926-11.63 25.926-25.926 0-13.948-11.072-25.36-24.889-25.906zm20.74 24.869c.573 0 1.038.464 1.038 1.037 0 12.58-10.235 22.815-22.815 22.815a1.037 1.037 0 010-2.074c11.436 0 20.74-9.305 20.74-20.741 0-.573.465-1.037 1.038-1.037zM22.816 10.37v14.52H20.74V10.37h2.074zm-4.148 5.186v9.333h-2.074v-9.333h2.074zm-4.148-7.26V24.89h-2.075V8.296h2.075zm-4.149-3.11v19.703H8.296V5.185h2.074zm-4.148 7.258V24.89H4.148V12.444h2.074z"
    />
  </svg>
);

export default SvgTracking;
