/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 0,
  isMailingAddressDisabled: true,
  isOtherCountryVisible: false,
  isOtherMailingStateVisible: false,
  isOtherOfficeStateVisible: false,
  isInformalNameSameAsName: true,
  Prefix: '',
  First: '',
  Middle: '',
  Last: '',
  Suffix: '',
  Informal: '',
  Title: '',
  Company: '',
  StreetAddress1: '',
  StreetAddress2: 'STE ',
  City: '',
  StateCode: '',
  PostalCode: '',
  CountryCode: 'USA',
  OtherCountryCode: '',
  PoBoxNumber: '',
  PoBoxCity: '',
  PoBoxStateCode: '',
  PoBoxPostalCode: '',
  Number: '',
  Extension: '',
  Mobile: '',
  FaxNumber: '',
  EmailAddress: '',
  ConfirmEmailAddress: '',
  Password: '',
  ConfirmPassword: '',
  SecurityQuestionId: '',
  SecurityAnswer: '',
  Country: '',
  OfficeStateOther: '',
  PoBoxOther: '',
  Comments: '',
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    handleFormChange(state, action) {
      state[action.payload.name] = action.payload.value;
    },
    handleFormUpdate(state, action) {
      state = {
        ...state,
        ...action.payload,
      };
    },
    resetForm(state, action) {
      state = initialState;
    },
  },
});

export const { resetForm, handleFormChange, handleFormUpdate } = registerSlice.actions;

export default registerSlice.reducer;
