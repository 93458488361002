import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import React from 'react';

const style = {
  container: css`
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  `,
  size: {
    lg: css`
      max-width: 1200px;
    `,
    md: css`
      max-width: 1000px;
    `,
    sm: css`
      max-width: 800px;
    `,
  },
};

const Container = ({ children, size, ...props }) => (
  <div css={[style.container, style.size[size]]} {...props}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['sm', 'lg']),
};
Container.defaultProps = {
  size: 'lg',
};

export default Container;
