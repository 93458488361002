import { css, jsx } from '@emotion/core';
import { Divider } from 'antd';
import React, { memo } from 'react';
import { connect } from 'react-redux';

import CustomIcon from '@/src/shared/components/Icon';
import { colors } from '@/styles';

import { ADVOCACY_ACCESS } from '../../constants';
import { MenuItem, NavItem, PadLock, States, SubMenu, SubTitle, TopMenuItem } from './components';

const isBrowser = typeof window !== 'undefined';
const origin = isBrowser ? window.location.origin : '';

const style = {
  divider: css`
    background-color: ${colors.primaryBlue};
  `,
};

const NavAdvocacy = memo(props => {
  const { isLoggedIn, role, dispatch, ..._props } = props;
  return (
    <TopMenuItem
      {..._props}
      title={<span>Advocacy &amp; Policy</span>}
      popupClassName="ant-menu-large"
      className="hideIfClosed"
    >
      {/* Priority Issues */}
      <NavItem
        type="default"
        path="/priorityissues"
        locked={false}
        title="Priority Advocacy Issues"
      />

      {/* State */}
      <SubMenu title={<SubTitle title="State" />}>
        <States />
        <NavItem path="/advocacy/state/capital-bulletin" />
        <NavItem path="/advocacy/state/environment-report" />
        <NavItem path="/advocacy/state/legislative-preview" />
        <NavItem path="/advocacy/state/legislative-wrap-up" />
      </SubMenu>
      {/* Federal */}
      <SubMenu title={<SubTitle title="Federal" />}>
        <NavItem path="/advocacy/federal/update" />

        {/* <NavItem path="/advocacy/federal/federal-legislation-tracker" /> */}
        {/* <NavItem path="/advocacy/federal/legislator-biographies" /> */}
        <NavItem path="/advocacy/federal/bulletin" />
      </SubMenu>
      {/* International */}
      <NavItem type="default" path="/advocacy/international" locked />
      {/* Policy-Making */}
      <SubMenu title={<SubTitle title="Policy-Making" />}>
        {/*
        <SubMenu subsub title={<SubTitle subsub title="Committees &amp; Working Groups" />}>
          <NavItem path="/policy/committees" />
          <NavItem path="/policy/committees/advisory-lists" />
        </SubMenu>
        */}
        <NavItem
          path="/policy/committees"
          title="Committees"
          memberLevels={ADVOCACY_ACCESS.POLICY_COMMITTEES}
        />
        <NavItem path="/advocacy/policy-making/naic" />
      </SubMenu>
      {/* Legal / Judicial Advocacy */}
      <SubMenu title={<SubTitle title="Legal/Judicial Advocacy" />}>
        <NavItem
          path="/advocacy/legal-judicial-advocacy/amicus"
          memberLevels={ADVOCACY_ACCESS.LEGAL_AMICUS}
        />
        <NavItem
          path="/advocacy/legal-judicial-advocacy/named-party"
          memberLevels={ADVOCACY_ACCESS.LEGAL_NAMED_PARTY}
        />
        <NavItem path="/advocacy/state/environment-report" />
        <NavItem path="/advocacy/legal-judicial-advocacy/litigation-bulletin" />
        {/* <NavItem path="/advocacy/legal-judicial-advocacy/continuing-legal-education" /> */}
        {/* <NavItem path="/advocacy/legal-judicial-advocacy/legal-resources" /> */}
      </SubMenu>
      <MenuItem>
        <a href={`${origin}/statenet-redirect/maptrack`} target="_blank" rel="noopener noreferrer">
          <PadLock isLoggedIn={isLoggedIn} role={role} />
          <span>Legislation and Regulation Database</span>
          <CustomIcon name="External" />
        </a>
      </MenuItem>
      <MenuItem>
        <a
          href="https://voiceofamericasinsurers.org/pac-info"
          target="_blank"
          rel="noopener noreferrer"
        >
          Insuring America PAC
          <CustomIcon name="External" />
        </a>
      </MenuItem>
      <MenuItem>
        <a href="https://voiceofamericasinsurers.org" target="_blank" rel="noopener noreferrer">
          Voice of America&apos;s Insurers Grassroots
          <CustomIcon name="External" />
        </a>
      </MenuItem>
      <Divider css={style.divider} />
      <NavItem type="default" path="/research-papers" locked={false} />
    </TopMenuItem>
  );
});

export default connect(
  state => {
    const { auth } = state;
    const { isLoggedIn, role } = auth;
    return { isLoggedIn, role };
  },
  null
)(NavAdvocacy);
