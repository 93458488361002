export const primaryColor = `#003DA6`;
export const primaryBlue = `#003DA6`;
export const primaryRed = `#EA002A`;
export const secondaryRed = `#AA2D2A`;
export const primaryYellow = `#FFFF00`;
export const secondary = `#0075BE`;
export const secondaryBlue = `#0075BE`;
export const secondaryBlueDark = `#00407D`;
export const secondaryGray = `#57575c`;
export const secondaryGrey = `#57575c`;
export const secondaryGrayDark = `#3D3D41`;
export const secondaryGreyDark = `#3D3D41`;
export const error = `#AA2D2A`;
export const accentGoldDark = `#EAAF0F`;
export const accentGoldMedium = `#FFD141`;
export const accentGoldLight = `#F9E08E`;
export const accentRustDark = `#AA2D2A`;
export const accentRustMedium = `#D25F15`;
export const accentRustLight = `#F0A055`;
export const accentBlueLight = `#C5E3FC`;
export const accentBlueMedium = `#44C8F5`;
export const accentVioletDark = `#410099`;
export const accentVioletMedium = `#8B86EA`;
export const accentVioletLight = `#C3B4E3`;
export const accentVioletUltralight = `#DDDBEA`;
export const accentGrayMedium = `#A8A8AA`;
export const accentGrayLight = `#DAD9d7`;
export const accentGrayUltralight = `#F6F5F5`;
export const accentGreenDark = `#0A573D`;
export const accentGreenMedium = `#799C4B`;
export const accentGreenLight = `#84C770`;
export const accentGreenUltralight = `#DBF4C1`;
export const black = `#232020`;
export const white = `#FFF`;
