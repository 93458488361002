import React from 'react';

const SvgRelatedInfo = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M52.143 0c.63 0 1.143.511 1.143 1.143v53.714c0 .632-.512 1.143-1.143 1.143h-40A1.143 1.143 0 0111 54.857V43.43H7.003a4.006 4.006 0 01-4.003-4c0-2.206 1.796-4 4.003-4H11V32H7.003A4.006 4.006 0 013 28c0-2.205 1.796-4 4.003-4H11v-3.429H7.003a4.006 4.006 0 01-4.003-4c0-2.205 1.796-4 4.003-4H11V1.143C11 .51 11.512 0 12.143 0zM51 2.286H13.286V12.57h3.996a4.006 4.006 0 014.004 4c0 2.206-1.796 4-4.004 4h-3.996V24h3.996a4.006 4.006 0 014.004 4c0 2.205-1.796 4-4.004 4h-3.996v3.429h3.996a4.006 4.006 0 014.004 4c0 2.205-1.796 4-4.004 4h-3.996v10.285H51V2.286zm-3.429 44.571a1.143 1.143 0 110 2.286h-24a1.143 1.143 0 110-2.286zm0-5.714a1.143 1.143 0 110 2.286h-24a1.143 1.143 0 110-2.286zm-30.289-3.429H7.003c-.947 0-1.717.77-1.717 1.715 0 .945.77 1.714 1.717 1.714h10.28c.946 0 1.717-.77 1.717-1.714 0-.946-.77-1.715-1.718-1.715zm30.29-2.285a1.143 1.143 0 110 2.285h-24a1.143 1.143 0 110-2.285zm0-5.715a1.143 1.143 0 110 2.286h-24a1.143 1.143 0 110-2.286zm-30.29-3.428H7.003c-.947 0-1.717.769-1.717 1.714s.77 1.714 1.717 1.714h10.28c.946 0 1.717-.769 1.717-1.714s-.77-1.714-1.718-1.714zM47.572 24a1.143 1.143 0 110 2.286h-24a1.143 1.143 0 110-2.286zm0-5.714a1.143 1.143 0 110 2.285h-24a1.143 1.143 0 110-2.285zm-30.29-3.429H7.003c-.947 0-1.717.77-1.717 1.714 0 .946.77 1.715 1.717 1.715h10.28c.946 0 1.717-.77 1.717-1.715 0-.945-.77-1.714-1.718-1.714zm30.29-2.286a1.143 1.143 0 110 2.286h-24a1.143 1.143 0 110-2.286zm0-8c.63 0 1.142.512 1.142 1.143v3.429c0 .632-.511 1.143-1.143 1.143H35a1.143 1.143 0 01-1.143-1.143V5.714c0-.631.512-1.143 1.143-1.143zm-1.143 2.286H36.143V8h10.286V6.857z"
    />
  </svg>
);

export default SvgRelatedInfo;
