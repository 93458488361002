// eslint-disable-next-line
const requirePagemap = dir => require(`@/pages/${dir}/pagemap.json`);
const rootPagemap = require('@/pages/rootPagemap.json');

// See "URL Schema - Front End" Google Sheet

module.exports = {
  ...requirePagemap('about'), // about pagemap
  ...requirePagemap('advocacy'), // advocacy pagemap
  ...requirePagemap('compliance'), // compliance pagemap
  ...requirePagemap('events'), // events pagemap
  ...requirePagemap('policy'), // policy pagemap
  ...requirePagemap('media'), // press pagemap
  ...requirePagemap('publications'), // publications pagemap
  ...requirePagemap('socialequity'), // about pagemap
  ...requirePagemap('register'), // register pagemap
  ...requirePagemap('search'), // search pagemap
  ...requirePagemap('account'), // account pagemap,
  ...requirePagemap('covid-19-resources'), // covid resources pagemap
  ...rootPagemap,
};
