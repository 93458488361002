import {
  SET_CURRENT_PAGE,
  SET_DEFAULT_SORT_SEARCH,
  SET_SORT_FIELD,
  SET_SORT_ORDER,
} from '../types/searchOptionsTypes';

const DEFAULT_SEARCH_OPTIONS_STATE = {
  isFetchingSearchOptions: false,
  defaultSortSearch: true,
  searchOptions: {
    SearchText: null,
    SortField: 'RELEVANCE',
    SortOrder: 'DESC',
    Page: 1,
  },
};

const SearchOptionsReducer = (state = DEFAULT_SEARCH_OPTIONS_STATE, action) => {
  const { type } = action;

  switch (type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          Page: action.payload.Page,
        },
      };
    case SET_SORT_ORDER:
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          SortOrder: action.payload.SortOrder,
        },
      };
    case SET_SORT_FIELD:
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          SortField: action.payload.SortField,
        },
      };
    case SET_DEFAULT_SORT_SEARCH:
      return {
        ...state,
        defaultSortSearch: action.payload,
      };
    default:
      return state;
  }
};
export default SearchOptionsReducer;
