const ICON_MAP = {
  '1': 'AgentsBrokersProducers',
  '2': 'Auto',
  '3': 'Bank',
  '4': 'Bulletin',
  '5': 'Calendar',
  '6': 'CeoCommunications',
  '7': 'Claims',
  '8': 'Complaints',
  '9': 'ConferenceCall',
  '10': 'Construction',
  '11': 'Corporation',
  '12': 'Credit',
  '13': 'Cyber',
  '14': 'Data',
  '15': 'DriverLicensing',
  '16': 'Drones',
  '17': 'Environmental',
  '18': 'Financial',
  '19': 'Fraud',
  '20': 'Gavel',
  '21': 'Hazard',
  '22': 'HumanResources',
  '23': 'Hurricanes',
  '24': 'IndustryTrends',
  '25': 'Insurance',
  '26': 'International',
  '27': 'Law',
  '28': 'LegislativePolitical',
  '29': 'LegislativePriorities',
  '30': 'Marketing',
  '31': 'Medical',
  '32': 'Meeting',
  '33': 'Plans',
  '34': 'Politics',
  '35': 'Privacy',
  '36': 'RegulatoryClimate',
  '37': 'RelatedInfo',
  '38': 'Search',
  '39': 'Sharing',
  '40': 'Snapshot',
  '41': 'Tax',
  '42': 'Telephone',
  '43': 'Tracking',
  '44': 'Transit',
  '45': 'Video',
  '46': 'Webinar',
  '47': 'WorkersCompensation',
};

export default ICON_MAP;
