import React from 'react';

const SvgLegislativePolitical = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M34.386 2c.542 0 .982.44.982.982v4.913c0 .543-.44.982-.982.982h-5.895v1.992c4.067.252 7.36 3.589 7.805 7.833h7.915c.542 0 .982.44.982.982v3.93h3.93c.542 0 .982.44.982.982v10.282l5.458 3.638c.273.182.437.49.437.817v13.755c0 .543-.44.982-.982.982H.982A.982.982 0 010 53.088V39.333c0-.328.164-.635.437-.817l5.458-3.638V24.596c0-.543.44-.982.982-.982h3.93v-3.93c0-.543.44-.982.982-.982h7.915c.409-3.897 3.218-7.03 6.822-7.714V2zM5.895 37.239l-3.93 2.62v12.246h3.93V37.24zm42.245-11.66H7.86v26.526h3.93V38.351c0-.543.44-.983.982-.983h3.93c.542 0 .982.44.982.983v13.754h2.948V38.351c0-.543.44-.983.982-.983h3.93c.542 0 .982.44.982.983v13.754h2.948V38.351c0-.543.44-.983.982-.983h3.93c.542 0 .982.44.982.983v13.754h2.948V38.351c0-.543.44-.983.982-.983h3.93c.543 0 .983.44.983.983v13.754h3.93V25.58zM15.72 39.333h-1.966v12.772h1.965V39.333zm8.841 0h-1.965v12.772h1.965V39.333zm8.843 0h-1.965v12.772h1.965V39.333zm8.842 0H40.28v12.772h1.965V39.333zm7.86-2.094v14.866h3.93V39.86l-3.93-2.62zm-33.404-8.713c.542 0 .982.44.982.983v3.93c0 .543-.44.982-.982.982h-3.93a.982.982 0 01-.983-.982v-3.93c0-.543.44-.983.983-.983zm8.842 0c.542 0 .982.44.982.983v3.93c0 .543-.44.982-.982.982h-3.93a.982.982 0 01-.982-.982v-3.93c0-.543.44-.983.982-.983zm8.842 0c.542 0 .982.44.982.983v3.93c0 .543-.44.982-.982.982h-3.93a.982.982 0 01-.982-.982v-3.93c0-.543.44-.983.982-.983zm8.842 0c.543 0 .983.44.983.983v3.93c0 .543-.44.982-.983.982h-3.93a.982.982 0 01-.982-.982v-3.93c0-.543.44-.983.982-.983zM15.72 30.491h-1.965v1.965h1.965v-1.965zm8.842 0h-1.965v1.965h1.965v-1.965zm8.843 0H31.44v1.965h1.965v-1.965zm8.842 0H40.28v1.965h1.965v-1.965zm.982-9.824H12.772v2.947h30.456v-2.947zM28 12.807c-3.21 0-5.868 2.568-6.313 5.895h12.626c-.445-3.327-3.102-5.895-6.313-5.895zm5.404-8.842H28.49v2.947h4.913V3.965z"
    />
  </svg>
);

export default SvgLegislativePolitical;
