import { remove } from 'lodash';

import { addTransformUtils } from '@/utils/formHelpers';
import isObjectEmpty from '@/utils/isObjectEmpty';
/**
 * @param {object} form - key for array of objects
 * @returns {Boolean} - true: all fields are empty; false: at least one field has a value
 */

export function areAllFieldsEmpty(form) {
  const { getFieldsValue } = form;
  const fieldsValues = getFieldsValue();
  const _copy = { ...fieldsValues };

  const { FieldOptions: fields } = _copy;

  // eslint-disable-next-line compat/compat
  const values = Object.values(fields);

  remove(values, field => {
    if (Array.isArray(field)) {
      if (field.length < 1) {
        return true;
      }
    } else if (!field) {
      return true;
    }
    return false;
  });

  return values.length < 1;
}

export function parseUrl(searchOptions, router) {
  const formattedQueryGroup = {};
  const { query: queryGroup, asPath: url } = router;

  if (isObjectEmpty(queryGroup)) {
    return;
  }

  const hasQueryInitializer = url.indexOf('?') > -1;

  if (!hasQueryInitializer) {
    return;
  }

  formattedQueryGroup.PageSize = 10;
  formattedQueryGroup.Page = 1;

  const queryKeys = Object.keys(queryGroup);

  queryKeys.forEach(k => {
    const _k = k.toLowerCase();
    switch (_k) {
      case 'states':
        formattedQueryGroup.States = queryGroup[k].split(',');
        break;
      case 'linesofbusiness':
        formattedQueryGroup.LinesOfBusiness = queryGroup[k].split(',');
        break;
      case 'publications':
        formattedQueryGroup.Publications = queryGroup[k].split(',');
        break;
      case 'dayranges':
        formattedQueryGroup.DayRanges = queryGroup[k].split(',');
        break;
      case 'subjects':
        formattedQueryGroup.Subjects = queryGroup[k].split(',');
        break;
      case 'searchtext':
        formattedQueryGroup.SearchText = queryGroup[k];
        break;
      default:
        break;
    }
  });

  const copyFieldOptions = addTransformUtils(formattedQueryGroup, searchOptions);

  queryKeys.forEach(k => {
    switch (k) {
      case 'states':
        formattedQueryGroup.States = copyFieldOptions.getFullComplex('States', 'StateCode');
        return formattedQueryGroup;
      case 'linesofbusiness':
        formattedQueryGroup.LinesOfBusiness = copyFieldOptions.getFullComplex(
          'LinesOfBusiness',
          'LobCode'
        );
        return formattedQueryGroup;
      case 'publications':
        formattedQueryGroup.Publications = copyFieldOptions.getFullComplex(
          'Publications',
          'PublicationCode'
        );
        return formattedQueryGroup;
      case 'dayranges':
        formattedQueryGroup.DayRanges = copyFieldOptions.getFullComplex(
          'DayRanges',
          'DayRangeCode'
        );
        return formattedQueryGroup;
      case 'subjects':
        formattedQueryGroup.Subjects = copyFieldOptions.getFullComplex('Subjects', 'SubjectCode');
        return formattedQueryGroup;
      default:
        return formattedQueryGroup;
    }
  });
  // console.log({formattedQueryGroup})

  // eslint-disable-next-line consistent-return
  return formattedQueryGroup;
}

export const defaultSort = ({ SearchText }) => {
  if (SearchText) {
    return ['RELEVANCE', 'DESC'];
  }
  return ['CREATEDDATE', 'DESC'];
};

export const getSearchItemsByCount = (SearchOptions, Count) => {
  return {
    SearchOptions: {
      ...SearchOptions,
      FieldOptions: {
        ...SearchOptions.FieldOptions,
        Page: 1,
        PageSize: Count,
      },
    },
  };
};
