import { useAuth0 } from '@auth0/auth0-react';
import { css } from '@emotion/react';
import { Icon, Menu, Modal } from 'antd';
import React from 'react';

import buttonStyle from '@/src/shared/components/Button/style';
import { bp, colors } from '@/styles';

import Divider from '../../Divider';
import { dropdownOverlayMenu } from '../NavAccount';
import MenuItem from './MenuItem';
import NavItem from './NavItem';

const mobileDivider = css`
  display: block;
  @media ${bp.lg} {
    display: none;
  }
`;

const buttonContainer = css`
  padding-bottom: 5rem !important;

  @media ${bp.lg} {
    padding-bottom: 0 !important;
  }
`;

const logoutButton = css`
  border: 2px solid ${colors.primaryBlue};
  width: 100%;
  text-align: center;
  border-radius: 999px;

  &:hover {
    color: ${colors.white} !important;
    background-color: ${colors.primaryBlue};
  }

  @media ${bp.lg} {
    border: none;
    text-align: left;

    &:hover {
      background: none;
      color: ${colors.primaryRed} !important;
    }
  }
`;

function AccountMenu({ isAdmin, isLoggedIn, isAssessmentUser, ..._props }) {
  const { logout } = useAuth0();

  function handleLogout() {
    Modal.confirm({
      title: 'Are you sure you want to log out?',
      keyboard: true,
      okText: 'Yes',
      cancelText: 'Cancel',
      cancelButtonProps: { css: [buttonStyle.size.small, buttonStyle.type.gray] },
      okButtonProps: { css: [buttonStyle.size.small, buttonStyle.type.default] },
      onOk() {
        logout({
          logoutParams: {
            returnTo: process.env.API_ROOT,
          },
        });
      },
      onCancel() {},
    });
  }

  return (
    <Menu className="hideIfClosed" css={dropdownOverlayMenu}>
      <Divider type="small" css={mobileDivider} />
      <NavItem {..._props} path="/daily-digest" type="bold" locked={false} title="Daily Digest" />
      <Divider type="small" />
      {isAdmin && (
        <MenuItem type="bold" {..._props}>
          <a href="/admin/">Staff Portal</a>
        </MenuItem>
      )}
      {isAssessmentUser && (
        <NavItem
          {..._props}
          path="/account/invoice-portal"
          type="bold"
          locked={false}
          title="Invoice Portal"
        />
      )}
      {(isAdmin || isAssessmentUser) && <Divider type="small" />}

      <NavItem
        {..._props}
        path="/account/update-profile"
        type="bold"
        locked={false}
        title="Update Profile"
      />

      {!isAdmin && (
        <NavItem
          {..._props}
          path="/account/change-password"
          type="bold"
          locked={false}
          title="Change Password"
        />
      )}

      <NavItem
        {..._props}
        path="/account/subscription-preferences"
        type="bold"
        locked={false}
        title="Subscription Preferences"
      />

      <NavItem
        {..._props}
        path="/account/contact-lists"
        type="bold"
        locked={false}
        title="My Contact Lists"
      />

      <NavItem
        {..._props}
        path="/account/my-committees"
        type="bold"
        locked={false}
        title="My Committees"
      />

      <Divider type="small" />
      <MenuItem type="bold" {..._props}>
        <a href="/register">Register a New User</a>
      </MenuItem>
      <Divider type="small" />

      {isLoggedIn && (
        <MenuItem type="bold" {..._props} css={buttonContainer}>
          <a onClick={() => handleLogout()} css={logoutButton}>
            <Icon type="logout" /> Log Out
          </a>
        </MenuItem>
      )}
    </Menu>
  );
}

export default AccountMenu;
