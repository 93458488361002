import React from 'react';

const SvgTax = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M31.01 2a1 1 0 011 1v10.593c5.777 1.727 10.003 7.084 10.003 13.415 0 3.36-1.192 6.448-3.173 8.864L56 53.032l-1.414 1.414-17.13-17.13a13.985 13.985 0 01-5.446 3.106v7.592a1 1 0 01-1 1H1a1 1 0 01-1-1V3a1 1 0 011-1zm-1 2H2v43.014h28.01v-6.161c-.656.094-1.32.16-2.001.16-1.826 0-3.567-.362-5.168-1.001H7.002v-2h12.372a14.07 14.07 0 01-4-5.002H7.003v-2h7.592a13.93 13.93 0 01-.59-4.002c0-.681.066-1.346.16-2H7.002v-2.001h7.592a13.98 13.98 0 012.703-5.002H7.002v-2h12.372a13.918 13.918 0 018.635-3.002c.68 0 1.345.066 2 .16V4.001zm-2.001 11.004c-6.62 0-12.004 5.385-12.004 12.004 0 6.62 5.385 12.004 12.004 12.004 6.62 0 12.004-5.385 12.004-12.004 0-6.62-5.385-12.004-12.004-12.004zM27.096 17a1 1 0 011 1v1.013c.043-.001.083-.013.126-.013a4.506 4.506 0 014.501 4.502 1 1 0 11-2 0 2.502 2.502 0 00-5.002 0c0 1.38 1.121 2.5 2.5 2.5a4.506 4.506 0 014.502 4.502 4.506 4.506 0 01-4.501 4.501c-.044 0-.084-.011-.128-.012l.003.012v1a1 1 0 11-2 0v-1c0-.178.058-.336.14-.481-1.485-.737-2.517-2.253-2.517-4.02a1 1 0 112.001 0 2.502 2.502 0 005.002 0c0-1.38-1.122-2.5-2.501-2.5A4.506 4.506 0 0123.72 23.5c0-1.71.969-3.181 2.376-3.943v-1.559a1 1 0 011-1zm-2.088-7.997v2H7.002v-2h18.006z"
    />
  </svg>
);

export default SvgTax;
