import React from 'react';

const SvgMarketing = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M28.381 7.178c.378-.237.86-.237 1.238 0l9.048 5.656H43c.645 0 1.166.521 1.166 1.166v2.414c.092.03.186.051.27.105l10.863 7c.332.215.534.585.534.981v30.333c0 .645-.522 1.167-1.167 1.167h-52.5A1.166 1.166 0 011 54.833V24.5c0-.396.202-.766.534-.98l10.863-7c.084-.055.178-.076.27-.106V14c0-.645.521-1.166 1.166-1.166h5.5zm-.534 33.825L5.685 53.667h45.379L27.847 41.003zm25.653-3.93l-13.244 8.04L53.5 52.339V37.073zm-50.167 0v15.25l12.94-7.394-12.94-7.856zm38.5-21.906H15v26.25l-.002.01 3.59 2.18 8.666-4.953a1.17 1.17 0 011.137-.011l9.497 5.18 3.947-2.397-.002-.01v-26.25zm2.333 3.955v20.889l9.334-5.667v-9.208l-9.334-6.014zm-31.5 0l-9.333 6.014v9.208l9.334 5.667V19.122zm24.5 14.711v2.334H18.5v-2.334h18.666zm0-4.666V31.5H18.5v-2.333h18.666zm-3.5-11.667c.645 0 1.167.522 1.167 1.167v7c0 .645-.522 1.166-1.166 1.166h-14a1.166 1.166 0 01-1.167-1.166v-7c0-.645.522-1.167 1.167-1.167zM32.5 19.834H20.833V24.5H32.5v-4.666zm20.8-11.95a1.166 1.166 0 111.649 1.65l-4.124 4.124a1.163 1.163 0 01-1.65 0 1.166 1.166 0 010-1.65zm-51.416 0a1.166 1.166 0 011.65 0l4.124 4.125a1.166 1.166 0 11-1.65 1.65L1.884 9.533a1.166 1.166 0 010-1.65zM29 9.544l-5.265 3.29h10.53L29 9.544zM41.916.733a1.167 1.167 0 012.167.866L41.75 7.433a1.166 1.166 0 11-2.167-.866zM14.567.084a1.168 1.168 0 011.516.65l2.334 5.833a1.167 1.167 0 01-2.167.866L13.916 1.6a1.167 1.167 0 01.651-1.517z"
    />
  </svg>
);

export default SvgMarketing;
