import { Button as AntButton, message } from 'antd';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import style from './style';

function copyLinkToClipBoard() {
  const dummy = document.createElement('input');
  const url = window.location.href;
  document.body.appendChild(dummy);
  dummy.value = url;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  if (dummy.value === url) {
    message.success('Link copied to clipboard.');
  }
}

const isBrowser = typeof window !== 'undefined';

const Button = forwardRef((props, ref) => {
  const {
    theme,
    size,
    inGroup,
    onClick,
    onClickCopy,
    onClickPrint,
    onClickExportPDF,
    children,
    sitePageId,
    link,
    href,
    target,
    rel,
    type,
    ..._props
  } = props;

  if (link) {
    return (
      <Link href={href} {..._props} passHref>
        <a
          ref={ref}
          {...(target ? { target } : {})}
          {...(rel ? { rel } : {})}
          css={[style.size[size === 'default' ? 'link' : size], style.type[theme]]}
        >
          {children}
        </a>
      </Link>
    );
  }

  return (
    <AntButton
      // ref={ref}
      css={[style.size[size], style.type[theme], ...(inGroup ? [style.inGroupStyle] : [])]}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      {...(href ? { href } : null)}
      {...onClickCopy && { onClick: copyLinkToClipBoard }}
      {...onClickPrint &&
        isBrowser && {
          onClick: () => {
            window.print();
          },
        }}
      {...onClickExportPDF && {
        onClick: () => {
          if (typeof onClickExportPDF === 'boolean') {
            if (!sitePageId) {
              throw new Error('sitePageId required when using onClickExportPDF');
            } else {
              window.open(
                `${process.env.API_ROOT}/pciwebsite/Cms/Content/ViewAsPdf?sitePageId=${sitePageId}`
              );
            }
          } else if (typeof onClickExportPDF === 'function') {
            onClickExportPDF();
          }
        },
      }}
      {...(target ? { target } : {})}
      {...(rel ? { rel } : {})}
      {..._props}
    >
      {children}
    </AntButton>
  );
});

Button.propTypes = {
  theme: PropTypes.oneOf([
    'default',
    'gray',
    'red',
    'transparent',
    'outlined',
    'inverseOutlined',
    'darkOutlined',
    'link',
    'white',
    'whiteNoPsuedo',
    'disabled',
  ]),
  size: PropTypes.oneOf([
    'default',
    'small',
    'medium',
    'large',
    'full',
    'link',
    'normal',
    'darkOutlined',
    'darkOutlinedInverse',
  ]),
  inGroup: PropTypes.bool,
  onClick: PropTypes.func,
  onClickCopy: PropTypes.bool,
  onClickPrint: PropTypes.bool,
  onClickExportPDF: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  sitePageId: PropTypes.number,
  link: PropTypes.bool,
  href: PropTypes.string,
};

Button.defaultProps = {
  theme: 'default',
  size: 'default',
  inGroup: false,
  onClick: () => {},
  onClickCopy: false,
  onClickPrint: false,
  onClickExportPDF: false,
  sitePageId: undefined,
  link: false,
  href: '',
};

Button.Group = ({ children, size, theme, ...props }) => {
  return (
    <AntButton.Group {...props} css={style.group}>
      {React.Children.toArray(children).map(child => {
        return React.cloneElement(child, {
          ...child.props,
          size,
          theme,
          inGroup: true,
        });
      })}
    </AntButton.Group>
  );
};

Button.Group.propTypes = {
  theme: PropTypes.oneOf(['gray', 'white']),
  size: PropTypes.oneOf(['small', 'large']),
  children: PropTypes.node.isRequired,
};

Button.Group.defaultProps = {
  theme: 'gray',
  size: 'small',
};

export default Button;
