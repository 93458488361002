import {
  DELETE_SAVED_SEARCH_BEGIN,
  DELETE_SAVED_SEARCH_ERROR,
  DELETE_SAVED_SEARCH_SUCCESS,
  LOAD_SAVED_SEARCHES_BEGIN,
  LOAD_SAVED_SEARCHES_ERROR,
  LOAD_SAVED_SEARCHES_SUCCESS,
  RESET_SELECTED_SEARCH,
  SAVE_SEARCH_BEGIN,
  SAVE_SEARCH_ERROR,
  SAVE_SEARCH_SUCCESS,
  SET_MODAL_VISIBLE,
  SET_SAVED_SEARCH_INCLUDEONHOMEPAGE_BY_ID,
  SET_SELECTED_SAVED_SEARCH_BY_ID,
} from '../types/savedSearchesTypes';

const INITIAL_SELECTED_SEARCH = {
  SearchId: null,
  PersonId: null,
  SearchDescription: '',
  FieldOptions: {
    SearchText: '',
    States: [],
    LinesOfBusiness: [],
    Subjects: [],
    Publications: [],
    DayRanges: [],
    PostingTypes: [],
    Committees: [],
    DateRanges: [],
  },
};

const DEFAULT_SAVED_SEARCHES_STATE = {
  isFetchingSavedSearches: false,
  fetchedSavedSearches: false,
  savedSearches: {},
  modalVisible: false,
  selectedSearch: INITIAL_SELECTED_SEARCH,
};

const DEFAULT_SAVED_SEARCH_RESPONSE_STATE = {
  isSavingSearch: false,
  isSaveSuccessful: true,
  SearchId: null,
};

const DEFAULT_DELETE_SEARCH_RESPONSE_STATE = {
  isDeletingSearch: false,
  isDeleteSuccessful: true,
};

// const DEFAULT_SELECTED_SEARCH_ID_STATE = {
//   SearchId: null,
// }

export const savedSearchesData = (state = DEFAULT_SAVED_SEARCHES_STATE, action) => {
  const { type } = action;

  switch (type) {
    case LOAD_SAVED_SEARCHES_BEGIN:
      return {
        ...state,
        isFetchingSavedSearches: action.payload.isFetchingSavedSearches,
      };
    case LOAD_SAVED_SEARCHES_ERROR: {
      const { isFetchingSavedSearches } = action.payload;
      return {
        ...state,
        isFetchingSavedSearches,
        savedSearches: {},
      };
    }
    case LOAD_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        isFetchingSavedSearches: action.payload.isFetchingSavedSearches,
        savedSearches: action.payload.savedSearches,
        fetchedSavedSearches: true,
      };
    case SET_SELECTED_SAVED_SEARCH_BY_ID:
      return {
        ...state,
        selectedSearch: state.savedSearches[action.payload.selectedSearchId],
      };
    case SET_MODAL_VISIBLE:
      return {
        ...state,
        modalVisible: action.payload.isModalVisible,
      };
    case SET_SAVED_SEARCH_INCLUDEONHOMEPAGE_BY_ID: {
      const { id, value } = action.payload;
      const savedSearch = state.savedSearches[id.toString()];
      savedSearch.IncludeOnHome = value;
      return {
        ...state,
        savedSearches: {
          ...state.savedSearches,
          [id.toString()]: savedSearch,
        },
      };
    }
    case RESET_SELECTED_SEARCH: {
      return {
        ...state,
        selectedSearch: INITIAL_SELECTED_SEARCH,
      };
    }
    default:
      return state;
  }
};

export const saveSearchResponse = (state = DEFAULT_SAVED_SEARCH_RESPONSE_STATE, action) => {
  const { type } = action;

  switch (type) {
    case SAVE_SEARCH_BEGIN:
      return {
        ...state,
        isSavingSearch: action.payload.isSavingSearch,
        isSaveSuccessful: action.payload.isSaveSuccessful,
      };
    case SAVE_SEARCH_ERROR:
      return {
        ...state,
        isSavingSearch: action.payload.isSavingSearch,
        isSaveSuccessful: action.payload.isSaveSuccessful,
      };
    case SAVE_SEARCH_SUCCESS:
      return {
        ...state,
        isSavingSearch: action.payload.isSavingSearch,
        isSaveSuccessful: action.payload.isSaveSuccessful,
        SearchId: action.payload.SearchId,
      };
    default:
      return state;
  }
};

export const deleteSearchResponse = (state = DEFAULT_DELETE_SEARCH_RESPONSE_STATE, action) => {
  const { type } = action;

  switch (type) {
    case DELETE_SAVED_SEARCH_BEGIN:
      return {
        ...state,
      };
    case DELETE_SAVED_SEARCH_ERROR:
      return {
        ...state,
      };
    case DELETE_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
