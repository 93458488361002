import { css, jsx } from '@emotion/core';

import { bp, space } from '@/styles';

const { m } = space;
const style = {
  footerWrapper: css`
    display: flex;
    flex-direction: column;
    @media ${bp.md} {
      justify-content: space-between;
      flex-direction: row;
    }
  `,
  footerButtonWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media ${bp.md} {
      flex-direction: inherit;
      width: auto;
    }
  `,
  cancel: css`
    margin-top: 16px;
    text-align: center;

    @media ${bp.md} {
      margin-top: 0px;
    }
  `,
  cancelInner: css`
    display: flex !important;
    align-items: baseline !important;
    text-align: center !important;
    justify-content: center;
  `,
  cancelIcon: css`
    padding-left: 8px;
  `,
  cancelText: css`
    padding-left: 0px;
  `,
  saveSearch: css`
    margin-left: 0px !important;
    margin-top: 16px;
    @media ${bp.md} {
      margin-top: 0px;
      margin-left: 8px !important;
    }
  `,
};

export default style;
