import { SET_NAMED_PARTY_SORT_FIELD } from '../types';

const DEFAULT_SORT_FIELD_STATE = 'CASENAME';

const SortFieldReducer = (state = DEFAULT_SORT_FIELD_STATE, action) => {
  const { type } = action;
  switch (type) {
    case SET_NAMED_PARTY_SORT_FIELD:
      return action.payload.sortField;
    default:
      return state;
  }
};
export default SortFieldReducer;
