import { css } from '@emotion/core';

import bp, { sizes } from './utilities/breakpoints';
import * as colors from './utilities/colors';
import * as easing from './utilities/easings';
import * as form from './utilities/form';
import space from './utilities/space';
import * as text from './utilities/text';

const colorWhite = css`
  color: white !important;
`;

const hiddenXXL = css`
  @media (max-width: 374px) {
    display: none !important;
  }
`;

export { bp, colors, colorWhite, hiddenXXL, space, easing, text, form, sizes };
