import React from 'react';

const SvgSnapshot = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M27.5 0C39.355 0 49 9.677 49 21.571c0 11.62-19.919 33.197-20.768 34.11a.998.998 0 01-1.464 0C25.918 54.768 6 33.19 6 21.57 6 9.677 15.644 0 27.5 0zm0 2C16.748 2 8 10.78 8 21.571c0 9.652 15.868 27.894 19.5 31.94C31.13 49.465 47 31.22 47 21.572 47 10.78 38.252 2 27.5 2zM32 7a1 1 0 011 1v4h7a1 1 0 011 1v15a1 1 0 01-1 1H15a1 1 0 01-1-1V13a1 1 0 011-1h7V8a1 1 0 011-1zm7 7H16v13h23V14zm-11.5 1c3.032 0 5.5 2.468 5.5 5.5S30.532 26 27.5 26a5.507 5.507 0 01-5.5-5.5c0-3.032 2.468-5.5 5.5-5.5zm0 2c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zM31 9h-7v3h7V9z"
    />
  </svg>
);

export default SvgSnapshot;
