import { css } from '@emotion/react';

import { colors } from '@/styles';

export const primary = css`
  font-family: 'Lato', sans-serif;
  color: ${colors.secondaryGray};
  font-size: 1.6rem;
  line-height: 1.75;
`;

export const t = css`
  font-family: 'Lato', sans-serif;
  color: ${colors.secondaryGray};
  font-size: 1.6rem;
  line-height: 1.75;
`;

export const textSecondary = css`
  font-family: 'Lato', sans-serif;
  color: ${colors.secondaryGray};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const boldBlueText = css`
  font-family: 'Lato', sans-serif;
  color: ${colors.primaryBlue};
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;

  &:hover {
    color: ${colors.primaryRed};
  }

  .anticon {
    margin-right: 0px !important;
  }
`;

export const boldGrayText = css`
  font-family: 'Lato', sans-serif;
  color: ${colors.secondaryGray};
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: 700;
`;
