import { css, jsx } from '@emotion/core';
import React from 'react';

import { bp } from '@/styles';

import NavItem from './NavItem';
import SubMenu from './SubMenu';
import SubTitle from './SubTitle';

const style = {
  statesList: css`
    .ant-menu-sub {
      @media ${bp.lg} {
        columns: 4;
      }
    }
  `,
};

const State = ({ statecode, ...props }) => {
  const path = `/advocacy/state/${statecode}`;
  return <NavItem as="/advocacy/state/[slug]" path={path} {...props} type="subItem" />;
};

const States = props => {
  return (
    <SubMenu
      {...props}
      css={style.statesList}
      title={<SubTitle title="Information by State" subsub />}
      subsub
    >
      <State statecode="AL" />
      <State statecode="AK" />
      <State statecode="AZ" />
      <State statecode="AR" />
      <State statecode="CA" />
      <State statecode="CO" />
      <State statecode="CT" />
      <State statecode="DE" />
      <State statecode="DC" />
      <State statecode="FL" />
      <State statecode="GA" />
      <State statecode="HI" />
      <State statecode="ID" />
      <State statecode="IL" />
      <State statecode="IN" />
      <State statecode="IA" />
      <State statecode="KS" />
      <State statecode="KY" />
      <State statecode="LA" />
      <State statecode="ME" />
      <State statecode="MD" />
      <State statecode="MA" />
      <State statecode="MI" />
      <State statecode="MN" />
      <State statecode="MS" />
      <State statecode="MO" />
      <State statecode="MT" />
      <State statecode="NE" />
      <State statecode="NV" />
      <State statecode="NH" />
      <State statecode="NJ" />
      <State statecode="NM" />
      <State statecode="NY" />
      <State statecode="NC" />
      <State statecode="ND" />
      <State statecode="OH" />
      <State statecode="OK" />
      <State statecode="OR" />
      <State statecode="PA" />
      <State statecode="RI" />
      <State statecode="SC" />
      <State statecode="SD" />
      <State statecode="TN" />
      <State statecode="TX" />
      <State statecode="UT" />
      <State statecode="VT" />
      <State statecode="VA" />
      <State statecode="WA" />
      <State statecode="WV" />
      <State statecode="WI" />
      <State statecode="WY" />
    </SubMenu>
  );
};

export default States;
