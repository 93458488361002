import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import subscriptionManagement from './account/redux/reducer';
import advocacyParticipation from './advocacyParticipation/redux/advocacyParticipationReducer';
import amicusReducer from './amicus/redux/amicusReducer';
import authReducer from './auth/redux/authSlice';
import companyContacts from './companyContacts/redux/reducer';
import complianceReducer from './compliance/redux/complianceReducer';
import forgotpasswordReducer from './forgotpassword/redux/forgotpasswordReducer';
import forumReducer from './forum/redux/forumReducer';
import homeReducer from './home/redux/reducer';
import namedParty from './namedParty/redux/reducers';
import pageLoadReducer from './pageLoad/redux/reducers';
import registerReducer from './register/redux/registerAccountReducer';
import searchReducer from './search/redux/reducers';
import updateProfileReducer from './updateProfile/redux/reducer';

const isServer = typeof window === 'undefined';

const rootReducer = combineReducers({
  advocacyParticipation,
  amicus: amicusReducer,
  compliance: complianceReducer,
  search: searchReducer,
  register: registerReducer,
  forgotpassword: forgotpasswordReducer,
  forum: forumReducer,
  home: homeReducer,
  updateProfile: updateProfileReducer,
  subscriptionManagement,
  namedParty,
  companyContacts,
  auth: !isServer
    ? persistReducer(
        {
          key: 'root',
          whitelist: ['isLoggedIn', 'token', 'auth0Token'],
          storage,
        },
        authReducer
      )
    : authReducer,
  pageLoad: pageLoadReducer,
});

const middlewareEnhancer = applyMiddleware(thunk);

const composeEnhancers = composeWithDevTools(middlewareEnhancer);

export const makeStore = context => createStore(rootReducer, composeEnhancers);

export const wrapper = createWrapper(makeStore, { storeKey: '__NEXT_REDUX_STORE__' });
