import { css, jsx } from '@emotion/core';
import { Icon, Input } from 'antd';
import React from 'react';

import Button from '@/src/shared/components/Button';
import Form from '@/src/shared/components/Form';
import { colors } from '@/styles';

const style = {
  searchInput: css`
    input {
      font-size: 1.6rem;
      border: 0 !important;
      padding-left: 44px !important;
      border: 1px solid ${colors.accentGrayLight} !important;
      background-color: transparent;
      &::placeholder {
        font-size: 1.6rem;
      }
      &::-webkit-input-placeholder {
        font-size: 1.6rem;
      }
      &::-moz-placeholder {
        font-size: 1.6rem;
      }
      &:-ms-input-placeholder {
        font-size: 1.6rem;
      }
      &:-moz-placeholder {
        font-size: 1.6rem;
      }
      outline: none !important;
      box-shadow: none !important;
    }
    .ant-input-prefix {
      font-size: 2.4rem;
      opacity: 0.5;
    }
  `,
};

const SearchForm = props => {
  const { form, ..._props } = props;

  const handleSubmit = e => {
    if (e && 'preventDefault' in e) e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        console.log(err, values);
        return;
      }

      window.location = `/search${values.searchtext ? `?searchtext=${values.searchtext}` : ''}`;
    });
  };

  return (
    <Form
      theme="light"
      onSubmit={handleSubmit}
      css={css`
        display: flex;
      `}
      {..._props}
    >
      <Form.Item>
        {form.getFieldDecorator('searchtext')(
          <Input
            css={style.searchInput}
            size="large"
            prefix={<Icon type="search" />}
            placeholder="Search"
            autoComplete="off"
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          theme="default"
          css={css`
            margin-left: 16px;
          `}
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create()(SearchForm);
