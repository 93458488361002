import {
  complianceAffiliate,
  informationalAffiliate,
  memberUser,
  pciEveryone,
  servicePurchaser,
} from './roles';

const allMemberLevels = [memberUser, complianceAffiliate, informationalAffiliate, servicePurchaser];

export const checkAccess = (memberLevels, role = []) => {
  if (role.includes(pciEveryone)) {
    return true;
  }
  const accessLevels = memberLevels || allMemberLevels;
  return accessLevels.find(memberRole => role.includes(memberRole));
};

export const ADVOCACY_ACCESS = {
  POLICY_COMMITTEES: [memberUser, servicePurchaser],
  LEGAL_AMICUS: [memberUser],
  LEGAL_NAMED_PARTY: [memberUser],
};

export const COMPLIANCE_ACCESS = {
  COMPLIANCE: [memberUser, complianceAffiliate, servicePurchaser],
  STATE_FILINGS: [memberUser, complianceAffiliate, servicePurchaser],
  CLAIMS_COMPLIANCE: [memberUser, complianceAffiliate, servicePurchaser],
  MARKET_CONDUCT: [memberUser],
  AUTO_INSURANCE_COMPLIANCE: [memberUser, complianceAffiliate, servicePurchaser],
  COMPLIANCE_RESOURCES: [memberUser, complianceAffiliate, servicePurchaser],
  SUBSCRIPTIONS: [memberUser, complianceAffiliate, servicePurchaser],
};

export const ABOUT_ACCESS = {
  MEMBERSHIP: [memberUser, servicePurchaser],
  CEO_COMMUNICATIONS: allMemberLevels,
  BOARD_DIRECTORS: [memberUser],
  STAFF_DIRECTORY: [memberUser, servicePurchaser],
};
