import React from 'react';

const SvgAgentsBrokersProducers = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M28 0C12.56 0 0 12.56 0 28s12.56 28 28 28 28-12.56 28-28S43.44 0 28 0zM13 49.217v-4.99c0-2.08 1.826-3.772 4.07-3.772h4.686c.405 0 .77-.245.925-.62l2.025-4.923c.893.054 1.824.088 2.794.088.98 0 1.922-.035 2.823-.09l2.34 4.97a1 1 0 00.905.575h5.363c2.243 0 4.069 1.693 4.069 3.773v4.989A25.85 25.85 0 0128 54a25.85 25.85 0 01-15-4.783zM37.786 31.82A46.809 46.809 0 0127.5 33c-4.642 0-8.564-.775-10.286-1.179.575-2.335 1.942-8.249 1.942-11.64v-2.324A6.865 6.865 0 0126.014 11h2.972a6.865 6.865 0 016.858 6.857v2.325c0 3.39 1.367 9.304 1.942 11.64zM45 47.647v-3.42c0-3.182-2.723-5.772-6.07-5.772h-4.727l-1.752-3.721c3.97-.417 6.674-1.183 6.827-1.227a1 1 0 00.69-1.214c-.022-.081-2.124-8.167-2.124-12.111v-2.325C37.844 12.974 33.87 9 28.986 9h-2.972c-4.884 0-8.858 3.974-8.858 8.857v2.325c0 3.944-2.102 12.03-2.123 12.111a1 1 0 00.69 1.214c.153.044 2.885.819 6.89 1.234l-1.527 3.714h-4.017c-3.346 0-6.069 2.59-6.069 5.773v3.42C5.494 42.876 2 35.84 2 28 2 13.663 13.664 2 28 2s26 11.663 26 26c0 7.84-3.493 14.877-9 19.647z"
    />
  </svg>
);

export default SvgAgentsBrokersProducers;
