import * as TYPES from './types';

const INITIAL_STATE = {
  recentSearch: {},
  savedSearches: [],
  loading: false,
  error: false,
  errorMsg: '',
};

export default function myApciaRecentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.LOAD_MYAPCIA_ERROR:
      return { ...state, loading: false, error: true, errorMsg: '' };
    case TYPES.LOAD_MYAPCIA_FAILURE:
      return { ...state, loading: false, error: true, errorMsg: '' };
    case TYPES.LOAD_MYAPCIA_START:
      return {
        ...state,
        loading: true,
        recentSearch: INITIAL_STATE.recentSearch,
        savedSearches: INITIAL_STATE.savedSearches,
      };
    case TYPES.LOAD_MYAPCIA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        recentSearch: action.payload.recentSearch,
        savedSearches: action.payload.savedSearches,
      };
    default:
      return state;
  }
}
