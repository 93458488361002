import React from 'react';

const SvgConferenceCall = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M41.007 0A3.002 3.002 0 0144 3.004V53a3 3 0 01-2.993 3H15.993A3 3 0 0113 53V3.005A3.002 3.002 0 0115.993 0zM42 46H15v7c0 .561.436 1 .993 1h25.014A.997.997 0 0042 53v-7zm-13 1c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0 2c-.551 0-1 .449-1 1 0 .551.449 1 1 1 .551 0 1-.449 1-1 0-.551-.449-1-1-1zM41.007 2H15.993A1 1 0 0015 3.004v40.972h27V3.004A1 1 0 0041.007 2zm-2.718 3.586l1.44 1.39L29.024 18.07l-1.44-1.389L38.29 5.586z"
    />
  </svg>
);

export default SvgConferenceCall;
