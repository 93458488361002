import { useAuth0 } from '@auth0/auth0-react';
import { css } from '@emotion/react';
import { Divider, Dropdown, Form, Icon, Input, Menu, Skeleton } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import { _ } from 'core-js';
import Link from 'next/link';
import Router from 'next/router';
import React, { memo, useState } from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';

import Button from '@/src/shared/components/Button';
import CustomIcon from '@/src/shared/components/Icon';
import { assessmentUser, pciEveryone } from '@/src/shared/constants';
import { bp, colors, sizes, space } from '@/styles';
import { boldBlueText } from '@/styles/utilities/text';

import AccountMenu from './components/AccountMenu';

const searchInputText = css`
  color: ${colors.primaryBlue} !important;
  font-weight: bold;
  font-size: 1.6rem;
`;

export const dropdownOverlayMenu = css`
  border-right: 0;
  display: flex;
  flex-direction: column;
  padding: 0;

  .ant-menu-item {
    padding: 0;
  }

  @media ${bp.lg} {
    padding: 0 1.2rem;
    .ant-menu-item,
    a {
      height: 100%;
    }
  }
`;

const style = {
  loginRegister: css`
    display: none;
    @media ${bp.lg} {
      font-size: 1.2rem;
      display: flex !important;
      margin-left: auto;
      > .ant-divider-vertical {
        height: 2rem;
      }
      > a {
        color: ${colors.primaryBlue} !important;
        font-weight: bold;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        &::after {
          content: '';
          height: 20px;
          display: inline-block;
          width: 1px;
          background-color: ${colors.accentGrayLight};
          margin: 0 10px -4px;
          transform: translateY(-2px);
        }
        &:hover {
          color: ${colors.primaryRed} !important;
        }
        &::before {
          display: none !important;
        }
        &:last-child::after {
          display: none;
        }
      }
    }
  `,
  loginRegisterSearch: css`
    order: -1;
    margin: 0 -16px 8px !important;
    padding: 0 16px 16px !important;
    width: calc(100% + 32px) !important;
    height: 100% !important;
    @media ${bp.lg} {
      display: block !important;
      order: 0;
      margin: 0px 0 6px auto !important;
      padding: 0 !important;
      border-bottom: none !important;
      width: auto !important;
    }
  `,
  searchForm: css`
    display: flex !important;
    justify-content: space-between;
    @media ${bp.lg} {
      align-items: center;
      height: 100%;
    }
  `,
  searchInput: css`
    input {
      font-size: 1.6rem;
      border: 0 !important;
      padding-left: 24px !important;
      &::placeholder {
        ${searchInputText};
      }
      &::-webkit-input-placeholder {
        ${searchInputText};
      }
      &::-moz-placeholder {
        ${searchInputText};
      }
      &:-ms-input-placeholder {
        ${searchInputText};
      }
      &:-moz-placeholder {
        ${searchInputText};
      }
      outline: none !important;
      box-shadow: none !important;
    }
    .ant-input-prefix {
      ${searchInputText};
      left: 0 !important;
    }
  `,
  searchInputContainer: css`
    transform: translateY(1px);
    @media ${bp.lg} {
      display: none;
    }
  `,
  accountSubmenu: css`
    border-right: 0 !important;
    .ant-dropdown-menu-item {
      > a {
        font-size: 1.2rem;
        color: ${colors.primaryBlue};
        .anticon {
          margin-right: 4px;
        }
      }
    }
  `,
  accountSubmenuIcon: css`
    font-size: 1.6rem;
    font-weight: 700;
    color: ${colors.primaryBlue};
    &:hover {
      color: ${colors.primaryRed};
    }
    .anticon {
      margin-right: 4px;
      font-size: 1.6rem;
    }
  `,
  mobileLoginContainer: css`
    > .ant-divider-horizontal {
      margin: 1rem 0;
    }

    @media ${bp.lg} {
      display: none;
    }
  `,
  loginButtons: css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    > a {
      font-size: 1.6rem;
      line-height: 2.8;
      font-weight: 900;

      &:hover {
        color: ${colors.primaryRed};
      }
    }

    > .ant-divider-vertical {
      height: 2rem;
    }
  `,
  hideOnLg: css`
    @media ${bp.lg} {
      display: none;
    }
  `,
  default: css`
    padding-left: 0 !important;
    padding-right: 10px !important;
    line-height: 1 !important;
    height: auto !important;
    margin: 4px 0 !important;
    display: inline-flex;
    width: 10rem;

    .ant-skeleton-header {
      padding-right: 1rem !important;
    }

    .ant-skeleton-avatar {
      height: 2rem !important;
      width: 2rem !important;
      margin-top: 1.1rem;
    }

    .ant-skeleton-title {
      width: 7rem !important;
      height: 1.8rem !important;
    }

    .ant-skeleton-paragraph {
      display: none !important;
      visibility: none !important;
    }
  `,
};

const NavAccount = memo(props => {
  const { isLg, form, ..._props } = props;
  const { loginWithRedirect } = useAuth0();
  const { isLoading, isLoggedIn, role } = useSelector(state => state.auth);

  const [searchFocused, setSearchFocused] = useState(false);

  const isAdmin = Array.isArray(role) && role.includes(pciEveryone);
  const isAssessmentUser = Array.isArray(role) && role.includes(assessmentUser);

  function handleSearch(e) {
    e.preventDefault();
    const values = form.getFieldsValue();
    Router.push(`/search${values.searchtext ? `?searchtext=${values.searchtext}` : ''}`);
  }

  return (
    <Menu.Item {..._props} css={style.loginRegisterSearch} className="hideIfClosed">
      <Media query={`(max-width: ${sizes.lg}px)`}>
        <Divider
          style={{
            marginBottom: '1rem',
          }}
        />
      </Media>

      <Form onSubmit={handleSearch} css={style.searchForm} {..._props}>
        <div css={style.searchInputContainer}>
          <Form.Item css={space.m.b0}>
            {form.getFieldDecorator('searchtext')(
              <Input
                css={style.searchInput}
                prefix={<Icon type="search" />}
                placeholder="Search"
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setTimeout(() => setSearchFocused(false), 50)}
              />
            )}
          </Form.Item>
        </div>
        {searchFocused ? (
          <div>
            <Form.Item css={space.m.b0}>
              <Button htmlType="submit">Search</Button>
            </Form.Item>
          </div>
        ) : (
          <div css={style.loginRegister} {..._props}>
            {isLoading ? (
              <div css={style.default}>
                <Skeleton avatar />
              </div>
            ) : (
              <>
                {isLoggedIn ? (
                  <Dropdown
                    placement="bottomRight"
                    overlayClassName="account-dropdown"
                    trigger={isLg ? ['hover'] : ['click']}
                    overlay={
                      <AccountMenu
                        isAdmin={isAdmin}
                        isAssessmentUser={isAssessmentUser}
                        isLoggedIn={isLoggedIn}
                      />
                    }
                  >
                    <a css={style.accountSubmenuIcon}>
                      <CustomIcon name="Avatar" /> <span css={boldBlueText}>Account</span>{' '}
                      <Icon type="down" />
                    </a>
                  </Dropdown>
                ) : (
                  <>
                    <Dropdown
                      placement="bottomRight"
                      overlayClassName="account-dropdown"
                      trigger={isLg ? ['hover'] : ['click']}
                      overlay={
                        <Menu className="hideIfClosed" css={dropdownOverlayMenu}>
                          <MenuItem type="bold" {..._props}>
                            <a
                              onClick={() =>
                                loginWithRedirect({
                                  appState: {
                                    returnTo: window.location.pathname,
                                  },
                                })
                              }
                              id="ga-login"
                            >
                              Login
                            </a>
                          </MenuItem>
                          <MenuItem type="bold" {..._props}>
                            <a href="/about/faqs/" id="ga-login">
                              Login Help
                            </a>
                          </MenuItem>
                        </Menu>
                      }
                    >
                      <span css={boldBlueText}>
                        Login <Icon type="down" />
                      </span>
                    </Dropdown>
                    <Divider type="vertical" />
                    <Link href="/register">
                      <a target="_blank" id="ga-register">
                        <span css={boldBlueText}>Register</span>
                      </a>
                    </Link>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </Form>
      {(isLoggedIn || isLoading) && (
        <Divider
          css={{
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        />
      )}
      {!isLoggedIn && !isLoading && (
        <div css={style.mobileLoginContainer}>
          <Divider />
          <div css={style.loginButtons}>
            <a
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    returnTo: window.location.pathname,
                  },
                })
              }
              id="ga-login"
            >
              Login
            </a>
            <Divider type="vertical" />
            <a target="_blank" id="ga-register" href="/register">
              Register
            </a>
          </div>
          <Divider />
          <div css={style.loginButtons}>
            <a target="_blank" id="ga-register" href="/about/faqs/">
              Login Help
            </a>
          </div>
          <Divider />
        </div>
      )}
    </Menu.Item>
  );
});

export default Form.create()(NavAccount);
