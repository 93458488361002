import { SET_NAMED_PARTY_SORT_ORDER } from '../types';

const DEFAULT_SORT_ORDER_STATE = 'ASC';

const SortOrderReducer = (state = DEFAULT_SORT_ORDER_STATE, action) => {
  const { type } = action;
  switch (type) {
    case SET_NAMED_PARTY_SORT_ORDER:
      return action.payload.sortOrder;

    default:
      return state;
  }
};
export default SortOrderReducer;
