import { css as _css, jsx } from '@emotion/core';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import style from './style';

const CustomLink = props => {
  const { anchor, children, css, href, as, target, ..._props } = props;
  if (anchor) {
    return (
      <a css={[style.link, css]} href={href} target={target} {..._props}>
        {children}
      </a>
    );
  }
  return (
    <Link href={href} as={as || href} passHref>
      <a css={[style.link, css]} {..._props}>
        {children}
      </a>
    </Link>
  );
};

CustomLink.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.object,
  anchor: PropTypes.bool,
  target: PropTypes.string,
};

CustomLink.defaultProps = {
  css: _css``,
  anchor: false,
  target: '_self',
};

export default CustomLink;
