import React from 'react';

const SvgFraud = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M28 0c9.389 0 17 7.611 17 17 0 1.715-.254 3.396-.748 5.001L46 22a1 1 0 011 1v32a1 1 0 01-1 1H10a1 1 0 01-1-1V23a1 1 0 011-1l27.8.001A10.949 10.949 0 0039 17c0-6.075-4.925-11-11-11s-11 4.925-11 11a3 3 0 01-6 0c0-9.389 7.611-17 17-17zm17 24H11v30h34V24zm-17 5a5 5 0 013 9v10a1 1 0 01-1 1h-4a1 1 0 01-1-1V38a5 5 0 013-9zm0 2a3 3 0 00-1.5 5.6 1 1 0 01.5.865V47h2v-9.535a1 1 0 01.5-.866A3 3 0 0028 31zm0-29c-8.284 0-15 6.716-15 15a1 1 0 102 0c0-7.18 5.82-13 13-13s13 5.82 13 13c0 1.741-.343 3.434-.997 5h2.144c.562-1.59.853-3.274.853-5 0-8.284-6.716-15-15-15z"
    />
  </svg>
);

export default SvgFraud;
