import { css } from '@emotion/core';
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import Media from 'react-media';

import { handleDatePickerBlur } from '@/utils/dateTimeHelpers';

const DATE_FORMAT = 'MM/DD/YYYY';
const formatList = ['MM/DD/YYYY', 'M/D/YYYY'];
const defaultValue = [null, null];

const style = {
  pickerContainer: showColumn =>
    !showColumn
      ? css`
          display: flex;
          align-items: center;
        `
      : css``,
  divider: css`
    margin: 0 5px;
  `,
};

const DateRangePicker = props => {
  const [endOpen, setEndOpen] = useState(false);
  const { value, format, size, disabledDate, showColumn, onChange } = props;

  return (
    <Media query="(max-width: 596px)">
      {isMobile => {
        const [startValue, endValue] = value || defaultValue;
        const startValStr = startValue ? moment(startValue).format(DATE_FORMAT) : '';
        const endValStr = endValue ? moment(endValue).format(DATE_FORMAT) : '';
        const columnStack = showColumn && isMobile;

        const _props = {
          format: format || formatList,
          size: size || 'default',
        };

        if (isMobile) {
          _props.onBlur = handleDatePickerBlur;
        }

        if (props.css) {
          _props.css = props.css;
        }

        const handleStartChange = (startV, startS) => {
          const v = [startV, endValue];
          const s = [startS, endValStr];
          onChange(v, s);
        };
        const handleEndChange = (endV, endS) => {
          const v = [startValue, endV];
          const s = [startValStr, endS];
          onChange(v, s);
        };
        const handleStartOpenChange = open => {
          if (!open) {
            setEndOpen(true);
          }
        };
        const handleEndOpenChange = open => {
          setEndOpen(open);
        };
        const disabledStartDate = start => {
          const isDisableDayAfter = disabledDate && disabledDate(start);
          if (!start || !endValue) {
            return isDisableDayAfter;
          }
          return start.valueOf() > endValue.valueOf() || isDisableDayAfter;
        };
        const disabledEndDate = end => {
          const isDisableDayAfter = disabledDate && disabledDate(end);
          if (!end || !startValue) {
            return isDisableDayAfter;
          }
          return end.valueOf() <= startValue.valueOf() || isDisableDayAfter;
        };

        return (
          <div css={style.pickerContainer(columnStack)}>
            <DatePicker
              {..._props}
              disabledDate={disabledStartDate}
              value={startValue}
              placeholder="Start date"
              onChange={handleStartChange}
              onOpenChange={handleStartOpenChange}
            />
            {!columnStack && <span css={style.divider}> ~ </span>}
            <DatePicker
              {..._props}
              disabledDate={disabledEndDate}
              value={endValue}
              open={endOpen}
              placeholder="End date"
              onChange={handleEndChange}
              onOpenChange={handleEndOpenChange}
            />
          </div>
        );
      }}
    </Media>
  );
};

export default DateRangePicker;
