import React from 'react';

const SvgCeoCommunications = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M28 0C12.56 0 0 12.561 0 28s12.56 28 28 28 28-12.561 28-28S43.44 0 28 0zM12.249 48.755c1.812-.569 4.163-1.193 7.191-1.995a.966.966 0 00.711-.814l.434-3.463h14.992l.432 3.463c.049.39.33.713.71.814 2.927.776 5.213 1.406 7.037 1.99A25.93 25.93 0 0128 54.07a25.93 25.93 0 01-15.751-5.314zM21.93 35.23c-1.178-1.356-2.155-3.107-2.827-5.063a.966.966 0 00-1.26-.589.638.638 0 01-.873-.51l-.55-3.915c-.05-.358.188-.678.626-.733.257-.016.507-.097.69-.278a.968.968 0 00.287-.687c.001-1.73.202-3.426.598-5.04a.965.965 0 00.019-.362 8.404 8.404 0 01-.08-1.15c0-4.343 3.464-8.115 8.055-8.775a.998.998 0 00.182-.046c2.404-.84 4.454-1.323 5.625-1.323.074 0 .104.01.106.01.008.011.263.319.078 1.605a.967.967 0 00.472.974c2.827 1.633 4.515 4.457 4.515 7.555 0 .386-.026.773-.079 1.15a.967.967 0 00.019.363c.393 1.603.595 3.29.598 5.01 0 .256.149.507.332.688.184.181.468.277.734.281a.646.646 0 01.429.252c.104.14.148.315.124.49l-.556 3.95a.661.661 0 01-.254.439.66.66 0 01-.63.084.967.967 0 00-1.269.583c-.671 1.946-1.645 3.688-2.817 5.037a.967.967 0 00-.237.634v4.688h-11.82v-4.688a.967.967 0 00-.237-.634zM45.507 47.3c-1.918-.67-4.381-1.364-7.662-2.238l-.458-3.663a.966.966 0 00-.958-.846h-.511v-4.335c1.072-1.303 1.984-2.885 2.667-4.63a2.579 2.579 0 002.521-2.23l.556-3.95a2.577 2.577 0 00-.49-1.916 2.556 2.556 0 00-1.125-.857 23.016 23.016 0 00-.596-4.501c.049-.406.073-.819.073-1.23 0-3.591-1.834-6.873-4.937-8.895.078-1.126-.119-1.947-.597-2.499-.27-.31-.76-.681-1.568-.681-1.853 0-4.629.864-6.176 1.402-5.49.834-9.616 5.406-9.616 10.673 0 .411.024.824.073 1.23a23.003 23.003 0 00-.597 4.536 2.58 2.58 0 00-1.598 2.752l.55 3.917a2.579 2.579 0 002.498 2.218c.686 1.757 1.6 3.35 2.68 4.661v4.335h-.504a.966.966 0 00-.958.846l-.458 3.662c-3.414.905-5.942 1.585-7.823 2.239C5.237 42.526 1.931 35.642 1.931 28 1.931 13.626 13.626 1.931 28 1.931c14.374 0 26.069 11.695 26.069 26.069 0 7.642-3.306 14.526-8.562 19.299z"
    />
  </svg>
);

export default SvgCeoCommunications;
