import { css, jsx } from '@emotion/core';
import { Divider as AntDivider } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { bp, colors } from '@/styles';

const divider = {
  default: css``,
  full: css`
    margin-left: -32px;
    margin-right: -32px;
    width: calc(100% + 64px);
    margin-top: 24px;
  `,
  smallfull: css`
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
    @media ${bp.sm} {
      margin-left: -16px;
      margin-right: -16px;
      width: calc(100% + 32px);
    }
  `,
  partial: css`
    margin-left: -24px;
    width: calc(100% + 48px);
    margin-top: 24px;
  `,
  red: css`
    width: 100px;
    min-width: 100px;
    height: 6px;
    background-color: ${colors.primaryRed};
  `,
  small: css`
    margin: 1.3rem 0;
    @media ${bp.lg} {
      margin: 0 0;
    }
  `,
};

const Divider = ({ type, css: _css = css``, ...props }) => {
  return <AntDivider {...props} css={[divider[type], _css]} />;
};

Divider.propTypes = {
  type: PropTypes.oneOf(['full', 'smallfull', 'partial', 'red', 'default', 'small']),
};

Divider.defaultProps = {
  type: 'default',
};

export default Divider;
