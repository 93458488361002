import { css, jsx } from '@emotion/core';
import { Icon } from 'antd';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Button from '@/src/shared/components/Button';
import Container from '@/src/shared/components/Container';

const style = {
  wrapper: css`
    bottom: 0;
    height: 4rem;
    left: 0;
    margin: -4rem 0 0;
    pointer-events: none;
    position: sticky;
    right: 0;
    width: 100%;
    @media print {
      display: none !important;
    }
  `,
  container: css`
    text-align: right;
  `,
  button: css`
    align-items: center;
    display: inline-flex;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.66;
    margin: 0.4rem 0;
    padding: 0.6rem 2.5rem;
    pointer-events: auto;
    text-transform: none;
  `,
};

const hideOnPaths = ['/account'];

const BackToTop = () => {
  const router = useRouter();

  const shouldHide = hideOnPaths.some(path => {
    return router.pathname.includes(path);
  });

  if (shouldHide) {
    return null;
  }

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = e => setVisible(e.target.documentElement.scrollTop > 200);

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!visible) {
    return null;
  }

  return (
    <div css={style.wrapper}>
      <Container css={style.container}>
        <Button onClick={handleClick} css={style.button} theme="darkOutlined">
          <Icon type="up" style={{ fontSize: '16px', margin: '2px 0 0' }} />
          Back To Top
        </Button>
      </Container>
    </div>
  );
};

export default BackToTop;
