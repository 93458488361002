import React from 'react';

const SvgVideo = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M28 0c15.44 0 28 12.56 28 28S43.44 56 28 56 0 43.44 0 28 12.56 0 28 0zm0 2.113C13.725 2.113 2.113 13.726 2.113 28c0 14.274 11.612 25.887 25.887 25.887 14.274 0 25.887-11.613 25.887-25.887C53.887 13.726 42.274 2.113 28 2.113zm5.004 16.906a5.292 5.292 0 015.282 5.29v.73l6.686-3.253a1.06 1.06 0 011.519.95v13.306a1.057 1.057 0 01-1.52.95l-6.685-3.252v.064a5.292 5.292 0 01-5.282 5.29H14.791c-2.912 0-5.282-2.373-5.282-5.29V24.31c0-2.918 2.37-5.291 5.282-5.291zm0 2.113H14.791a3.177 3.177 0 00-3.168 3.178v9.494a3.177 3.177 0 003.168 3.177h18.213a3.177 3.177 0 003.17-3.177V32.05a1.057 1.057 0 011.518-.95l6.685 3.253v-9.927l-6.685 3.253a1.057 1.057 0 01-1.519-.95v-2.42a3.177 3.177 0 00-3.169-3.177z"
    />
  </svg>
);

export default SvgVideo;
