import { css } from '@emotion/react';

import { bp, colors } from '@/styles';

// #region mixins
const grayInput = css`
  background-color: ${colors.accentGrayUltralight};
  border: none;
  border-radius: 4px;
`;

const grayInputTag = css`
  height: 30px;
  min-width: 100px;
  border-radius: 2px;
  border: 1px solid ${colors.accentGrayLight};
  background-color: #fff;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  font-weight: 900;
  padding: 0 18px 0 6px;
  margin-top: 4px !important;
`;

const asterisk = css`
  line-height: 1;
  font-size: 15px;
  color: ${colors.primaryBlue};
  font-family: SimSun, sans-serif;
`;
// #endregion

export const inputGray = css`
  .ant-input {
    ${grayInput};
    &:focus,
    &:active {
      border: 1px solid ${colors.secondaryBlue};
      box-shadow: none;
    }
  }
  .ant-select-selection {
    ${grayInput};
    &__choice {
      ${grayInputTag};
      &__remove {
        top: -1px;
      }
    }
    &[aria-expanded='true'] {
      border: 1px solid ${colors.secondaryBlue};
      box-shadow: none;
    }
  }
  .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
    margin: 0 6px; /* keep input string touching border */
    padding: 0; /* remove ios padding */
  }
`;

export const inputMobileZoom = css`
  @media (max-width: 768px) {
    font-size: 16px;
    .ant-input {
      height: 38px;
      font-size: 16px;
    }
    .ant-select-selection {
      font-size: 16px;
    }
  }
`;

export const formAsterisk = [
  asterisk,
  css`
    font-weight: 900;
    margin-right: 4px;
  `,
];

// IE not support Grid Layout, so use flexbox here
export const gridRow = css`
  display: flex;
  > :first-child {
    flex: 1 0 33%;
    margin-right: 16px;
  }
  > :nth-child(2) {
    flex: 1 0 67%;
  }
  overflow-x: scroll;
  @media ${bp.sm} {
    overflow-x: inherit;
  }
`;

export const gridContainer = css`
  ${gridRow};
  border-bottom: 1px solid #dad9d7;
  padding: 16px 0px;
  font-size: 1.6rem;
`;

// for mobile, display in one column and no header
export const gridCol = css`
  ${gridContainer};
  @media (max-width: 575px) {
    flex-direction: column;
    > div {
      flex: 1 0 100%;
      margin-right: 0;
    }
    > :first-child {
      margin-bottom: 16px;
      font-weight: 800;
    }
  }
`;

export const gridColHeader = css`
  ${gridRow};
  @media (max-width: 575px) {
    display: none;
  }
`;
