import { css, jsx } from '@emotion/core';
import { Button, Icon } from 'antd';
import React, { PureComponent } from 'react';

import Container from '@/src/shared/components/Container';
import CustomIcon from '@/src/shared/components/Icon';
import Link from '@/src/shared/components/Link';
import { ADDRESS_CHICAGO, ADDRESS_DC, PHONE_CHICAGO, PHONE_DC } from '@/src/shared/data';
import { bp, colors } from '@/styles';

const style = {
  phoneIcon: css`
    padding-right: 4px;
  `,
  footer: hide => css`
    background-color: ${colors.primaryBlue};
    padding: 64px 0;
    color: ${colors.white};
    width: 100%;
    height: 100%;
    float: none;
    margin-top: 0;
    ${hide
      ? css`
          [href] {
            display: none;
          }
        `
      : css``};
    @media ${bp.md} {
      padding: 56px 0;
    }
    @media print {
      display: block;
      padding: 20px 0px;
      page-break-inside: avoid !important;
      break-inside: avoid !important;
      -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
      color-adjust: exact !important;
      color: #3d3e41;
      background-color: ${colors.white};
    }
  `,
  footerTop: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 33px;
    flex-direction: column;
    @media ${bp.md} {
      flex-direction: row;
    }
    @media print {
      display: block;
    }
  `,
  footerBack: css`
    width: 100%;
    color: ${colors.primaryBlue};
    background-color: ${colors.white};
    margin-bottom: 32px;
    border-radius: 50px;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 2px;
    height: 40px;
    text-transform: uppercase;
    font-weight: 600;
    @media ${bp.md} {
      display: none;
    }
    @media print {
      display: none;
    }
  `,
  footerImg: css`
    display: none;
    max-width: 30rem;
    @media ${bp.md} {
      display: block;
    }
    @media print {
      display: none;
    }
  `,
  footerSocial: css`
    @media print {
      display: none;
    }
  `,
  footerIcon: css`
    margin-left: 32px;
    cursor: pointer;
    color: ${colors.primaryBlue};
    transition-duration: 0ms;
    .anticon {
      width: 4.8rem;
      height: 4.8rem;
    }
    &:hover {
      color: ${colors.primaryRed};
    }
    &:first-of-type {
      margin-left: 0px;
    }
    @media ${bp.md} {
      .anticon {
        width: 4rem;
        height: 4rem;
      }
      &:first-of-type {
        margin-left: 16px;
      }
      margin-left: 16px;
    }
  `,
  footerLinks: css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media ${bp.md} {
      flex-direction: row;
    }
    @media print {
      display: none;
    }
  `,
  footerLink: css`
    padding-right: 0;
    margin-right: 0;
    color: ${colors.white};
    font-weight: 900;
    line-height: 24px;
    position: relative;
    margin-bottom: 32px;
    font-size: 20px;
    &:focus,
    &:active,
    &:hover {
      box-shadow: none !important;
      color: ${colors.white};
      outline: none;
      text-decoration: underline;
    }
    @media ${bp.md} {
      margin-bottom: 0px;
      font-size: 14px;
    }
    @media print {
      display: none;
    }
    &:after {
      display: none;
      @media ${bp.md} {
        display: block;
      }
    }
    &:not(:last-of-type) {
      padding-right: 15px;
      margin-right: 15px;
      &:after {
        content: '';
        height: 11px;
        width: 2px;
        position: absolute;
        right: 0;
        top: 7px;
        background-color: ${colors.white};
      }
    }
  `,
  footerWrapper: css`
    margin-top: 20px;
    display: block;
    @media ${bp.md} {
      display: flex;
    }
    @media print {
      display: flex;
      break-inside: avoid !important;
      width: 100% !important;
    }
  `,
  footerAddress: css`
    font-size: 14px;
    font-weight: 900;
    line-height: 24px;
    max-width: 225px;
    &:not(:last-of-type) {
      margin-bottom: 35px;
      @media ${bp.md} {
        margin-bottom: 0px;
        margin-right: 103px;
      }
      @media print {
        margin-bottom: 0px;
      }
    }
    span {
      padding-top: 4px;
    }
    a {
      color: ${colors.white};
    }
    address {
      margin-bottom: 8px;
    }
    @media print {
      max-width: 250px !important;
    }
  `,
  footerBottom: css`
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.171);
    @media print {
      border-top: 1px solid rgba(61, 62, 65, 0.173);
    }
  `,
  footerCopyright: css`
    margin-bottom: 8px;
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
  `,
  footerCopy: css`
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
    @media print {
      display: none;
    }
  `,
  footerCopyBold: css`
    font-weight: 600;
  `,
  footerBottomLogo: css`
    img {
      max-width: 30rem;
      margin-top: 45px;
      display: block;
      @media ${bp.md} {
        display: none;
      }
      @media print {
        display: none;
      }
    }
  `,
  footerCheck: css`
    margin-top: 8px;
    display: block;
    color: ${colors.white};
    line-height: 2rem;
  `,
  footerCopyTop: css`
    margin-bottom: 8px;
  `,
};

class Footer extends PureComponent {
  toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  render() {
    const {
      router: { route },
    } = this.props;
    const isPreview = route.indexOf('/preview') === 0;

    return (
      <footer css={style.footer(isPreview)}>
        <Container>
          <div css={style.footerTop}>
            {/* if mobile do not render this image below and instead render back to top */}
            <Button css={style.footerBack} htmlType="button" icon="up" onClick={this.toTop}>
              Back To Top
            </Button>
            <img
              src="/static/logo-white-secondary.svg"
              alt="APCIA logo"
              css={style.footerImg}
              loading="lazy"
            />
            <div css={style.footerSocial}>
              <a
                href="https://twitter.com/TeamAPCIA"
                css={style.footerIcon}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CustomIcon name="social/Twitter" />
              </a>
              <a
                href="https://www.facebook.com/TeamAPCIA/"
                css={style.footerIcon}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CustomIcon name="social/Facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/american-property-casualty-insurance-association/"
                css={style.footerIcon}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CustomIcon name="social/LinkedIn" />
              </a>
            </div>
          </div>
          <div css={style.footerLinks}>
            <Link href="/" css={style.footerLink}>
              Home
            </Link>
            <Link href="/about/contact-us" css={style.footerLink}>
              Contact Us
            </Link>
            <Link anchor href="https://www.statreporting.com/" css={style.footerLink}>
              Independent Statistical Service
            </Link>
            <Link
              href="https://www2.smartbrief.com/signupSystem/subscribe.action?pageSequence=1&briefName=apcia&campaign=index2"
              css={style.footerLink}
              anchor
            >
              Smart Brief
            </Link>
            <Link href="/sitemap" css={style.footerLink} anchor>
              Site Map
            </Link>
            <Link href="/terms-of-use" css={style.footerLink}>
              Terms of Use
            </Link>
            <Link anchor href="/privacy-policy" css={style.footerLink}>
              Privacy Policy
            </Link>
            <Link anchor href="/accessibility" css={style.footerLink}>
              Accessibility
            </Link>
          </div>
          <div css={style.footerWrapper}>
            <div css={style.footerAddress}>
              <address>{ADDRESS_DC}</address>
              <a href={`tel:${PHONE_DC}`} css={style.phoneNumber}>
                <Icon type="phone" theme="filled" css={style.phoneIcon} /> {PHONE_DC}
              </a>
            </div>
            <div css={style.footerAddress}>
              <address>{ADDRESS_CHICAGO}</address>
              <a href={`tel:${PHONE_CHICAGO}`}>
                <Icon type="phone" theme="filled" css={style.phoneIcon} /> {PHONE_CHICAGO}
              </a>
            </div>
          </div>
          <div css={style.footerBottom}>
            <div css={style.footerCopyright}>
              Copyright © {new Date().getFullYear()} by American Property Casualty Insurance
              Association
            </div>
            <div css={[style.footerCopy, style.footerCopyBold]}>
              American Property Casualty Insurance Association
            </div>
            <div css={style.footerCopy}>
              The American Property Casualty Insurance Association (APCIA) is the primary national
              trade association for home, auto, and business insurers. APCIA promotes and protects
              the viability of private competition for the benefit of consumers and insurers, with a
              legacy dating back 150 years. APCIA members represent all sizes, structures, and
              regions—protecting families, communities, and businesses in the U.S. and across the
              globe.
            </div>
          </div>
          {/* <div css={style.footerBottomLogo} loading="lazy">
            <img src="/static/logo-white-secondary.svg" alt="APCIA logo" css={style.footerImg} />
          </div> */}
        </Container>
      </footer>
    );
  }
}

export default Footer;
