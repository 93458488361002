import React from 'react';

const SvgPrivacy = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M36.401 9c6.339 0 11.495 5.157 11.495 11.495l-.001.21C52.84 22.993 56 27.888 56 33.357c0 7.697-6.3 13.96-14.043 13.96H11.426C5.126 47.317 0 42.221 0 35.957c0-6.035 4.76-10.986 10.743-11.338 1.254-5.995 6.659-10.421 12.896-10.421.992 0 1.977.11 2.936.327A11.462 11.462 0 0136.401 9zm0 1.916a9.555 9.555 0 00-8.502 5.163l-.362.695-.753-.217a11.351 11.351 0 00-3.145-.442c-5.566 0-10.356 4.118-11.142 9.58l-.119.83-.952-.01c-5.244 0-9.51 4.237-9.51 9.444 0 5.206 4.266 9.442 9.51 9.442h30.531c6.687 0 12.127-5.402 12.127-12.043 0-4.913-2.959-9.29-7.538-11.151l-.648-.264.054-.698c.019-.247.029-.497.029-.75 0-5.282-4.298-9.58-9.58-9.58zm-6.226 10.537A6.234 6.234 0 0136.4 27.68a6.201 6.201 0 01-2.874 5.248v8.642h-6.705v-8.642a6.201 6.201 0 01-2.874-5.248 6.234 6.234 0 016.227-6.227zm0 1.916a4.315 4.315 0 00-4.31 4.31c0 1.628.9 3.1 2.35 3.842l.523.267v7.866h2.874v-7.866l.522-.267a4.298 4.298 0 002.351-3.841 4.315 4.315 0 00-4.31-4.311zm-18.68 4.79a.958.958 0 010 1.915 5.754 5.754 0 00-5.747 5.748.958.958 0 01-1.916 0c0-4.226 3.438-7.663 7.663-7.663z"
    />
  </svg>
);

export default SvgPrivacy;
