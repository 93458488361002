import {
  LOAD_SELECTED_PROFILE_BEGIN,
  LOAD_SELECTED_PROFILE_ERROR,
  LOAD_SELECTED_PROFILE_SUCCESS,
  SET_UPDATE_PROFILE_FORM_DATA,
} from './types';

const INITIAL_STATE = {
  currentStep: 0,
  isMailingAddressDisabled: false,
  isOtherCountryVisible: false,
  isOtherMailingStateVisible: false,
  isOtherOfficeStateVisible: false,
  loadingProfile: true,
  PersonId: 0,
  Prefix: '',
  First: '',
  Middle: '',
  Last: '',
  Suffix: '',
  Informal: '',
  Title: '',
  Company: '',
  StreetAddress1: '',
  StreetAddress2: '',
  City: '',
  StateCode: '',
  PostalCode: '',
  CountryCode: '',
  OtherCountryCode: '',
  PoBoxNumber: '',
  PoBoxCity: '',
  PoBoxStateCode: '',
  PoBoxState: '',
  PoBoxPostalCode: '',
  Number: '',
  Extension: '',
  Mobile: '',
  FaxNumber: '',
  EmailAddress: '',
  ConfirmEmailAddress: '',
  Country: '',
  OfficeStateOther: '',
  PoBoxOther: '',
  Spouse: '',
  HomeAddress: '',
  AssistantName: '',
  AssistantTitle: '',
  AssistantPhone: '',
  AssistantEmail: '',
  Comments: '',
  OriginalData: '',
};

const ProfileUpdateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_UPDATE_PROFILE_FORM_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case LOAD_SELECTED_PROFILE_BEGIN:
      return {
        ...state,
        loadingProfile: action.payload.loadingProfile,
      };
    case LOAD_SELECTED_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingProfile: action.payload.loadingProfile,
        PersonId: action.payload.PersonId,
        Prefix: action.payload.Name.Prefix,
        First: action.payload.Name.First,
        Middle: action.payload.Name.Middle,
        Last: action.payload.Name.Last,
        Suffix: action.payload.Name.Suffix,
        Informal: action.payload.Name.Informal,
        Title: action.payload.Title,
        Company: action.payload.Company,
        StreetAddress1: action.payload.Office.StreetAddress1,
        StreetAddress2: action.payload.Office.StreetAddress2,
        City: action.payload.Office.City,
        StateCode: action.payload.Office.StateCode,
        PostalCode: action.payload.Office.PostalCode,
        CountryCode: action.payload.Office.CountryCode,
        Country: action.payload.Office.Country,
        PoBoxNumber: action.payload.PoBox.Number,
        PoBoxCity: action.payload.PoBox.City,
        PoBoxStateCode: action.payload.PoBox.StateCode,
        PoBoxState: action.payload.PoBox.State,
        PoBoxPostalCode: action.payload.PoBox.PostalCode,
        Number: action.payload.Phone.Number,
        Extension: action.payload.Phone.Extension,
        Mobile: action.payload.Mobile,
        FaxNumber: action.payload.FaxNumber,
        EmailAddress: action.payload.EmailAddress,
        ConfirmEmailAddress: action.payload.EmailAddress,
        Spouse: action.payload.Spouse,
        HomeAddress: action.payload.HomeAddress,
        HomePhone: action.payload.HomePhone,
        AssistantName: action.payload.Assistant.Name,
        AssistantTitle: action.payload.Assistant.Title,
        AssistantPhone: action.payload.Assistant.Phone,
        AssistantEmail: action.payload.Assistant.EmailAddress,
        Comments: action.payload.Comments,
        OriginalData: action.payload,
      };
    case LOAD_SELECTED_PROFILE_ERROR:
      return {
        ...state,
        loadingProfile: action.payload.loadingProfile,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ProfileUpdateReducer;
