import React from 'react';

const FourOhFourIcon = () => (
  <svg
    width="266px"
    height="266px"
    viewBox="0 0 266 266"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Mockups" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Z---404-Page" transform="translate(-359.000000, -291.000000)">
        <g id="404-Icon" transform="translate(359.000000, 291.000000)">
          <circle id="Oval" stroke="#FFFFFF" strokeWidth="3" cx="133" cy="133" r="73.5" />
          <circle
            id="Oval-Copy"
            stroke="#FFFFFF"
            strokeWidth="3"
            opacity="0.5"
            cx="133"
            cy="133"
            r="103.5"
          />
          <circle
            id="Oval-Copy-2"
            stroke="#FFFFFF"
            strokeWidth="3"
            opacity="0.25"
            cx="133"
            cy="133"
            r="131.5"
          />
          <g id="Icons/question-circle" transform="translate(83.000000, 83.000000)" fill="#FFFFFF">
            <path
              d="M50,6.25 C25.8349609,6.25 6.25,25.8349609 6.25,50 C6.25,74.1650391 25.8349609,93.75 50,93.75 C74.1650391,93.75 93.75,74.1650391 93.75,50 C93.75,25.8349609 74.1650391,6.25 50,6.25 Z M49.444579,75.6860353 C47.4023426,75.6860353 45.7446277,74.0283204 45.7446277,71.9860841 C45.7446277,69.9438477 47.4023426,68.2861328 49.444579,68.2861328 C51.4868153,68.2861328 53.1445302,69.9438477 53.1445302,71.9860841 C53.1445302,74.0283204 51.4868153,75.6860353 49.444579,75.6860353 Z M65.3210459,39.6606436 C65.1074229,43.6169423 63.894044,46.7358387 57.8271495,52.8027331 C54.7509776,55.8789049 52.8112804,58.1860338 52.6147472,60.2111802 C52.4609386,61.8005356 51.1193859,62.9882798 49.5556652,62.9882798 C49.4531261,62.9882798 49.350587,62.9797348 49.248048,62.9711899 C47.5561535,62.8088364 46.3256847,61.2963853 46.4880382,59.6044908 C46.9067394,55.3405749 50.0341808,51.8969714 53.4777843,48.453368 C58.9892589,42.9418935 59.083253,41.1901845 59.1772471,39.3359365 C59.2883311,37.1740714 58.4936534,35.0805655 56.9213878,33.4313957 C55.1354991,31.5515129 52.6062023,30.4748528 49.9829113,30.4748528 C49.9743663,30.4748528 49.9658214,30.4748528 49.9658214,30.4748528 C44.9157726,30.4833977 40.7971203,34.6020499 40.7971203,39.6606436 C40.7971203,41.361083 39.4213879,42.7368154 37.7209484,42.7368154 C36.020509,42.7368154 34.6447766,41.361083 34.6447766,39.6606436 C34.6447766,35.5676261 36.234132,31.7224113 39.1223156,28.8256829 C42.0104992,25.9289544 45.855714,24.3310541 49.9487316,24.3224785 C54.2639171,24.3139642 58.4338389,26.0913079 61.381837,29.1931145 C64.1333018,32.0727531 65.5261241,35.789794 65.3210459,39.6606436 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default FourOhFourIcon;
