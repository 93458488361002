import { css, jsx } from '@emotion/core';
import { Checkbox, Col, Icon, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  saveSearch,
  setIncludedOnHomepageByIdAndValue,
  setSelectedSearchAndOpenModal,
} from '@/src/search/redux/actions';
import Heading from '@/src/shared/components/Heading';
import { bp, colors, space } from '@/styles';
import { transformDateRange } from '@/utils/dateTimeHelpers';

const KEY_CODE_MAP = {
  States: 'StateCode',
  LinesOfBusiness: 'LobCode',
  Subjects: 'SubjectCode',
  Publications: 'PublicationCode',
  DayRanges: 'DayRangeCode',
};

function getComplexObject(key, values) {
  return values.map(fieldOptionValue => ({ [KEY_CODE_MAP[key]]: fieldOptionValue }));
}

const { m } = space;
const style = {
  checkbox: css`
    .ant-checkbox {
      &-checked .ant-checkbox-inner {
        background-color: ${colors.secondaryBlue};
        border-color: ${colors.secondaryBlue};
      }
    }
  `,
  edit: css`
    font-weight: bold;
    color: ${colors.secondaryBlue};
    font-size: 1.2rem;
    &:hover {
      cursor: pointer;
      color: ${colors.primaryRed} !important;
    }
  `,
  editText: css`
    visibility: hidden;
    font-size: 1.2rem;
    @media ${bp.md} {
      visibility: visible;
    }
  `,
  row: css`
    margin-bottom: 20px;
  `,
  searchTitle: css`
    color: ${colors.primaryBlue};
    &:hover {
      cursor: pointer;
      color: ${colors.primaryRed};
    }
  `,
};
const SavedSearchRow = props => {
  const {
    savedSearch,
    headingClick,
    nav,
    setIncludedOnHomepageByIdAndValue: _setIncludedOnHomepageByIdAndValue,
    setSelectedSearchAndOpenModal: _setSelectedSearchAndOpenModal,
    saveSearch: _saveSearch,
    ..._props
  } = props;

  async function handleCheckboxChange() {
    const _savedSearchCopy = savedSearch;
    const { FieldOptions, SearchId, ...rest } = _savedSearchCopy;
    const prevIncludeOnHomePage = _savedSearchCopy.IncludeOnHome;
    const newIncludeOnHomePage = prevIncludeOnHomePage === 'Y' ? 'N' : 'Y';
    await _setIncludedOnHomepageByIdAndValue(SearchId, newIncludeOnHomePage);
    const payload = {
      ...rest,
      SearchId,
      IncludeOnHome: newIncludeOnHomePage,
      FieldOptions: {
        ...FieldOptions,
        DateRanges: transformDateRange(FieldOptions.DateRanges),
      },
    };
    await _saveSearch({
      body: payload,
      noReloadBody: true,
      onError: () => {
        _setIncludedOnHomepageByIdAndValue(SearchId, prevIncludeOnHomePage);
      },
    });
  }

  return (
    <Row {..._props} gutter={16} type="flex" css={m.b16}>
      <Col xs={nav ? 15 : 14}>
        <Heading type={4} weight="bold" css={style.searchTitle} onClick={headingClick}>
          {savedSearch.SearchDescription}
        </Heading>
      </Col>
      <Col xs={3}>
        <span
          css={[style.edit]}
          onClick={() => {
            _setSelectedSearchAndOpenModal(savedSearch.SearchId);
          }}
        >
          <Icon type="edit" theme="filled" css={m.r8} />
          <span css={style.editText}>Edit</span>
        </span>
      </Col>
      <Col xs={nav ? 6 : 3}>
        <span>
          <Checkbox
            css={style.checkbox}
            checked={savedSearch.IncludeOnHome === 'Y'}
            onChange={handleCheckboxChange}
          />
          {nav && (
            <span
              css={css`
                margin-left: 8px;
              `}
            >
              Show on homepage
            </span>
          )}
        </span>
      </Col>
    </Row>
  );
};

SavedSearchRow.propTypes = {
  savedSearch: PropTypes.object.isRequired,
  headingClick: PropTypes.func,
  nav: PropTypes.bool,
};

SavedSearchRow.defaultProps = {
  headingClick: () => {},
  nav: false,
};

export default connect(
  null,
  {
    setIncludedOnHomepageByIdAndValue,
    setSelectedSearchAndOpenModal,
    saveSearch,
  }
)(SavedSearchRow);
