import {
  LOAD_NAMED_PARTY_RESULTS_BEGIN,
  LOAD_NAMED_PARTY_RESULTS_ERROR,
  LOAD_NAMED_PARTY_RESULTS_SUCCESS,
} from '../types';

const DEFAULT_AMICUS_RESULTS_STATE = {
  results: [],
  count: 0,
  SortOrder: 'ASC',
  SortField: 'CASENAME',
  isFetchingResults: false,
};

const SearchResultsReducer = (state = DEFAULT_AMICUS_RESULTS_STATE, action) => {
  const { type } = action;
  switch (type) {
    case LOAD_NAMED_PARTY_RESULTS_BEGIN:
      return {
        ...state,
        isFetchingResults: action.payload.isFetchingResults,
      };
    case LOAD_NAMED_PARTY_RESULTS_ERROR:
      return {
        ...state,
        isFetchingResults: action.payload.isFetchingResults,
      };
    case LOAD_NAMED_PARTY_RESULTS_SUCCESS:
      return {
        ...state,
        isFetchingResults: action.payload.isFetchingResults,
        results: action.payload.results,
        count: action.payload.count,
      };
    default:
      return state;
  }
};
export default SearchResultsReducer;
