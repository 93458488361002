import React from 'react';

const SvgSearch = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M17.733 0c9.242 0 16.733 7.492 16.733 16.733 0 4.517-1.79 8.617-4.7 11.627l3.799 3.799.042-.04a3.083 3.083 0 014.36 0L55.05 49.201a3.083 3.083 0 010 4.36l-1.536 1.535a3.083 3.083 0 01-4.36 0L32.071 38.014a3.083 3.083 0 010-4.36l.041-.042-3.863-3.862a16.66 16.66 0 01-10.194 3.713l-.322.003C8.492 33.466 1 25.975 1 16.733 1 7.492 8.492 0 17.733 0zM35.06 33.572l-1.535 1.535a1.028 1.028 0 000 1.453l17.083 17.084a1.028 1.028 0 001.453 0l1.536-1.536a1.028 1.028 0 000-1.453L36.513 33.572a1.028 1.028 0 00-1.453 0zM17.733 2.055c-8.106 0-14.678 6.572-14.678 14.678 0 8.107 6.572 14.678 14.678 14.678 8.107 0 14.678-6.571 14.678-14.678 0-8.106-6.571-14.678-14.678-14.678zm0 5.027l2.837 5.747 6.342.922-4.59 4.473 1.084 6.317-5.673-2.982-5.673 2.982 1.084-6.317-4.59-4.473 6.343-.922 2.836-5.747zm0 4.644l-1.471 2.982-3.291.478 2.381 2.32-.562 3.278 2.943-1.547 2.943 1.547-.562-3.277 2.381-2.321-3.29-.478-1.472-2.982z"
    />
  </svg>
);

export default SvgSearch;
