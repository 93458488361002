import React from 'react';

const SvgInsurance = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M28.265 3.145c.002-.031.018-.058.018-.09V1.018a1.018 1.018 0 10-2.037 0v2.037c0 .045.02.083.026.127-12.57.73-22.874 10.553-24.266 23.427a1.018 1.018 0 001.495 1.006c1.53-.826 3.758-1.299 6.111-1.299 4.467 0 7.804 1.695 7.804 3.211a1.018 1.018 0 102.036 0c0-1.399 2.846-2.947 6.794-3.178V53.02h.01C26.31 54.696 28.07 56 30.32 56c2.284 0 4.073-1.342 4.073-3.055a1.018 1.018 0 10-2.036 0c0 .416-.794 1.019-2.037 1.019-1.243 0-2.036-.603-2.036-1.019V26.35c3.939.235 6.775 1.78 6.775 3.177a1.018 1.018 0 102.036 0c0-1.516 3.337-3.21 7.803-3.21 2.913 0 5.676.753 7.04 1.92a1.018 1.018 0 001.677-.853c-1.06-13.432-12.149-23.982-25.35-24.239zM9.612 24.28c-1.936 0-3.769.278-5.33.797C6.156 14.67 14.416 6.778 24.612 5.405c-4.618 4.612-7.428 12.229-7.52 20.65-1.776-1.098-4.417-1.775-7.48-1.775zm17.643 0c-3.465 0-6.401.86-8.142 2.224 0-.011.006-.02.006-.03 0-8.654 3.179-16.664 8.146-20.711 4.967 4.047 8.145 12.057 8.145 20.71 0 .018.01.032.01.05-1.738-1.374-4.685-2.243-8.165-2.243zm17.642 0c-3.051 0-5.685.672-7.46 1.762-.095-8.506-2.968-16.185-7.671-20.78 10.808.932 19.783 9.351 21.571 20.243-1.744-.783-4.007-1.225-6.44-1.225z"
    />
  </svg>
);

export default SvgInsurance;
