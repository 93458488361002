import { css, jsx } from '@emotion/core';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { colors } from '@/styles';

const style = {
  inlineMenu: css`
    & > .ant-menu-submenu-title {
      padding-left: 0 !important;
      padding-right: 10px !important;
      height: auto !important;
      line-height: 1 !important;
      list-style-type: none !important;
      > .ant-menu-submenu-arrow {
        right: 5px;
        top: calc(50% + 4px);
        &::before,
        &::after {
          width: 12px !important;
          background: ${colors.primaryBlue};
        }
        &::before {
          transform: translate(3px, -2px) rotate(-50deg);
        }
        &::after {
          transform: translate(-3px, -2px) rotate(50deg);
        }
      }
      &:hover {
        .ant-menu-submenu-arrow {
          &::before,
          &::after {
            background: ${colors.primaryRed};
          }
        }
      }
    }
    &.ant-menu-submenu-open {
      > .ant-menu-submenu-title {
        > span {
          color: ${colors.primaryRed};
        }
        > .ant-menu-submenu-arrow {
          &::before,
          &::after {
            background: ${colors.primaryRed};
          }
        }
      }
    }
  `,
  inlineMenuSub: css`
    padding-left: 24px !important;
  `,
  subsub: css`
    & > .ant-menu-submenu-title {
      margin: 0 !important;
      display: flex;
      align-items: center;
      .ant-menu-submenu-arrow {
        position: relative;
        display: inline-block;
        &::before,
        &::after {
          position: absolute;
          width: 8px !important;
          height: 1.5px;
          background-color: ${colors.secondaryGrey} !important;
        }
        &::before {
          left: -1.5px;
        }
      }
      &:hover {
        .ant-menu-submenu-arrow {
          &::before,
          &::after {
            background: ${colors.primaryRed} !important;
          }
        }
      }
    }
    &.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      transform: none !important;
      &::before,
      &::after {
        background: ${colors.primaryRed} !important;
      }
    }
    .ant-menu-sub {
      padding-left: 48px;
      .ant-menu-item {
        padding: 0 !important;
      }
    }
  `,
};

const SubMenu = props => {
  const { children, subsub, ..._props } = props;
  return (
    <Menu.SubMenu {..._props} css={[style.inlineMenu, ...(subsub ? [style.subsub] : [])]}>
      {children}
    </Menu.SubMenu>
  );
};

SubMenu.propTypes = {
  subsub: PropTypes.bool,
};

SubMenu.defaultProps = {
  subsub: false,
};

export default SubMenu;
