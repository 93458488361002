import { combineReducers } from 'redux';

import fieldOptionsData from './fieldOptionsReducer';
import legalCaseData from './legalCaseReducer';
import currentPage from './pageReducer';
import searchCriteria from './searchCriteriaReducer';
import searchData from './searchResultsReducer';
import sortField from './sortFieldReducer';
import sortOrder from './sortOrderReducer';

export default combineReducers({
  currentPage,
  fieldOptionsData,
  legalCaseData,
  searchCriteria,
  searchData,
  sortField,
  sortOrder,
});
