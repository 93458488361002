import { css, jsx } from '@emotion/core';
import { Form as AntForm, Icon, Modal } from 'antd';
import { useRouter } from 'next/router';
import { normalize } from 'normalizr';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { deleteSearch, saveSearch, setModalVisibility } from '@/src/search/redux/actions';
import fieldSelector from '@/src/search/redux/selectors/fieldOptions';
import schema from '@/src/search/utils/schema/fieldOptions';
import Button from '@/src/shared/components/Button';
import Form from '@/src/shared/components/Form';
import Heading from '@/src/shared/components/Heading';
import { X } from '@/src/shared/components/Icon/_icons';
import { bp, space } from '@/styles';
import request from '@/utils/axios';
import formatURL from '@/utils/formatURL';

import SavedSearchForm from './SavedSearchForm';
import style from './style';
import { deleteSavedSearch, save } from './utils';

const { m } = space;

const index = props => {
  const { modalVisible, selectedSearch, form, isSavingSearch } = props;
  const router = useRouter();
  const [isFetchingSearchOptions, setIsFetchingSearchOptions] = useState(true);
  const [searchOptions, setSearchOptions] = useState({
    States: {},
    LinesOfBusiness: {},
    Subjects: {},
    Publications: {},
    DayRanges: {},
    PostingTypes: {},
    Committees: {},
  });

  useEffect(() => {
    if (modalVisible && !Object.keys(searchOptions.States).length) {
      (async function mounted() {
        try {
          const response = await request({
            url: '/cms/searchoptions',
            method: 'GET',
          });
          const { data } = response;
          const normalizedData = normalize(data.FieldOptions, schema).entities;
          setSearchOptions(normalizedData);
          setIsFetchingSearchOptions(false);
        } catch (error) {
          console.log(error);
          setIsFetchingSearchOptions(false);
        }
      })();
    }
  }, [modalVisible]);

  const {
    States,
    LinesOfBusiness,
    Subjects,
    Publications,
    DayRanges,
    PostingTypes,
    Committees,
  } = searchOptions;

  return (
    <Modal
      onCancel={() => {
        props.setModalVisibility(false);
      }}
      visible={modalVisible}
      width="80%"
      title={
        <Heading type={3} weight="bolder" css={m.b0} theme="primaryGray">
          Edit Search
        </Heading>
      }
      maskStyle={{
        backgroundColor: 'rgba(0,38,73,0.65)',
      }}
      // wrapClassName="ant-modal-priority"
      footer={[
        <div css={style.footerWrapper} key="delete-button">
          <span css={style.footerButtonWrapper}>
            <Button
              size="small"
              theme="red"
              onClick={() => {
                deleteSavedSearch(selectedSearch.SearchId, () => {
                  props.deleteSearch({
                    searchId: selectedSearch.SearchId,
                    successCallback: () => {
                      props.setModalVisibility(false);
                      const formattedUrl = formatURL(router.asPath, { searchId: null });
                      router.push(formattedUrl, formattedUrl, { shallow: true });
                    },
                  });
                });
              }}
              disabled={isSavingSearch || isFetchingSearchOptions}
            >
              <Icon type="delete" />
              Delete Saved Search
            </Button>
          </span>
          <span css={style.footerButtonWrapper}>
            <Button
              theme="gray"
              size="small"
              css={style.cancel}
              disabled={isSavingSearch || isFetchingSearchOptions}
              onClick={() => {
                props.setModalVisibility(false);
              }}
            >
              <span css={style.cancelInner}>
                <span css={style.cancelIcon}>
                  <X ant={false} name="x" css={style.cancelIcon} />
                </span>
                <span css={style.cancelIcon}>Cancel</span>
              </span>
            </Button>
            <Button
              disabled={isSavingSearch || isFetchingSearchOptions}
              size="small"
              theme="default"
              css={style.saveSearch}
              htmlType="submit"
              onClick={() => {
                const { query } = router;
                const successCallback = () => {
                  props.setModalVisibility(false);
                  form.resetFields();
                  const editQuery = `&edit=${new Date().getTime()}`;
                  const formattedUrl = `/search?searchId=${selectedSearch.SearchId}${editQuery}`;
                  router.push(formattedUrl, formattedUrl, { shallow: true });
                };
                save(form, searchOptions, props.saveSearch, selectedSearch, successCallback);
              }}
            >
              <Icon type="save" theme="filled" />
              Save Search
            </Button>
          </span>
        </div>,
      ]}
    >
      <Form>
        <SavedSearchForm
          data={selectedSearch}
          form={form}
          fieldOpts={{
            stateOpts: fieldSelector(States),
            lobOpts: fieldSelector(LinesOfBusiness),
            subjectOpts: fieldSelector(Subjects),
            pubOpts: fieldSelector(Publications),
            dayOpts: fieldSelector(DayRanges),
            typeOpts: fieldSelector(PostingTypes),
            commOpts: fieldSelector(Committees),
          }}
        />
      </Form>
    </Modal>
  );
};

function mapStateToProps(state) {
  const {
    search: {
      savedSearchesData: { selectedSearch, modalVisible },
      saveSearchResponse: { isSavingSearch },
    },
  } = state;

  return { isSavingSearch, modalVisible, selectedSearch };
}

export default connect(
  mapStateToProps,
  {
    setModalVisibility,
    saveSearch,
    deleteSearch,
  }
)(AntForm.create()(index));
