import { get } from 'lodash';

import { form } from '@/styles';

/**
 * @param {object[]} arr  array of the data
 * @param {string} key key of the object in the array to be used in the initial value array
 * @returns {Array} initial values
 */
export function getArrValues(arr, key = 'key') {
  try {
    if (Array.isArray(arr) && arr.length) {
      const arrValues = arr.map(item => item[key]);
      return arrValues;
    }
  } catch (err) {
    console.warn(err);
    return [];
  }
  return [];
}

/**
 * @param {string} keyVal - key for array of objects
 * @param {Array} arrVals - array of values from AntD multiselect
 * @returns {Array} - array of objects to be included in the payload
 */

export function formatMultiSelectData(arrVals, keyVal) {
  try {
    if (Array.isArray(arrVals) && arrVals.length) {
      const formattedData = arrVals.map(v => {
        const dataObj = {};
        dataObj[keyVal] = v;
        return dataObj;
      });
      return formattedData;
    }
  } catch (err) {
    return [];
  }

  return [];
}

/**
 * @param {object} values -
 * @param {object} opts -
 * @returns {{_options, getFullComplex, getValueComplex}} values decorated with _options, getFullComplex, getValueComplex
 */
export function addTransformUtils(values, opts) {
  const _values = { ...values };

  _values._options = opts;

  _values.getFullComplex = function getFullComplex(optionsName, code) {
    const _value = get(this, optionsName, []);

    try {
      const _opts = get(this._options, optionsName);

      return _opts.filter(x => _value.includes(x[code]));
    } catch (error) {
      if (!Array.isArray(_value)) {
        console.log(new Error('Field Value must be an Array'));
      } else {
        console.error(error);
      }
      return [];
    }
  };
  _values.getValueComplex = function getValueComplex(value, code) {
    return {
      [code]: value,
    };
  };
  return _values;
}

/**
 * @param {boolean} loading -
 * @returns {object} props
 */
export function multiSelectProps(loading = false) {
  return {
    css: form.inputGray,
    mode: 'multiple',

    optionFilterProp: 'children',
    showArrow: true,
    allowClear: true,
    loading,
  };
}

/**
 * @param {boolean} loading -
 * @returns {object} props
 */
export function selectProps(loading = false) {
  return {
    optionFilterProp: 'children',
    showArrow: true,
    size: 'large',
    loading,
    allowClear: true,
  };
}

export function checkIfValueInSaved(value) {
  if (!value) {
    return false;
  }
  if (Array.isArray(value)) {
    return value.filter(e => e).length > 0;
  }
  return true;
}
