import React from 'react';

const SvgHazard = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M41.7 50.773a1.061 1.061 0 110 2.123H14.096a1.061 1.061 0 110-2.123zM27.896 3c1.203 0 2.34.733 3.12 2.012L55.093 44.47c.801 1.312.923 2.605.342 3.64-.58 1.032-1.743 1.6-3.274 1.6H3.635c-1.532 0-2.694-.568-3.273-1.6-.58-1.034-.46-2.327.342-3.64L24.78 5.012C25.56 3.734 26.696 3 27.897 3zm.001 2.123c-.444 0-.92.363-1.306.994L2.516 45.576c-.372.61-.485 1.17-.303 1.495.182.323.714.517 1.422.517H52.16c.71 0 1.24-.194 1.422-.517.183-.326.07-.885-.302-1.495L29.205 6.118c-.386-.632-.862-.995-1.307-.995zm0 33.972a3.188 3.188 0 013.185 3.185 3.188 3.188 0 01-3.185 3.184 3.188 3.188 0 01-3.184-3.184 3.188 3.188 0 013.184-3.185zm0 2.123a1.063 1.063 0 000 2.123 1.063 1.063 0 000-2.123zm2.124-28.663c.586 0 1.061.475 1.061 1.061V35.91c0 .586-.475 1.062-1.061 1.062h-4.247a1.061 1.061 0 01-1.061-1.062V13.616a1.06 1.06 0 011.061-1.061zm-1.062 2.123h-2.123v20.17h2.123v-20.17z"
    />
  </svg>
);

export default SvgHazard;
