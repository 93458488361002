/* eslint-disable react/jsx-boolean-value */
import '@/styles/global.css';
import 'swiper/css';
import 'antd/dist/antd.less';

import { Auth0Provider } from '@auth0/auth0-react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/core';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import AppInner from '@/src/App';
import Head from '@/src/shared/components/Head';
import { makeStore, wrapper } from '@/src/store';

import disableReactDevTools from '../utils/disableReactDevTools';

export const cache = createCache({ key: 'apcia' });

function APCIA_APP(props) {
  const { Component, pageProps, router } = props;
  const store = useStore();
  const production = process.env.NODE_ENV === 'production';
  const { pagedata } = pageProps;
  const { MetaTitle, MetaDescription } = pagedata || {};

  const onRedirectCallback = appState => {
    console.log('Redirecting to', appState.returnTo)
    router.push(appState.returnTo);
  };

  useEffect(() => {
    if (production) {
      disableReactDevTools();
    }
  }, []);

  return (
    <CacheProvider value={cache}>
      <Auth0Provider
        domain={process.env.AUTH0_TENANT_URL}
        clientId={process.env.AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri:
            typeof window !== 'undefined' ? window.location.origin : process.env.API_ROOT,
        }}
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
        onRedirectCallback={onRedirectCallback}
      >
        <Head
          favicon
          title={MetaTitle || 'APCIA American Property Casualty Insurance Association'}
          description={
            MetaDescription || 'American Property Casualty Insurance Association description'
          }
        />
        {store.persistor ? (
          <PersistGate persistor={store.persistor}>
            <AppInner store={store} Component={Component} pageProps={pageProps} router={router} />
          </PersistGate>
        ) : (
          <AppInner store={store} Component={Component} pageProps={pageProps} router={router} />
        )}
      </Auth0Provider>
    </CacheProvider>
  );
}

export default wrapper.withRedux(APCIA_APP);
