import { css, jsx } from '@emotion/core';
import { Spin, Tabs } from 'antd';
import router from 'next/router';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchSavedSearches, setDefaultSortSearch } from '@/src/search/redux/actions';
import Heading from '@/src/shared/components/Heading';
import SavedSearchRow from '@/src/shared/components/SavedSearchModal/SavedSearchRow';
import { bp, colors, space } from '@/styles';

import { TopMenuItem } from './components';
import SearchForm from './components/SearchForm';

const { m } = space;
const { TabPane } = Tabs;

const style = {
  search: css`
    display: none;
    @media ${bp.lg} {
      display: list-item;
    }
  `,
  tabs: css`
    /* .ant-tabs-bar {
      border-bottom: 0;
    } */
    .ant-tabs-tab {
      color: ${colors.primaryBlue};
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: bold;
      padding: 0 0 22px;
      &.ant-tabs-tab-active {
        color: ${colors.primaryRed};
      }
    }
    .ant-tabs-ink-bar {
      height: 6px;
      background-color: ${colors.primaryRed};
    }
  `,
  saveSearchTab: css`
    max-height: 60vh;
    overflow: hidden auto;
  `,
};

const NavSearch = props => {
  const {
    savedSearches,
    isFetchingSavedSearches,
    fetchedSavedSearches,
    LinesOfBusiness,
    fetchSavedSearches: _fetchSavedSearches,
    setSelectedSearchAndOpenModal: _setSelectedSearchAndOpenModal,
    setDefaultSortSearch: _setDefaultSortSearch,
    personId,
    isLoggedIn,
    ..._props
  } = props;

  useEffect(() => {
    if (!fetchedSavedSearches && isLoggedIn && personId) {
      props.fetchSavedSearches({ personId, allow401: true });
    }
  }, [fetchedSavedSearches, isLoggedIn, personId]);

  return (
    <TopMenuItem
      {..._props}
      title={<span>Search</span>}
      className="hideIfClosed"
      popupClassName="ant-menu-xlarge"
      css={style.search}
    >
      <div>
        <Tabs defaultActiveKey="1" css={style.tabs}>
          <TabPane tab="Search the Entire Site" key="1">
            <SearchForm />
          </TabPane>
          <TabPane tab="My Saved Searches" key="2">
            <Heading
              type={4}
              weight="bold"
              theme="primaryGray"
              css={css`
                margin-bottom: 16px;
              `}
            >
              Search Name
            </Heading>
            {!isFetchingSavedSearches ? (
              <div css={style.saveSearchTab}>
                {Object.values(savedSearches).map(ss => (
                  <SavedSearchRow
                    nav
                    key={ss.SearchId}
                    savedSearch={ss}
                    headingClick={async () => {
                      await _setDefaultSortSearch();
                      router.push(`/search?searchId=${ss.SearchId}`);
                    }}
                  />
                ))}
              </div>
            ) : (
              <div css={[m.x16, m.y8, css``]}>
                <Spin css={m.r8} />
                <strong>Loading Saved Searches</strong>
              </div>
            )}
          </TabPane>
        </Tabs>
      </div>
    </TopMenuItem>
  );
};

function mapStateToProps(state) {
  const {
    search: {
      savedSearchesData: { savedSearches, isFetchingSavedSearches, fetchedSavedSearches },
    },
    auth: {
      userinfo: { PersonID },
      isLoggedIn,
    },
  } = state;
  return {
    isFetchingSavedSearches,
    fetchedSavedSearches,
    savedSearches,
    personId: PersonID,
    isLoggedIn,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchSavedSearches,
    setDefaultSortSearch,
  }
)(NavSearch);
