import React from 'react';

const SvgTelephone = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M55.644 43.292l-9.866-9.866c-.38-.38-.98-.427-1.412-.105l-7.092 5.242c-1.01.746-2.612.628-3.498-.257l-16.12-16.12c-.885-.885-1.003-2.487-.256-3.497l5.242-7.092a1.085 1.085 0 00-.105-1.413L12.67.318A1.084 1.084 0 0011.178.28L4.3 6.47c-5.073 4.565-5.753 12.597-1.529 17.934.37.585 2.8 4.116 13.737 15.052 10.895 10.895 14.43 13.34 15.032 13.722C33.882 55.074 36.76 56 39.642 56c3.635-.001 7.276-1.475 9.852-4.338l6.19-6.877a1.085 1.085 0 00-.04-1.493zm-7.763 6.918c-3.872 4.302-10.61 4.857-15.022 1.241a1.096 1.096 0 00-.142-.098c-.125-.074-3.192-1.95-14.675-13.432C6.557 26.434 4.672 23.356 4.603 23.24a1.071 1.071 0 00-.095-.142C.942 18.644 1.5 11.907 5.752 8.082l6.112-5.5 8.477 8.475-4.687 6.341c-1.396 1.889-1.19 4.666.468 6.323l16.12 16.12c1.657 1.658 4.435 1.864 6.322.466l6.34-4.685 8.477 8.476-5.5 6.112z"
    />
  </svg>
);

export default SvgTelephone;
