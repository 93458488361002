import React from 'react';

const Facebook = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx="20" cy="20" r="20" />
      <path
        d="M25.654 9.651v3.332h-1.982c-.723 0-1.211.152-1.463.454-.253.303-.38.758-.38 1.363v2.386h3.699l-.493 3.735H21.83V30.5h-3.862v-9.579H14.75v-3.735h3.218v-2.752c0-1.564.438-2.778 1.313-3.64.875-.863 2.04-1.294 3.495-1.294 1.237 0 2.196.05 2.878.151z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Facebook;
