/**
 * @param {string} url - formatted
 * @param {object} queryGroup - key/vals of query params
 * @returns {string} url
 */
const formatURL = (url, queryGroup) => {
  let _url = url;

  // https://dev.pciaa.net/search?searchtext=foo&states=hi,ak&publications=123,456
  // https://dev.pciaa.net/search?searchtext=bar&states=hi,ak&publications=123,456
  Object.entries(queryGroup).forEach(([queryName, queryVal]) => {
    const indexOfQuery = _url.indexOf(queryName);
    const hasQueryInitializer = _url.indexOf('?') > -1;

    // Query string key is already in the url
    if (indexOfQuery > -1) {
      const queryMatch = _url.match(new RegExp(`${queryName}=([^&]*)`));
      // eslint-disable-next-line no-unused-vars
      const [fullMatch, currentQueryVal] = queryMatch;

      // query string key has a value in the current url and the new value is not falsey
      if (queryMatch) {
        if (queryVal) {
          // fullMatch: searchtext=foo
          // queryName: searchtext
          // queryVal: bar
          _url = _url.replace(fullMatch, `${queryName}=${queryVal}`);
          // console.log({_url})
        } else {
          _url = _url.replace(new RegExp(`(?:&?)${fullMatch}`), '');
        }
        // console.log(queryMatch)

        // console.log({fullMatch, currentQueryVal});
      }
    } else if (queryVal) {
      _url = `${_url}${hasQueryInitializer ? '&' : '?'}${queryName}=${queryVal}`;
    }
  });

  return _url;
};

export default formatURL;
