/* eslint-disable import/prefer-default-export */
import dompurify from 'dompurify';
import { isObjectLike } from 'lodash';

import formatURL from '@/utils/formatURL';

/**
 * Gets the appropriate icon name from the file extension
 *
 * @returns {string} ant design icon name
 * @param {string} extension - file extension with or without the leading '.'
 */
export function getFileIcon(extension = '') {
  const _ext = extension.indexOf('.') === 0 ? extension.slice(1) : extension;
  const exts = {
    image: ['jpg', 'jpeg', 'png', 'svg', 'bmp', 'gif', 'tiff', 'ico'],
    excel: ['xls', 'xlsx', 'xlt', 'ods'],
    markdown: ['md', 'mdx'],
    pdf: ['pdf'],
    ppt: ['ppt', 'pptx', 'odp', 'key', 'pps'],
    text: ['txt', 'odt', 'rtf', 'wpd', 'wks', 'wps', 'odt'],
    word: ['doc', 'docx'],
    zip: ['zip', 'rar', 'tar', 'gz', '7z', 'dmg', 'jar'],
  };
  let iconName = 'file-unknown';
  Object.entries(exts).forEach(([key, value]) => {
    if (value.includes(_ext)) iconName = `file-${key}`;
  });
  return iconName;
}

export function sanitize(markup) {
  return dompurify.sanitize(markup);
}

export const CAT_ID_MAP = {
  '9996': 'publication',
  '9997': 'meeting',
  '9998': 'newsrelease',
};

export function getPath(catid, sitePageId) {
  switch (catid) {
    case 9996:
      // publication
      return [`/publications/${sitePageId}`, `/publications/[slug]`];
    case 9997:
      // meeting
      return [`/events/meetings/meeting/${sitePageId}`, `/events/meetings/meeting/[slug]`];
    case 9998:
      // news release
      return [`/media/news-releases/release/${sitePageId}`, `/media/news-releases/release/[slug]`];
    default:
      // content page, everything else
      return [`/${sitePageId}`, `/[slug]`];
  }
}

export function matchAll(content, regex) {
  // If IE11 support ever drops, use Array.from(_content.matchAll(attachmentRegex));
  const matches = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const match = regex.exec(content);
    if (match === null) break;
    matches.push(match);
  }
  return matches;
}

const PUBCODE_TITLE_MAP = {
  '26': 'Accident Prevention Compliance for Insurers',
  '2': 'Financial Regulation and Accounting Bulletin',
  '413': 'Actuarial Bulletin',
  '490': 'Adopted Regulation Bulletin',
  '468': 'Agents Licensing Compliance Guide',
  '442': 'APCIA Announcement',
  '8': 'Automobile Accident Reparations Laws and Regulations',
  '11': 'Automobile Insurance Laws and Enforcement Procedures Guide',
  '550': 'Automobile Physical Damage Compliance Guide',
  '17': 'Cancellation and Nonrenewal Compliance Guide',
  '905': 'Catastrophe Claims Guide',
  '19': 'CEO Bulletin',
  '494': 'Claims and Unfair Claims Handling Laws and Regulations',
  '20': 'Claims Bulletin',
  '350': 'Claims Handling and Unfair Practices Compliance Guide',
  '22': 'Commercial Automobile Insurance Bulletin',
  '828': 'CompliAssist Bulletin',
  '827': 'Cyber Bulletin',
  '834': 'Data Security Laws Quick Reference Chart',
  '81': 'Enacted Law Bulletin',
  '837': 'Enacted Legislation Report',
  '433': 'Federal Bulletin',
  '791': 'Federal Update',
  '251': 'Financial Operating Results',
  '449': 'Financial Operating Results - Expanded',
  '45': 'General Liability Insurance Bulletin',
  '49': 'Greenbook',
  '56': 'Insurance Fraud Compliance Guide',
  '500': 'International Bulletin',
  '59': 'Investment Bulletin',
  '745': 'Large Commercial Risk Exemptions',
  '63': 'Legal and Government Affairs Bulletin',
  '686': 'Legislative Preview',
  '438': 'Legislative Tracking Report',
  '687': 'Legislative Wrap-Up',
  '416': 'Litigation Bulletin',
  '357': 'Loss Control Bulletin',
  '831': 'Market Conduct Bulletin',
  '836': 'Market Conduct Examination Laws and Regulations',
  '415': 'NAIC Bulletin',
  '475': 'NCOIL Bulletin',
  '423': 'New Law Alert',
  '847': 'On the Horizon',
  '10': 'Personal Automobile Insurance Bulletin',
  '849': 'Policy Bulletin',
  '86': 'Professional Liability Insurance Bulletin',
  '87': 'Property Insurance Bulletin',
  '489': 'Proposed Regulation Bulletin',
  '829': 'Public Affairs Task Force Alert',
  '848': 'Puerto Rico Bulletin',
  '392': 'Record Retention Requirements Laws and Regulations',
  '521': 'Regulatory Tracking Report',
  '493': 'Reinsurance Bulletin',
  '369': 'Research Bulletin',
  '846': 'Risk-Based Pricing Laws and Regulations',
  '15': 'Service Contract Reimbursement Bulletin',
  '418': 'State Capital Bulletin',
  '424': 'State Compliance Charts',
  '381': 'State Filing Guide',
  '97': 'State Insurance Department Directory',
  '838': 'State Regulatory Activity Report',
  '363': 'State Tax Guide',
  '122': 'Surplus Lines Insurance Bulletin',
  '94': 'Tax Bulletin',
  '483': 'Terrorism Insurance Watch',
  '125': 'Uninsured/Underinsured Motorist Case Law',
  '126': 'Uninsured/Underinsured Motorist Coverage Laws and Regulations',
  '129': 'Weekly Digest',
  '130': 'Workers Compensation Insurance Bulletin',
};

export function getPubTitleByCode(pubCode) {
  return PUBCODE_TITLE_MAP[pubCode] || '';
}

function replacePlus(str) {
  return str && str.replace(/\+/g, ' ');
}

export function formatToken(token) {
  // eslint-disable-next-line no-param-reassign
  if (!token) token = '';
  const formattedResponseParts = decodeURIComponent(token)
    .replace(
      /http:\/\/schemas.(xmlsoap|microsoft).(org|com)\/ws\/200(5|8)\/0(5|6)\/identity\/claims\//g,
      ''
    )
    .split('&');
  const formattedResponse = formattedResponseParts.reduce(
    (acc, current) => {
      const [key, val] = current.split('=');
      if (key === 'role') {
        acc.role.push(replacePlus(val));
      } else {
        acc[key] = val;
      }
      return acc;
    },
    { role: ['admin'] }
  );
  delete formattedResponse.HMACSHA256;
  delete formattedResponse.Issuer;
  const possiblePlusses = [
    'Department',
    'FullName',
    'StreetAddress1',
    'StreetAddress2',
    'Supervisor',
    'Title',
    'Company',
  ];
  possiblePlusses.forEach(key => {
    formattedResponse[key] = replacePlus(formattedResponse[key]);
  });
  return formattedResponse;
}

/**
 * parses Emotion styles and combines selectors into strings
 * @returns {Array<string>} CSS Text
 */
function extractCSSRulesFromEmotion() {
  if (!window || !document) {
    throw new Error('only a client side process');
  }
  const returnSheets = [];
  const stylesheets = document.querySelectorAll('[data-emotion="css"]');
  for (let i = 0; i < stylesheets.length; i += 1) {
    const ss = stylesheets[i];
    const combinedCSSText = Array.from(ss.sheet.cssRules)
      .reduce((acc, cur) => {
        const ct = cur.cssText
          .replace(/\n/g, '')
          .replace(/\s*{\s*/g, '{')
          .replace(/\s*}\s*/g, '}')
          .replace(/;\s*/g, ';')
          .replace(/:\s*/g, ':');
        return `${acc} ${ct}`;
      }, '')
      .trim();
    if (combinedCSSText) returnSheets.push(combinedCSSText);
  }
  return returnSheets;
}

/**
 * Fetches all Emotion styles (not global) and appends stylesheets to document.head with
 * their contents.
 */
export function fetchAndAppendVirtualStyles() {
  const styles = extractCSSRulesFromEmotion();
  for (let i = 0; i < styles.length; i += 1) {
    const cssText = styles[i];
    const styleTag = document.createElement('STYLE');
    styleTag.innerText = cssText;
    document.head.appendChild(styleTag);
  }
}

export const COOKIE_KEY = 'gdprccpaaccept';
export const SECURITY_COOKIE_KEY = '2fasecuritycode';

export function setCookie(key, value) {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000 * 365);
  document.cookie = `${key}=${value};expires=${expires.toUTCString()}`;
}

export function getCookie(key) {
  const keyValue = document.cookie.match(new RegExp(`(^|;) ?${key}=([^;]*)(;|$)`));
  return keyValue ? keyValue[2] : null;
}

/**
 * @param {object} config
 * @param {string} config.baseURL
 * @param {string} config.url
 * @param {object} config.params
 * @param {object} config.data
 */
export function getPathWithParams(config) {
  try {
    const { baseURL, url, params, data } = config;
    const path = url.replace(baseURL, '');
    if (config.method.toLowerCase() === 'post') {
      return formatURL(path, { ...params, ...(isObjectLike(data) ? data : { data }) }).replace(
        /^\//,
        ''
      );
    }
    return formatURL(path, params || {}).replace(/^\//, '');
  } catch (error) {
    console.log(error);
    return '';
  }
}

export function windowScrollTo({ top = 0, left = 0 }) {
  if (typeof window === 'undefined') return;
  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
  if (supportsNativeSmoothScroll) {
    window.scrollTo({ top, left, behavior: 'smooth' });
  } else {
    window.scrollTo(left, top);
  }
}

export function scrollToElement(id, offset = 0) {
  if (!id) return;
  const element = document.getElementById(id);
  if (element) {
    const { top } = element.getBoundingClientRect();
    window.scrollTo({
      top: offset > 0 ? top - offset : top,
      behavior: 'smooth',
    });
  }
}

export function getToken(token) {
  const sessionTokenKey = process.env.SESSION_TOKEN;
  const authEnv = process.env.AUTH_ENV;
  const apciTK = getCookie(sessionTokenKey);
  const tokenEnv = getCookie('APCIENV');
  const isEnvMatch = authEnv === tokenEnv;
  if (apciTK && apciTK !== 'none' && isEnvMatch) {
    return apciTK;
  }
  if (token && token !== 'none') {
    return token;
  }
  return null;
}

// Create a Google Tag Manager Event
export function trackGTMEvent(eventObj) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventObj);
}

// Remove commas from GTM event param
export function escapeGTMParam(param = '') {
  return param.replace(/,/g, '');
}
