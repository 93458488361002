import React from 'react';

const SvgEnvironmental = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M1.51 4.574A1 1 0 112.925 5.99c.105-.106.094-.079.078-.025-.05.177-.105.434-.16.754a30.364 30.364 0 00-.312 2.77c-.192 2.633-.199 5.707.027 8.75.599 8.072 2.668 14.683 6.381 18.396 8.076 8.076 21.928 10.268 28.323 5.106l-7.672-7.672-9.191 4.443a1 1 0 11-.87-1.8l8.564-4.14-5.401-5.4-10.224 4.847a1 1 0 11-.857-1.807l9.58-4.542-5.426-5.425-4.7 1.88a1 1 0 01-.743-1.857l3.905-1.562-7.43-7.43A1 1 0 018.21 9.86l42.798 42.798a1 1 0 11-1.414 1.414L38.683 43.161c-7.224 6.045-22.356 3.69-31.158-5.113-4.132-4.132-6.33-11.156-6.962-19.662C.107 12.23.6 5.486 1.51 4.574zM4.575 1.51C5.485.6 12.23.107 18.386.563c8.506.631 15.53 2.83 19.662 6.962 8.802 8.802 11.159 23.934 5.113 31.157l2.661 2.662a1 1 0 11-1.414 1.414l-12.24-12.24a1.323 1.323 0 01-.033-.033l-6.861-6.861a1.323 1.323 0 01-.033-.033l-6.862-6.862a1.247 1.247 0 01-.031-.031L9.86 8.21a1 1 0 111.415-1.414l7.43 7.43 1.562-3.904a1 1 0 011.857.743l-1.88 4.7 5.425 5.425 4.542-9.579a1 1 0 011.807.857L27.17 22.692l5.4 5.4 4.14-8.564a1 1 0 111.801.87l-4.443 9.192 7.672 7.672c5.162-6.396 2.97-20.247-5.106-28.323-3.713-3.713-10.324-5.783-18.396-6.381-3.043-.226-6.117-.219-8.75-.027a30.11 30.11 0 00-2.77.312c-.32.054-.577.11-.754.16-.054.015-.08.027.025-.078A1 1 0 114.575 1.51z"
    />
  </svg>
);

export default SvgEnvironmental;
