export const GET_NAMED_PARTY_FIELD_OPTIONS_BEGIN = 'GET_NAMED_PARTY_FIELD_OPTIONS_BEGIN';
export const GET_NAMED_PARTY_FIELD_OPTIONS_ERROR = 'GET_NAMED_PARTY_FIELD_OPTIONS_ERROR';
export const GET_NAMED_PARTY_FIELD_OPTIONS_SUCCESS = 'GET_NAMED_PARTY_FIELD_OPTIONS_SUCCESS';

export const LOAD_NAMED_PARTY_RESULTS_BEGIN = 'LOAD_NAMED_PARTY_RESULTS_BEGIN';
export const LOAD_NAMED_PARTY_RESULTS_ERROR = 'LOAD_NAMED_PARTY_RESULTS_ERROR';
export const LOAD_NAMED_PARTY_RESULTS_SUCCESS = 'LOAD_NAMED_PARTY_RESULTS_SUCCESS';

export const SET_NAMED_PARTY_SEARCH_CRITERIA = 'SET_NAMED_PARTY_SEARCH_CRITERIA';

export const SET_NAMED_PARTY_CURRENT_PAGE = 'SET_NAMED_PARTY_CURRENT_PAGE';

export const SET_NAMED_PARTY_SORT_ORDER = 'SET_NAMED_PARTY_SORT_ORDER';

export const SET_NAMED_PARTY_SORT_FIELD = 'SET_NAMED_PARTY_SORT_FIELD';

export const GET_NAMED_PARTY_LEGAL_CASE_BEGIN = 'GET_NAMED_PARTY_LEGAL_CASE_BEGIN';
export const GET_NAMED_PARTY_LEGAL_CASE_ERROR = 'GET_NAMED_PARTY_LEGAL_CASE_ERROR';
export const GET_NAMED_PARTY_LEGAL_CASE_SUCCESS = 'GET_NAMED_PARTY_LEGAL_CASE_SUCCESS';

export const CLEAR_NAMED_PARTY_SEARCH_CRITERIA = 'CLEAR_NAMED_PARTY_SEARCH_CRITERIA';
