/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import request from '@/utils/axios';

const initialState = {
  legalCaseData: {
    isFetchingLegalCase: true,
    error: false,
    LegalCase: {
      AvailableToWeb: {
        Code: 'Y',
        Description: 'Yes',
      },
      Archived: {
        Count: 1,
        Description: 'No',
        Value: 'N',
      },
      CaseUpdates: [],
      LegalCaseId: null,
      DateCreated: '',
      DateCreatedShort: '',
      CaseName: '',
      StatusCode: 1,
      Status: '',
      CaseTypeCode: 1,
      CaseType: '',
      StateCode: '',
      StateDescription: '',
      Issues: [],
      SourceOfRequest: '',
      Plaintiff: '',
      FilingDeadline: '',
      FilingDeadlineShort: '',
      PolicyType: '',
      BriefFiledDate: '',
      DecisionDate: '',
      Court: {
        CourtCode: '',
        CourtDescription: '',
      },
      Defendant: '',
      CaseSummary: '',
      CoParticipants: '',
      OutsideCounsel: {
        Name: '',
        FirmName: '',
        Address: '',
        Phone: '',
        EmailAddress1: '',
        EmailAddress2: '',
      },
    },
  },
  fieldOptionsData: {
    options: {
      statuses: [],
      caseNames: [],
      issues: [],
      states: [],
    },
    isFetchingOptions: true,
  },
  searchCriteria: {
    CaseNames: [],
    States: [],
    Issues: [],
    Statuses: [],
  },
  searchData: {
    results: [],
    count: 0,
    isFetchingResults: false,
    SortOrder: 'ASC',
    SortField: 'CASENAME',
  },
  currentPage: 1,
};

export const getFieldOptions = createAsyncThunk(
  'amicus/getFieldOptions',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'amicus/searchoptions',
        method: 'GET',
        triggerLoad: false,
      });

      if ('data' in response) {
        const {
          data: { AmicusFieldOptions },
        } = response;
        const fieldOptions = {
          statuses: AmicusFieldOptions.Statuses,
          caseNames: AmicusFieldOptions.CaseNames,
          issues: AmicusFieldOptions.Issues,
          states: AmicusFieldOptions.States,
        };
        return fieldOptions;
      }
    } catch (err) {
      console.warn(err);
      return rejectWithValue(err);
    }
  }
);

export const getLegalCase = createAsyncThunk(
  'amicus/getLegalCase',
  async (id, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'amicus/case',
        method: 'GET',
        params: {
          caseId: id,
        },
        triggerLoad: false,
      });
      if (response.data) {
        const { data } = response;
        if (data.Success === 'false') {
          const errorMessage = data.message;
          throw errorMessage;
        } else {
          return data;
        }
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loadResults = createAsyncThunk(
  'amicus/loadResults',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'amicus/casesearch',
        method: 'POST',
        data: payload.searchOptions,
        triggerLoad: false,
      });
      const { data } = response;

      if (data) {
        return data;
      }
      throw new Error('no search data');
    } catch (err) {
      // console.warn(err);
      return rejectWithValue(err);
    }
  }
);

const amicusSlice = createSlice({
  name: 'amicus',
  initialState,
  reducers: {
    updateSearchCriteria: (state, action) => {
      state.searchCriteria[action.payload.name] = action.payload.value;
    },
    resetResults: (state, action) => {
      state.searchData = {
        ...state.searchData,
        results: [],
        count: 0,
        isFetchingResults: false,
      };
    },
    resetSearchCriteria: (state, action) => {
      state.searchCriteria = {
        CaseNames: [],
        States: [],
        Issues: [],
        Statuses: [],
      };
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSortField: (state, action) => {
      state.searchData.SortField = action.payload;
    },
    setSortOrder: (state, action) => {
      state.searchData.SortOrder = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getFieldOptions.fulfilled, (state, action) => {
      state.fieldOptionsData.isFetchingOptions = false;
      state.fieldOptionsData.options = action.payload;
    });

    builder.addCase(getFieldOptions.rejected, (state, action) => {
      state.fieldOptionsData.isFetchingOptions = false;
    });

    builder.addCase(getFieldOptions.pending, (state, action) => {
      state.fieldOptionsData.isFetchingOptions = true;
    });

    builder.addCase(getLegalCase.pending, (state, action) => {
      state.legalCaseData.error = false;
      state.legalCaseData.isFetchingLegalCase = true;
    });

    builder.addCase(getLegalCase.rejected, (state, action) => {
      if (action.meta.arg) {
        state.legalCaseData.isFetchingLegalCase = false;
        state.legalCaseData.error = true;
      }
    });

    builder.addCase(getLegalCase.fulfilled, (state, action) => {
      state.legalCaseData.LegalCase = action.payload;
      state.legalCaseData.isFetchingLegalCase = false;
      state.legalCaseData.error = false;
    });

    builder.addCase(loadResults.pending, (state, action) => {
      state.searchData.isFetchingResults = true;
    });

    builder.addCase(loadResults.fulfilled, (state, action) => {
      if (action.payload.Count > 0) {
        state.searchData.count = action.payload.Count;
        state.searchData.results = action.payload.LegalCases;
        state.searchData.isFetchingResults = false;
      }
    });

    builder.addCase(loadResults.rejected, (state, action) => {
      state.searchData.isFetchingResults = false;
    });
  },
});

export const {
  updateSearchCriteria,
  resetResults,
  resetSearchCriteria,
  setCurrentPage,
  setSortField,
  setSortOrder,
} = amicusSlice.actions;

export default amicusSlice.reducer;
