/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  termsDue: null,
};

const forumSlice = createSlice({
  name: 'forum',
  initialState,
  reducers: {
    setForumTermsDue(state, action) {
      state.termsDue = action.payload;
    },
  },
});

export const { setForumTermsDue } = forumSlice.actions;

export default forumSlice.reducer;
