import { combineReducers } from 'redux';

import recentSearch from './recentSearchReducer';
import { savedSearchesData, saveSearchResponse } from './savedSearchesReducers';
import searchOptionsData from './searchOptionsReducer';
import { searchResults } from './searchResultsReducers';

export default combineReducers({
  searchOptionsData,
  savedSearchesData,
  saveSearchResponse,
  searchResults,
  recentSearch,
});
