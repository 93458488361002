import NextHead from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

const prodRobots = process.env.PROD_ROBOTS;

const Head = ({ title, description, url, ogImage, favicon = false, children }) => (
  <NextHead>
    <meta charSet="UTF-8" />
    <title>{title} | APCIA</title>
    <meta name="description" content={description} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="google-site-verification" content="7Se-tc_m7TmhsKiNQlloWktCIFyvvkMFjv5xAfQCE_I" />
    <meta name="robots" content={prodRobots ? 'index, follow' : 'noindex, nofollow'} />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    {/* <link rel="icon" sizes="192x192" href="/static/touch-icon.png" />
    <link rel="apple-touch-icon" href="/static/touch-icon.png" />
    <link rel="mask-icon" href="/static/favicon-mask.svg" color="#49B882" />
    <link rel="icon" href="/static/favicon.png" /> */}

    {favicon && (
      <>
        <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/static/favicon/android-chrome-192x192.png"
        />
        <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/static/favicon/manifest.json" />
        <link rel="mask-icon" href="/static/favicon/safari-pinned-tab.svg" color="#003da6" />
        <link rel="shortcut icon" href="/static/favicon/favicon.ico" />
      </>
    )}
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta name="msapplication-config" content="/assets/favicon/browserconfig.xml" />
    <meta name="theme-color" content="#ffffff" />

    <meta property="og:site_name" content="APCIA" key="og-sitename" />
    <meta property="og:type" content="website" key="og-type" />
    <meta property="og:url" content={url} key="og-url" />
    <meta property="og:title" content={title} key="og-title" />
    <meta property="og:image" content={ogImage || '/static/logo-share.jpg'} key="og-image" />
    <meta property="og:description" content={description} key="og-description" />

    <meta name="twitter:card" content="summary" key="twitter-card" />
    <meta name="twitter:url" content={url} key="twitter-url" />
    <meta name="twitter:title" content={title} key="twitter-title" />
    <meta name="twitter:description" content={description} key="twitter-description" />
    <meta name="twitter:image" content={ogImage} key="twitter-image" />
    {/* <script src="https://www.google.com/recaptcha/api.js" async defer /> */}
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    {children}
  </NextHead>
);

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  ogImage: PropTypes.string,
  children: PropTypes.node,
};

Head.defaultProps = {
  title: '',
  description: '',
  url: process.env.HOST_URL,
  ogImage: '',
  children: <></>,
};

export default Head;
