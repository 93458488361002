import {
  LOAD_SUBSCRIPTION_PREFERENCES_BEGIN,
  LOAD_SUBSCRIPTION_PREFERENCES_ERROR,
  LOAD_SUBSCRIPTION_PREFERENCES_SUCCESS,
} from './types';

const DEFAULT_STATE = {
  loadingSubscriptionPreferences: true,
  preferences: {
    DigestScopeId: null,
    DigestFormatId: 1,
    Jurisdictions: [],
    LinesOfBusiness: [],
    GeneralPublications: [],
    StatePublications: [],
    CompliAssistCharts: [],
  },
};

const AccountReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOAD_SUBSCRIPTION_PREFERENCES_BEGIN:
      return {
        ...state,
        ...action.payload,
      };
    case LOAD_SUBSCRIPTION_PREFERENCES_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case LOAD_SUBSCRIPTION_PREFERENCES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return DEFAULT_STATE;
  }
};

export default AccountReducer;
