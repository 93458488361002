export const sizes = {
  xxs: 375,
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

const breakpoints = {
  sizes,
  xxs: `(min-width: ${sizes.xxs}px)`,
  xs: `(min-width: ${sizes.xs}px)`,
  sm: `(min-width: ${sizes.sm}px)`,
  md: `(min-width: ${sizes.md}px)`,
  lg: `(min-width: ${sizes.lg}px)`,
  xl: `(min-width: ${sizes.xl}px)`,
  xxl: `(min-width: ${sizes.xxl}px)`,
};

export default breakpoints;
