import React from 'react';

const SvgIndustryTrends = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M53.204 5a2.801 2.801 0 010 5.6h-.937v23.334A4.676 4.676 0 0147.59 38.6H30.2l4.681 11.2h2.586a.933.933 0 110 1.867H34.26a.934.934 0 01-.862-.574L28.177 38.6h-.707l-5.222 12.493a.934.934 0 01-.862.574H18.18a.933.933 0 110-1.867h2.586l4.682-11.2H9.342a4.676 4.676 0 01-4.675-4.666V10.6h-1.87a2.801 2.801 0 010-5.6zM50.4 10.6H6.533v23.334c0 1.543 1.26 2.8 2.81 2.8H47.59a2.808 2.808 0 002.809-2.8V10.6zM34.828 22.354a.93.93 0 01.743.252l3.929 3.741 7.683-2.72a.933.933 0 01.623 1.76l-8.223 2.91a.93.93 0 01-.955-.202l-3.545-3.377-4.415 6.517a.935.935 0 01-1.322.232L26.504 29.4c-1.545.012-8.423.068-9.495.074-.636.25-3.356 1.914-5.609 3.373a.932.932 0 11-1.015-1.566c5.671-3.674 6.217-3.674 6.51-3.674l.204-.001c1.424-.008 8.981-.07 9.65-.075h.055c.197 0 .39.062.549.178l2.31 1.68 4.492-6.632a.934.934 0 01.673-.404zm-3.97-8.973a.938.938 0 01.7.236l6.971 6.26 7.893.22c.515.014.92.443.908.959a.925.925 0 01-.96.907l-8.236-.23a.93.93 0 01-.596-.238l-6.494-5.83-5.745 7.152a.928.928 0 01-.4.29l-8.377 3.14a.925.925 0 01-.657 0l-6.079-2.299a.934.934 0 01.66-1.746l5.75 2.174 7.809-2.927 6.202-7.722a.93.93 0 01.651-.346zm22.346-6.514H2.796a.933.933 0 00-.93.933c0 .523.409.933.93.933h50.408c.512 0 .93-.418.93-.933a.922.922 0 00-.93-.933z"
    />
  </svg>
);

export default SvgIndustryTrends;
