import { Col, Form, Input, Radio, Row, Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import DateRangePicker from '@/src/shared/components/DateRangePicker';
import Heading from '@/src/shared/components/Heading';
import { disableDayAfter, validateCustomRange } from '@/utils/dateTimeHelpers';
import { colMdProps, rowProps } from '@/utils/rowColHelpers';
import { multiSelectProps, singleSelectProps } from '@/utils/selectHelpers';

const FormItem = Form.Item;
const { Option } = Select;

const SavedSearchForm = ({ data, form, fieldOpts }) => {
  const { getFieldDecorator } = form;
  const { stateOpts, lobOpts, subjectOpts, pubOpts, dayOpts, typeOpts, commOpts } = fieldOpts;

  const renderMultiSelect = ({
    label,
    field,
    initialValue,
    options,
    optCode,
    optDescription,
    overrides = {},
  }) => {
    return (
      <Col {...colMdProps}>
        <FormItem label={label}>
          {getFieldDecorator(field, {
            initialValue,
          })(
            <Select {...multiSelectProps(false)} {...overrides}>
              {options.map(opt => (
                <Option value={opt[optCode]} key={`${opt[optDescription]}-${label}`}>
                  {opt[optDescription]}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>
      </Col>
    );
  };

  return (
    <div>
      <Row {...rowProps}>
        <Col {...colMdProps}>
          <Heading type={3} weight="bold" theme="primaryGray">
            Search Name
          </Heading>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colMdProps}>
          <FormItem label="Name of Search">
            {form.getFieldDecorator('SearchDescription', {
              initialValue: data.SearchDescription,
              rules: [
                {
                  required: true,
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col {...colMdProps}>
          <FormItem label="Show on homepage">
            {form.getFieldDecorator('IncludeOnHome', {
              initialValue: data.IncludeOnHome,
            })(
              <Radio.Group>
                <Radio value="Y">Yes</Radio>
                <Radio value="N">No</Radio>
              </Radio.Group>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colMdProps}>
          <Heading type={3} weight="bold" theme="primaryGray">
            Search Filters
          </Heading>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colMdProps}>
          <FormItem label="Search Text">
            {form.getFieldDecorator('SearchText', {
              initialValue: data.FieldOptions.SearchText,
            })(<Input />)}
          </FormItem>
        </Col>
        {renderMultiSelect({
          label: 'Jurisdictions',
          options: stateOpts,
          optCode: 'StateCode',
          optDescription: 'StateDescription',
          form,
          field: 'States',
          initialValue: data.FieldOptions.States.map(s => s.StateCode),
        })}
      </Row>
      <Row {...rowProps}>
        {renderMultiSelect({
          label: 'Lines of Business',
          options: lobOpts,
          optCode: 'LobCode',
          optDescription: 'LobDescription',
          form,
          field: 'LinesOfBusiness',
          initialValue: data.FieldOptions.LinesOfBusiness.map(lob => lob.LobCode),
        })}
        {renderMultiSelect({
          label: 'Current Subjects',
          options: subjectOpts,
          optCode: 'SubjectCode',
          optDescription: 'SubjectDescription',
          form,
          field: 'Subjects',
          initialValue: data.FieldOptions.Subjects.map(sub => sub.SubjectCode),
        })}
      </Row>
      <Row {...rowProps}>
        {renderMultiSelect({
          label: 'Publications',
          options: pubOpts.sort((a, b) =>
            a.PublicationDescription.localeCompare(b.PublicationDescription)
          ),
          optCode: 'PublicationCode',
          optDescription: 'PublicationDescription',
          form,
          field: 'Publications',
          initialValue: data.FieldOptions.Publications.map(pub => pub.PublicationCode),
        })}
        {renderMultiSelect({
          label: 'Type',
          options: typeOpts,
          optCode: 'Cat1Id',
          optDescription: 'Cat1Description',
          field: 'PostingTypes',
          initialValue: (data.FieldOptions.PostingTypes || []).map(pt => pt.Cat1Id),
        })}
      </Row>
      <Row {...rowProps}>
        {renderMultiSelect({
          label: 'Committees',
          options: commOpts.sort((a, b) =>
            a.CommitteeDescription.localeCompare(b.CommitteeDescription)
          ),
          optCode: 'CommitteeCode',
          optDescription: 'CommitteeDescription',
          field: 'Committees',
          initialValue: data.FieldOptions.Committees.map(com => com.CommitteeCode),
        })}
      </Row>
      <Row {...rowProps}>
        <Col {...colMdProps}>
          <FormItem label="Created">
            {form.getFieldDecorator('DayRanges', {
              initialValue: (data.FieldOptions.DayRanges || []).length
                ? data.FieldOptions.DayRanges[0].DayRangeCode
                : undefined,
            })(
              <Select {...singleSelectProps(false)}>
                {dayOpts.map(day => (
                  <Option value={day.DayRangeCode} key={`${day.DayRangeCode}-day-range`}>
                    {day.DayRangeDescription}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col {...colMdProps}>
          <FormItem label="Published Between">
            {form.getFieldDecorator('DateRanges', {
              initialValue:
                data.FieldOptions.DateStart && data.FieldOptions.DateEnd
                  ? [moment(data.FieldOptions.DateStart), moment(data.FieldOptions.DateEnd)]
                  : undefined,
              rules: [
                {
                  message: 'Please complete start date and end date',
                  validator: validateCustomRange,
                },
              ],
            })(<DateRangePicker disabledDate={disableDayAfter} showColumn />)}
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

SavedSearchForm.propTypes = {
  data: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  fieldOpts: PropTypes.object.isRequired,
};

export default SavedSearchForm;
