import React from 'react';

const SvgCorporation = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M17.772 0c.543 0 .982.44.982.982v3.93h18.667c.543 0 .983.44.983.983v37.398l8.995 1.42a.983.983 0 01.83.971v8.351h2.946V56H5v-1.965h1.965V5.895c0-.543.44-.983.982-.983h2.948V.982c0-.542.44-.982.982-.982zm20.632 45.283v8.752h7.86v-7.511l-7.86-1.241zM36.439 6.877H8.929v47.158h27.51V6.877zm-19.65 34.386v3.93h1.965v-3.93h1.965v3.93h1.965v-2.947a.982.982 0 111.965 0v3.93c0 .542-.44.982-.982.982h-11.79a.982.982 0 01-.982-.983v-3.93a.982.982 0 111.965 0v2.948h1.965v-3.93h1.964zm16.702-2.947c.543 0 .983.44.983.982v3.93c0 .543-.44.983-.983.983h-5.895a.982.982 0 01-.982-.983v-3.93c0-.542.44-.982.982-.982zm-.982 1.965h-3.93v1.965h3.93V40.28zm-15.72-8.842v3.93h1.965v-3.93h1.965v3.93h1.965V32.42a.982.982 0 111.965 0v3.93c0 .542-.44.982-.982.982h-11.79a.982.982 0 01-.982-.982v-3.93a.982.982 0 111.965 0v2.947h1.965v-3.93h1.964zm16.702-2.948c.543 0 .983.44.983.983v3.93c0 .542-.44.982-.983.982h-5.895a.982.982 0 01-.982-.982v-3.93c0-.543.44-.983.982-.983zm-.982 1.965h-3.93v1.965h3.93v-1.965zm-15.72-8.842v3.93h1.965v-3.93h1.965v3.93h1.965v-2.948a.982.982 0 111.965 0v3.93c0 .543-.44.983-.982.983h-11.79a.982.982 0 01-.982-.983v-3.93a.982.982 0 111.965 0v2.948h1.965v-3.93h1.964zm16.702-2.947c.543 0 .983.44.983.982v3.93c0 .542-.44.982-.983.982h-5.895a.982.982 0 01-.982-.982v-3.93c0-.542.44-.982.982-.982zm-.982 1.965h-3.93v1.964h3.93v-1.964zm-15.72-8.843v3.93h1.965v-3.93h1.965v3.93h1.965v-2.947a.982.982 0 111.965 0v3.93c0 .542-.44.982-.982.982h-11.79a.982.982 0 01-.982-.982v-3.93a.982.982 0 111.965 0v2.947h1.965v-3.93h1.964zm16.702-2.947c.543 0 .983.44.983.983v3.93c0 .542-.44.982-.983.982h-5.895a.982.982 0 01-.982-.983v-3.93c0-.542.44-.982.982-.982zm-.982 1.965h-3.93v1.965h3.93v-1.965zm-15.72-8.842h-3.93v2.947h3.93V1.965z"
    />
  </svg>
);

export default SvgCorporation;
