import { css, jsx } from '@emotion/core';
import Link from 'next/link';
import React, { memo } from 'react';

import { bp, colors } from '@/styles';

import { TopMenuItem } from './components';

const style = {
  navTop: css`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  `,
  menuToggle: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    color: ${colors.primaryBlue};
    line-height: 1 !important;
    appearance: none;
    border: none;
    background: transparent;
    padding: 16px;
    /* margin-top: -16px;
    margin-left: -16px; */
    font-size: 1.2rem;
    outline: none;
    cursor: pointer;
    width: 65px;
    @media ${bp.lg} {
      display: none;
    }
  `,
  menuToggleX: css`
    position: relative;
    height: 24px;
    width: 24px;
    margin-bottom: 4px;
    > span {
      width: 24px;
      height: 1.5px;
      background-color: ${colors.primaryBlue};
      display: block;
      transform-origin: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      &:nth-of-type(1) {
        transform: translateY(-8px);
      }
      &:nth-of-type(3) {
        transform: translateY(8px);
      }
    }
  `,
  menuToggleXOpen: css`
    span {
      &:nth-of-type(1) {
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        display: none;
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg);
      }
    }
  `,
  logo: css`
    padding: 14px 0 14px 16px;
    @media ${bp.lg} {
      padding-left: 0;
    }
    > img {
      height: 44px;
      @media ${bp.lg} {
        height: auto;
      }
    }
  `,
};

const toggleIconClassName = 'apcia-topmenu-toggle';

const NavHome = memo(props => {
  const { isLg, isOpen, handleToggleMenuClick, ..._props } = props;
  return (
    <TopMenuItem isSingle {..._props}>
      <div css={style.navTop}>
        <button
          className={`
            ${toggleIconClassName} 
            ${toggleIconClassName}--${isOpen ? 'open' : 'close'}
          `}
          css={style.menuToggle}
          type="button"
          onClick={handleToggleMenuClick}
        >
          <div css={[style.menuToggleX, ...(isOpen ? [style.menuToggleXOpen] : [])]}>
            <span />
            <span />
            <span />
          </div>
          <span>{isOpen ? 'Close' : 'Menu'}</span>
        </button>
        <Link href="/">
          <a css={style.logo}>
            <img src="/static/logo.svg" alt="APCIA logo" />
          </a>
        </Link>
      </div>
    </TopMenuItem>
  );
});

export default NavHome;
