// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

export function checkTokenValid(token) {
  try {
    const jwt = jwt_decode(token);
    const { iat, exp } = jwt;
    const currentDate = Math.floor(new Date().getTime() / 1000);

    if (!iat) {
      throw new Error('Invalid issue date');
    }
    if (!exp) {
      throw new Error('Invalid expiration date');
    }
    if (currentDate > exp) {
      throw new Error('Token has expired');
    }
    return true;
  } catch (err) {
    console.warn(err);
    return false;
  }
}
