import { css, jsx } from '@emotion/core';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { bp, colors, easing } from '@/styles';

const style = {
  menuItemTop: css`
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 0px;
    color: ${colors.primaryBlue};
    border-bottom-width: 6px !important;
    background-color: transparent;
    transition-property: background, padding !important;
    order: 0;
    @media ${bp.lg} {
      height: 80px;
    }
    &:hover {
      color: ${colors.primaryRed} !important;
      border-bottom-color: ${colors.primaryRed} !important;
    }
    > a,
    > .ant-menu-submenu-title {
      color: ${colors.primaryBlue} !important;
      line-height: 80px;
      height: 80px;
      display: flex !important;
      font-size: 1.6rem !important;
      transition: color 0.3s ${easing.easeInOut};
      padding-left: 0 !important;
      padding-right: 0 !important;
      &:hover {
        color: ${colors.primaryRed} !important;
      }
      @media ${bp.lg} {
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
      @media ${bp.xl} {
        padding-left: 24px !important;
        padding-right: 24px !important;
      }
      > span {
        transform: translateY(-3px);
      }
    }
    & > .ant-menu-sub {
      padding-left: 20px;
      border-left: 6px solid ${colors.primaryRed};
      @media ${bp.lg} {
        padding-left: 0;
        border-left: none;
      }
      a {
        /* color: ${colors.primaryBlue}; */
        font-size: 1.6rem;
        /* font-weight: 700; */
        line-height: 2.4rem;
        transition: color 0.3s ${easing.easeInOut};
        &:hover {
          color: ${colors.primaryRed} !important;
        }
      }
      .ant-menu-inline {
        border-right: 0;

        .ant-menu-submenu-open {
          & > .ant-menu-submenu-title {
            & > .ant-menu-submenu-arrow {
              &::before {
                transform: translate(3px, -2px) rotate(50deg) !important;
              }
              &::after {
                transform: translate(-4px, -2px) rotate(-50deg) !important;
              }
            }
          }
        }
      }
    }
    &.ant-menu-submenu-open {
      > .ant-menu-submenu-title {
        color: ${colors.primaryRed} !important;
        & > .ant-menu-submenu-arrow {
          &::before, &::after {
            background: ${colors.primaryRed} !important;
          }
        }
      }
    }
  `,
  menuItemTopActive: isSelected => css`
    ${isSelected
      ? css`
          .ant-menu-submenu-title {
            color: ${colors.primaryRed} !important;
            & > .ant-menu-submenu-arrow {
              &::before,
              &::after {
                background: ${colors.primaryRed} !important;
              }
            }
          }
        `
      : null};
  `,
  menuItemTopHome: css`
    border: none !important;
    padding: 0 !important;
    height: auto !important;
    margin: 0 -16px !important;
    width: calc(100% + 32px) !important;
    order: -1;
    @media ${bp.lg} {
      padding-left: 24px !important;
      padding-right: 24px !important;
      width: auto !important;
    }
  `,
};

const TopMenuItem = props => {
  const { isSingle, eventKey, isLg, children, isSelected, ...rest } = props;
  const _props = {
    css:
      eventKey === 'home'
        ? style.menuItemTopHome
        : [style.menuItemTop, style.menuItemTopActive(isSelected)],
    eventKey,
    ...rest,
  };

  const [openKeys, setOpenKeys] = useState([]);

  function onOpenChange(newOpenKeys) {
    const isSubSub = newOpenKeys.length === 2 && newOpenKeys[1].indexOf(newOpenKeys[0]) === 0;
    const latestOpenKey = newOpenKeys.find(k => openKeys.indexOf(k) === -1);
    if (isSubSub) {
      setOpenKeys(newOpenKeys.filter(k => k.indexOf(newOpenKeys[0]) === 0));
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }

  function handleClick(e) {
    if (e.target.tagName === 'A' && props.onClick) {
      props.onClick();
    }
  }

  function renderMultiple() {
    return isLg ? (
      <div onClick={handleClick}>
        <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
          {children}
        </Menu>
      </div>
    ) : (
      children
    );
  }

  return isSingle ? (
    <Menu.Item {..._props}>{children}</Menu.Item>
  ) : (
    <Menu.SubMenu {..._props} data-key={eventKey}>
      {renderMultiple()}
    </Menu.SubMenu>
  );
};

TopMenuItem.propTypes = {
  isSingle: PropTypes.bool,
  isLg: PropTypes.bool,
};

TopMenuItem.defaultProps = {
  isSingle: false,
  isLg: false,
};

export default TopMenuItem;
