import React from 'react';

const SvgCredit = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M51.168 10C53.832 10 56 12.162 56 14.818v27.054c0 2.656-2.168 4.818-4.832 4.818H4.832C2.168 46.69 0 44.528 0 41.872V14.818C0 12.162 2.168 10 4.832 10zm2.901 18.345H1.931v13.527a2.897 2.897 0 002.901 2.887h46.336c1.6 0 2.901-1.295 2.901-2.887V28.345zm-43.448 7.724V38H4.828v-1.931h5.793zm3.862-4.828v1.931H4.828v-1.93h9.655zm11.586 0v1.931h-9.655v-1.93h9.655zm11.586 0v1.931H28v-1.93h9.655zm11.586 0v1.931h-9.655v-1.93h9.655zm4.828-10.62H1.931v5.793h52.138V20.62zm-2.901-8.69H4.832a2.897 2.897 0 00-2.901 2.887v3.872h52.138v-3.872a2.897 2.897 0 00-2.901-2.887z"
    />
  </svg>
);

export default SvgCredit;
