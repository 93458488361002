import { css, jsx } from '@emotion/core';

import { form } from '@/styles';
/**
 * @param {boolean} loading -
 * @returns {object} props
 */
export function multiSelectProps(loading = false) {
  return {
    css: form.inputGray,
    mode: 'multiple',

    optionFilterProp: 'children',
    showArrow: true,
    allowClear: true,
    loading,
  };
}

/**
 * @param {boolean} loading -
 * @returns {object} props
 */
export function singleSelectProps(loading = false) {
  return {
    optionFilterProp: 'children',
    showArrow: true,
    css: form.inputGray,
    loading,
    allowClear: true,
  };
}
