import React from 'react';

const SvgPolitics = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M18.833 9.559a1.01 1.01 0 011.009 1.009l-.001 3.749 2.845 7.555 3.302 4.59a1.009 1.009 0 01-1.639 1.179l-3.379-4.7a1.009 1.009 0 01-.125-.233L18.093 15.4h-.654l2.192 7.828c.047.168.05.344.009.512l-.039.123-.978 2.536 2.691 6.074c.074.166.101.349.08.53l-.612 5.086 3.417-2.154a1.009 1.009 0 111.076 1.707l-3.844 2.422-.705 5.035 3.644-2.192a1.009 1.009 0 011.04 1.729l-4.134 2.487-1.768 6.858h14.557l-.785-22.316-2.878-4.909a4.833 4.833 0 01-5.681-6.116l-1.19-.386.623-1.92 1.568.51a4.829 4.829 0 012.616-1.532v-2.018h2.019v2.018a4.829 4.829 0 012.616 1.532l1.568-.51.624 1.92-1.19.386c.133.443.205.913.205 1.399 0 1.582-.76 2.987-1.936 3.869l2.791 4.761.597-.85a1.01 1.01 0 011.305-.31l.1.062 3.915 2.741c.46.322.569.958.241 1.415l-5.549 7.734.441 12.52 3.83.001V56H14v-2.018l3.424-.001 1.554-6.025a1 1 0 01-.554-.933l.01-.108.822-5.87a1.006 1.006 0 01-.731-1.094l.832-6.914-2.748-6.203a1.009 1.009 0 01-.019-.772l1.008-2.612-2.255-8.05h-.334A1.009 1.009 0 0114 14.393v-3.824a1.01 1.01 0 011.009-1.009h3.824zm17.873 22.244l-1.023 1.46a1.005 1.005 0 01-.317.293l.161 4.605 3.432-4.781-2.253-1.577zm-7.358-12.579a2.815 2.815 0 100 5.63 2.815 2.815 0 000-5.63zm-11.524-7.647h-1.806v1.806h1.806v-1.806zm2.018-4.886V8.71H14V6.691h5.842zM16.92 0c.493 0 1.32.138 2.463.383l.544.12a76.203 76.203 0 012.023.485 1.009 1.009 0 01.365 1.776 43.425 43.425 0 01-2.045 1.47c-.189.129-.373.25-.553.366-1.26.81-2.122 1.242-2.797 1.242a2.92 2.92 0 110-5.842zm0 2.018a.903.903 0 100 1.806c.114 0 .831-.36 1.706-.922a28.09 28.09 0 00.7-.466 51.54 51.54 0 00-.366-.08c-.992-.212-1.744-.338-2.04-.338z"
    />
  </svg>
);

export default SvgPolitics;
