import * as TYPES from './types';

export function pageLoadStart(path) {
  return {
    type: TYPES.PAGE_LOAD_START,
    payload: { path },
  };
}

export function pageLoadSuccess(path, cache) {
  return {
    type: TYPES.PAGE_LOAD_SUCCESS,
    payload: { path, cache },
  };
}

export function pageLoadError(path, type) {
  return {
    type: TYPES.PAGE_LOAD_ERROR,
    payload: { path, type },
  };
}

export function clearPageLoadError() {
  return {
    type: TYPES.PAGE_LOAD_ERROR_CLEAR,
  };
}
