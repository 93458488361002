import { css } from '@emotion/core';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { bp, colors, easing } from '@/styles';

const textStyle = css`
  color: ${colors.primaryBlue};
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  transition: color 0.3s ${easing.easeInOut};
  &:hover {
    color: ${colors.primaryRed} !important;
  }
`;

const style = {
  default: css`
    padding-left: 0 !important;
    padding-right: 10px !important;
    line-height: 1 !important;
    height: auto !important;
    margin: 4px 0 !important;
    display: inline-flex;
    a {
      ${textStyle};
    }
    &[data-selected] > a {
      color: ${colors.primaryRed} !important;
    }
  `,
  bold: css`
    > a {
      font-size: 1.6rem;
      line-height: 2.8;
      font-weight: 900;

      &:hover {
        color: ${colors.primaryRed};
      }

      @media ${bp.lg} {
        font-size: 1.4rem;
        line-height: 2;
        font-weight: 400;
      }
    }
    &[data-selected] > a {
      color: ${colors.primaryRed} !important;
    }
  `,
  subTitle: css`
    display: flex;
    align-items: center;
    ${textStyle};
    &[data-selected] > a {
      color: ${colors.primaryRed} !important;
    }
  `,
  subItem: css`
    margin: 0 !important;
    height: auto !important;
    line-height: 1 !important;
    padding: 0 24px !important;
    > span,
    > a {
      font-size: 1.2rem !important;
      line-height: 2.6rem !important;
      font-weight: 400;
      transition: color 0.3s ${easing.easeInOut};
      color: ${colors.secondaryGrayDark} !important;
      &:hover {
        color: ${colors.primaryRed} !important;
      }
    }
    .anticon {
      margin-right: 4px;
    }
    &:hover {
      color: ${colors.primaryRed} !important;
      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: ${colors.primaryRed} !important;
        }
      }
    }
    &[data-selected] > a {
      color: ${colors.primaryRed} !important;
    }
  `,
};

const renderType = {
  subTitle: (props, children, type, subsub) => {
    return (
      <span css={subsub ? style.subItem : style.subTitle} {...props}>
        {children}
      </span>
    );
  },
  default: (props, children, type = 'default') => {
    return (
      <Menu.Item {...props} css={style[type]}>
        {children}
      </Menu.Item>
    );
  },
  bold: (props, children, type) => {
    return (
      <Menu.Item {...props} css={style[type]}>
        {children}
      </Menu.Item>
    );
  },
  subItem: function subItem(...args) {
    return this.default(...args);
  },
};

// css: _css = css``
const MenuItem = ({ children, type, subsub, ..._props }) => {
  return renderType[type](_props, children, type, subsub);
};

MenuItem.propTypes = {
  type: PropTypes.oneOf(['default', 'subTitle', 'subItem', 'bold']),
  subsub: PropTypes.bool,
};

MenuItem.defaultProps = {
  type: 'default',
  subsub: false,
};

export default MenuItem;
