import auth0 from 'auth0-js';

export function passwordValidationPattern(output) {
  const passwordPattern = {
    // '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,25}$' <<<<<< (NOTE: This regular expression includes special characters)
    pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,25})'),
    message: output || 'Password does not meet all requirements',
  };
  return passwordPattern;
}

export const auth0DatabaseClient = new auth0.WebAuth({
  domain: process.env.AUTH0_TENANT_URL,
  clientID: process.env.AUTH0_CLIENT_ID,
});
