import React from 'react';

const SvgBulletin = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M39.667 0a1.166 1.166 0 110 2.333H3.503c-.645 0-1.17.527-1.17 1.174v40.336c0 5.417 4.403 9.824 9.814 9.824H35A1.166 1.166 0 1135 56H12.147C5.45 56 0 50.546 0 43.843V3.507A3.509 3.509 0 013.503 0zm0 5.816v40.85A5.84 5.84 0 0045.5 52.5a5.84 5.84 0 005.833-5.833V5.817h2.334v42.016h-.094c-.569 3.95-3.967 7-8.073 7s-7.504-3.05-8.073-7h-.094V5.816h2.334zm8.166.017C48.478 5.833 49 6.355 49 7v39.667a1.166 1.166 0 11-2.333 0V7c0-.645.522-1.167 1.166-1.167zm-4.666 0c.644 0 1.166.522 1.166 1.167v39.667a1.166 1.166 0 11-2.333 0V7c0-.645.522-1.167 1.167-1.167zm-8.167 21c.644 0 1.167.522 1.167 1.167v16.333c0 .645-.523 1.167-1.167 1.167H22.167A1.166 1.166 0 0121 44.333V28c0-.645.522-1.167 1.167-1.167zM19.833 42v2.333H7V42h12.833zm14-12.833h-10.5v14h10.5v-14zm-14 5.833v2.333H7V35h12.833zm0-7v2.333H7V28h12.833zm0-21C20.478 7 21 7.522 21 8.167V24.5c0 .645-.522 1.167-1.167 1.167H7A1.166 1.166 0 015.833 24.5V8.167C5.833 7.522 6.356 7 7 7zM35 22.167V24.5H22.167v-2.333H35zM18.667 9.333h-10.5v14h10.5v-14zM35 15.167V17.5H22.167v-2.333H35zm0-7V10.5H22.167V8.167H35z"
    />
  </svg>
);

export default SvgBulletin;
