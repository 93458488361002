import React from 'react';

const SvgComplaints = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M28.415 0C42.981 0 54.83 11.828 54.83 26.367c0 14.54-11.85 26.367-26.415 26.367-3.654 0-7.183-.728-10.5-2.165l-9.433 5.296a1.054 1.054 0 01-1.11-.048 1.055 1.055 0 01-.455-1.014l1.498-11.21A26.29 26.29 0 012 26.366C2 11.827 13.85 0 28.415 0zm0 2.113c-13.4 0-24.302 10.88-24.302 24.254a24.18 24.18 0 006.2 16.182c.206.23.301.539.26.846l-1.281 9.591 8.05-4.52c.296-.166.651-.179.958-.039a24.138 24.138 0 0010.115 2.194c13.4 0 24.302-10.88 24.302-24.254S41.815 2.113 28.415 2.113zm0 33.812a3.173 3.173 0 013.17 3.17 3.173 3.173 0 01-3.17 3.17 3.173 3.173 0 01-3.17-3.17 3.173 3.173 0 013.17-3.17zm0 2.113a1.058 1.058 0 000 2.113 1.058 1.058 0 000-2.113zm0-28.529a4.232 4.232 0 014.227 4.228V30.64a4.232 4.232 0 01-4.227 4.228 4.232 4.232 0 01-4.226-4.228V13.737a4.232 4.232 0 014.226-4.228zm0 2.114a2.116 2.116 0 00-2.113 2.114V30.64c0 1.166.948 2.115 2.113 2.115a2.116 2.116 0 002.113-2.115V13.737a2.116 2.116 0 00-2.113-2.114z"
    />
  </svg>
);

export default SvgComplaints;
