import React from 'react';

const SvgDriverLicensing = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M53 10a3 3 0 013 3v30a3 3 0 01-3 3H3a3 3 0 01-3-3V13a3 3 0 013-3h50zm0 2H3a1 1 0 00-1 1v30a1 1 0 001 1h50a1 1 0 001-1V13a1 1 0 00-1-1zm-3 27v2H4v-2h46zm0-4v2H4v-2h46zM21 14a1 1 0 011 1v18a1 1 0 01-.883.993L21 34H5a1 1 0 01-1-1V15a1 1 0 011-1h16zm29 17v2H25v-2h25zm-37-3c-3.422 0-6.206 1.763-6.856 4h13.712c-.65-2.237-3.434-4-6.856-4zm37-1v2H25v-2h25zM20 16H6v12.585c.924-.885 2.12-1.586 3.482-2.033a5 5 0 117.035.001c1.364.447 2.56 1.148 3.484 2.033L20 16zm-7 4a3 3 0 100 6 3 3 0 000-6zm23-1v2H25v-2h11zm9.028-4v2H25v-2h20.028z"
    />
  </svg>
);

export default SvgDriverLicensing;
