import { notification } from 'antd';

import request from '@/utils/axios';
import { DATE_RANGES_OPTION } from '@/utils/dateTimeHelpers';

import { defaultSort, getSearchItemsByCount } from '../../utils';
import {
  DELETE_SAVED_SEARCH_BEGIN,
  DELETE_SAVED_SEARCH_ERROR,
  DELETE_SAVED_SEARCH_SUCCESS,
  LOAD_SAVED_SEARCHES_BEGIN,
  LOAD_SAVED_SEARCHES_ERROR,
  LOAD_SAVED_SEARCHES_SUCCESS,
  RESET_SELECTED_SEARCH,
  SAVE_SEARCH_BEGIN,
  SAVE_SEARCH_ERROR,
  SAVE_SEARCH_SUCCESS,
  SET_MODAL_VISIBLE,
  SET_SAVED_SEARCH_INCLUDEONHOMEPAGE_BY_ID,
  SET_SELECTED_SAVED_SEARCH_BY_ID,
} from '../types/savedSearchesTypes';
import {
  LOAD_SEARCH_RESULTS_BEGIN,
  LOAD_SEARCH_RESULTS_ERROR,
  LOAD_SEARCH_RESULTS_SUCCESS,
} from '../types/searchResultsTypes';
import { setDefaultSortSearch, setSortField, setSortOrder } from './searchOptionsActions';

// #region load saved searches
function loadSavedSearchesBegin() {
  return {
    type: LOAD_SAVED_SEARCHES_BEGIN,
    payload: {
      isFetchingSavedSearches: true,
    },
  };
}
function loadSavedSearchesError() {
  return {
    type: LOAD_SAVED_SEARCHES_ERROR,
    payload: {
      isFetchingSavedSearches: false,
    },
  };
}
function loadSavedSearchesSuccess(savedSearches) {
  return {
    type: LOAD_SAVED_SEARCHES_SUCCESS,
    payload: {
      isFetchingSavedSearches: false,
      savedSearches,
    },
  };
}
// #endregion load saved searches

// #region save search
function saveSearchBegin() {
  return {
    type: SAVE_SEARCH_BEGIN,
    payload: {
      isSavingSearch: true,
      isSaveSuccessful: false,
    },
  };
}
function saveSearchSuccess(SearchId) {
  return {
    type: SAVE_SEARCH_SUCCESS,
    payload: {
      isSavingSearch: false,
      isSaveSuccessful: true,
      SearchId,
    },
  };
}
function saveSearchError() {
  return {
    type: SAVE_SEARCH_ERROR,
    payload: {
      isSavingSearch: false,
      isSaveSuccessful: false,
    },
  };
}
// #endregion delete saved search

// #region delete saved search
function deleteSavedSearchBegin() {
  return {
    type: DELETE_SAVED_SEARCH_BEGIN,
    payload: {
      isDeletingSearch: true,
      isDeleteSuccessful: false,
    },
  };
}
function deleteSavedSearchError() {
  return {
    type: DELETE_SAVED_SEARCH_ERROR,
    payload: {
      isDeletingSearch: true,
      isDeleteSuccessful: false,
    },
  };
}
function deleteSavedSearchSuccess() {
  return {
    type: DELETE_SAVED_SEARCH_SUCCESS,
    payload: {
      isDeletingSearch: false,
      isDeleteSuccessful: true,
    },
  };
}
// #endregion delete saved search

function selectSavedSearchId(selectedSearchId) {
  return {
    type: SET_SELECTED_SAVED_SEARCH_BY_ID,
    payload: {
      selectedSearchId,
    },
  };
}

function loadSearchResultsBegin(searchId) {
  return {
    type: LOAD_SEARCH_RESULTS_BEGIN,
    payload: {
      isLoadingResults: true,
      lastLoadSearchId: searchId,
    },
  };
}
function loadSearchResultsError() {
  return {
    type: LOAD_SEARCH_RESULTS_ERROR,
    payload: {
      isLoadingResults: false,
    },
  };
}
function loadSearchResultsSuccess(Results, Count, searchItems) {
  return {
    type: LOAD_SEARCH_RESULTS_SUCCESS,
    payload: {
      isLoadingResults: false,
      Results,
      Count,
      searchItems,
    },
  };
}

function setIncludedOnHomepage(id, value) {
  return {
    type: SET_SAVED_SEARCH_INCLUDEONHOMEPAGE_BY_ID,
    payload: {
      id,
      value,
    },
  };
}

function resetSelectedSearch() {
  return {
    type: RESET_SELECTED_SEARCH,
  };
}

export function setIncludedOnHomepageByIdAndValue(id, value) {
  return async dispatch => {
    dispatch(setIncludedOnHomepage(id, value));
  };
}

export function setModalVisibility(isModalVisible) {
  return {
    type: SET_MODAL_VISIBLE,
    payload: {
      isModalVisible,
    },
  };
}

export const setSelectedSearchAndOpenModal = searchId => {
  return async dispatch => {
    try {
      dispatch(selectSavedSearchId(searchId));
      dispatch(setModalVisibility(true));
    } catch (err) {
      console.warn(err);
    }
  };
};

export const setSelectedSearch = searchId => {
  return async dispatch => {
    try {
      dispatch(selectSavedSearchId(searchId));
    } catch (err) {
      console.warn(err);
    }
  };
};

export const fetchSavedSearches = ({ personId }) => {
  return async dispatch => {
    try {
      dispatch(loadSavedSearchesBegin());
      const response = await request({
        url: '/cms/savedsearches',
        method: 'GET',
        params: {
          personId,
        },
        triggerLoad: false,
      });
      const { data } = response;
      if (data.Searches.length === 1 && data.Searches[0].SearchId === null) {
        dispatch(loadSavedSearchesSuccess([]));
      } else {
        const savedSearches = data.Searches.reduce((acc, savedSearchData) => {
          acc[savedSearchData.SearchId] = savedSearchData;
          return acc;
        }, {});
        dispatch(loadSavedSearchesSuccess(savedSearches));
      }
    } catch (err) {
      console.warn(err);
      dispatch(loadSavedSearchesError());
    }
  };
};

export const setSelectedSearchAndFetchResults = (searchId, personId, clickTitle) => {
  // debugger;
  return async (dispatch, getState) => {
    try {
      const {
        savedSearchesData: { savedSearches },
        searchResults: { isLoadingResults, lastLoadSearchId },
        searchOptionsData: { searchOptions, defaultSortSearch },
      } = getState().search;

      // stop duplicate search
      if (isLoadingResults && Number(lastLoadSearchId) === Number(searchId)) {
        return;
      }

      await dispatch(loadSearchResultsBegin(searchId));

      let fetchedSearch = savedSearches[searchId];

      if (!fetchedSearch) {
        const getSavedSearchesResponse = await request({
          url: '/cms/savedsearches',
          method: 'GET',
          params: {
            personId,
          },
          triggerLoad: false,
        });
        const { data } = getSavedSearchesResponse;
        fetchedSearch = data.Searches.find(s => s.SearchId === Number(searchId));
      }

      // Here if fetchedSearch is falsey the ID doesn't exist

      const { DateStart, DateEnd } = fetchedSearch.FieldOptions;
      const [SortField, SortOrder] =
        clickTitle || defaultSortSearch
          ? defaultSort(fetchedSearch.FieldOptions)
          : [searchOptions.SortField, searchOptions.SortOrder];
      const DateRanges = DateStart && DateEnd ? DATE_RANGES_OPTION : [];

      const searchItems = {
        ...fetchedSearch,
        FieldOptions: {
          ...fetchedSearch.FieldOptions,
          DateRanges,
          Page: 1,
          PageSize: 10,
          SortField,
          SortOrder,
        },
      };

      const savedSearch = JSON.stringify({ ...searchItems });

      const searchResponse = await request({
        method: 'POST',
        url: '/cms/search',
        data: savedSearch,
        triggerLoad: false,
      });
      const { Results, Count } = searchResponse.data;

      // avoid Race condition if change searchId before return results
      const { searchResults } = getState().search;
      if (Number(searchResults.lastLoadSearchId) !== Number(searchId)) {
        return;
      }

      await dispatch(setSortField(SortField));
      await dispatch(setSortOrder(SortOrder));
      if (clickTitle) {
        await dispatch(setDefaultSortSearch());
      }

      if (!Count) {
        dispatch(loadSearchResultsSuccess([], 0));
      } else if (Count && Count > 0) {
        const activeSearchItems = getSearchItemsByCount(searchItems, Count);
        dispatch(loadSearchResultsSuccess(Results, Count, activeSearchItems));
      } else {
        dispatch(loadSearchResultsError([], 0));
      }
      dispatch(selectSavedSearchId(searchId));
    } catch (err) {
      console.warn(err);
      dispatch(loadSearchResultsError([], 0));
    }
  };
};

export const saveSearch = payload => {
  return async (dispatch, getState) => {
    const { body, noReloadBody, onError, successCallback } = payload;
    try {
      const payloadCopy = { ...body };
      const currentSearchState = getState().search;
      const {
        savedSearchesData: { savedSearches },
      } = currentSearchState;
      const _savedSearches = { ...savedSearches };
      if (body.SearchId && !noReloadBody) {
        _savedSearches[body.SearchId] = body;
      }
      dispatch(saveSearchBegin());
      await request({
        method: 'POST',
        url: '/cms/savesearch',
        data: payloadCopy,
        triggerLoad: false,
      }).then(response => {
        const { data } = response;

        if (data.Success === 'true') {
          dispatch(saveSearchSuccess(data.SearchId));
          const isEdit = Boolean(_savedSearches[data.SearchId]);
          if (!isEdit) {
            _savedSearches[data.SearchId] = { ...body, SearchId: data.SearchId };
          }
          dispatch(loadSavedSearchesSuccess(_savedSearches));
          if (isEdit) {
            dispatch(selectSavedSearchId(data.SearchId));
          }
          const description =
            noReloadBody && body.IncludeOnHome === 'Y'
              ? 'Search successfully added to the homepage.'
              : 'Search successfully saved.';
          notification.success({
            message: 'Saved Search',
            description,
          });
          if (successCallback) successCallback(data.SearchId);
        } else {
          dispatch(saveSearchError());
          dispatch(loadSavedSearchesSuccess(savedSearches));
          notification.error({
            message: 'Saved Search',
            description: 'Search unsuccessfully saved.',
          });
          if (onError) onError();
        }
      });
    } catch (err) {
      console.warn(err);
      dispatch(saveSearchError());
      notification.error({
        message: 'Saved Search',
        description: 'Search unsuccessfully saved.',
      });
      if (onError) onError();
    }
  };
};

export const deleteSearch = payload => {
  return async (dispatch, getState) => {
    const { searchId, successCallback } = payload;
    const currentSearchState = getState().search;
    const {
      savedSearchesData: { savedSearches },
    } = currentSearchState;
    try {
      const currentSavedSearches = { ...savedSearches };
      delete currentSavedSearches[searchId];
      dispatch(loadSavedSearchesSuccess(currentSavedSearches));
      dispatch(deleteSavedSearchBegin());
      await request({
        url: '/cms/deletesearch',
        method: 'DELETE',
        params: {
          searchId,
        },
        triggerLoad: false,
      })
        .then(response => {
          const { data } = response;
          if (data.Success === 'true') {
            deleteSavedSearchSuccess();
            if (successCallback) successCallback();
            notification.success({
              message: 'Saved Search',
              description: 'Search successfully deleted.',
            });
            dispatch(resetSelectedSearch());
          } else {
            dispatch(loadSavedSearchesSuccess(savedSearches));
            notification.error({
              message: 'Saved Search',
              description: 'Search unsuccessfully deleted.',
            });
          }
        })
        .catch(err => {
          dispatch(loadSavedSearchesSuccess(savedSearches));
          dispatch(deleteSavedSearchError());
          notification.error({
            message: 'Saved Search',
            description: 'Search unsuccessfully deleted.',
          });
        });
    } catch (err) {
      console.warn(err);
      dispatch(loadSavedSearchesSuccess(savedSearches));
      dispatch(deleteSavedSearchError());
      notification.error({
        message: 'Saved Search',
        description: 'Search unsuccessfully deleted.',
      });
    }
  };
};

export function resetCurrentSelectedSearch() {
  return async dispatch => {
    dispatch(resetSelectedSearch());
  };
}
