import { Auth0Client } from '@auth0/auth0-spa-js';
import axios from 'axios';

import { checkTokenValid } from '@/src/auth/utils/middleware';
import { pageLoadError, pageLoadStart, pageLoadSuccess } from '@/src/pageLoad/redux/actions';
import { LOAD_ERROR_TYPES } from '@/src/pageLoad/redux/reducers';
import { getPathWithParams } from '@/utils/utils';

const abortController = typeof AbortController === 'function' ? new AbortController() : null;

const auth0 = new Auth0Client({
  domain: process.env.AUTH0_TENANT_URL,
  clientId: process.env.AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    redirect_uri: typeof window !== 'undefined' ? window.location.origin : process.env.API_ROOT,
  },
});

const instance = axios.create({
  baseURL: process.env.NEW_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  ...(abortController ? { signal: abortController.signal } : null),
});

instance.interceptors.request.use(async config => {
  const _config = config;

  if (!process.server && typeof window !== 'undefined') {
    const { triggerLoad = true, cache = false, serveFromCache = true } = _config;
    const url = getPathWithParams(config);
    const state = window.__NEXT_REDUX_STORE__.getState();
    const { pageLoad } = state;

    try {
      const auth0Token = await auth0.getTokenSilently({
        authorizationParams: {
          audience: process.env.NEW_API_ROOT,
          scope: 'offline_access',
        },
      });
      if (checkTokenValid(auth0Token)) {
        _config.headers.Authorization = `Bearer ${auth0Token}`;
      } else {
        throw new Error('Token not valid');
      }
    } catch (error) {
      console.log(error);
    }

    if (cache && url in pageLoad.pageCaches && serveFromCache) {
      const pageCache = pageLoad.pageCaches[url];
      if (pageCache.expiry >= new Date().getTime()) {
        // serve from cache
        console.log('Serving request from cache: ', url);
        _config.data = JSON.parse(pageCache.data);
        _config.cached = true;
        _config.cacheKey = url;
        // return {
        //   ..._config,
        //   cancelToken: new CancelToken((cancel) => {
        //     cancel(`Serving request from cache: ${url}`)
        //   })
        // }
        return Promise.reject(_config);
      }
    }

    if (!triggerLoad) return _config;
    window.__NEXT_REDUX_STORE__.dispatch(pageLoadStart(url));
  }
  return _config;
});

instance.interceptors.response.use(
  response => {
    if (!process.server && typeof window !== 'undefined') {
      const { triggerLoad = true, ...config } = response.config;
      const { transformData = r => r, cache: shouldCache, cacheTime = 2 /* in minutes */ } = config;
      const transformedResponse = transformData(response);
      if (!triggerLoad) return transformedResponse;
      const url = getPathWithParams(config);

      let cache = false;
      if (shouldCache) {
        const today = new Date();
        today.setMinutes(today.getMinutes() + cacheTime);
        cache = {
          data: JSON.stringify(transformedResponse.data),
          expiry: today.getTime(),
        };
      }

      window.__NEXT_REDUX_STORE__.dispatch(pageLoadSuccess(url, cache));
    }
    return response;
  },
  error => {
    if (error.cached || !error.response || typeof window === 'undefined') {
      return Promise.resolve(error);
    }

    const { response } = error;

    const {
      auth: { isLoggedIn, isLoading },
    } = window.__NEXT_REDUX_STORE__.getState();

    if (response?.headers?.autherror) {
      if (error?.response?.config?.allow401 || window.location.pathname === '/') {
        window.__NEXT_REDUX_STORE__.dispatch({ type: 'LOGOUT' });
        window.__NEXT_REDUX_STORE__.dispatch({ type: 'FINISH_LOADING' });
        return Promise.reject(error);
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'access_locked_content' });

      switch (response?.headers?.autherror) {
        case 'Token Expired':
          window.__NEXT_REDUX_STORE__.dispatch(
            pageLoadError(response.config.url, LOAD_ERROR_TYPES.AUTH_ERROR_EXPIRED)
          );
          auth0.loginWithRedirect({
            authorizationParams: {
              redirect_uri: window.location.origin,
            },
          });
          break;
        case 'User does not have permission.':
        case 'Claims Error':
          if (!isLoggedIn) {
            auth0.loginWithRedirect({
              authorizationParams: {
                redirect_uri: window.location.origin,
              },
            });
          } else {
            window.__NEXT_REDUX_STORE__.dispatch(
              pageLoadError(response.config.url, LOAD_ERROR_TYPES.AUTH_ERROR_NOT_ALLOW)
            );
          }
          break;
        default:
          if (!isLoggedIn) {
            if (abortController) {
              abortController.abort();
            }
            auth0.loginWithRedirect({
              appState: {
                returnTo: window.location.pathname,
              },
            });
          }
          break;
      }
    }

    if (!isLoading && !isLoggedIn) {
      auth0.loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }

    return Promise.reject(error);
  }
);

module.exports = instance;
