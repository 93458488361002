import React from 'react';

const SvgMedical = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M7.98.313a1.066 1.066 0 011.507 0l4.267 4.266a1.066 1.066 0 11-1.508 1.508l-2.113-2.112-3 2.403v7.488c0 .034-.016.062-.019.094.008.043.029.08.03.125A10.647 10.647 0 0017.8 24.267a10.647 10.647 0 0010.655-10.182c.002-.045.023-.082.03-.125-.002-.033-.018-.06-.018-.094V6.378l-3-2.403-2.113 2.112a1.063 1.063 0 01-1.508 0 1.066 1.066 0 010-1.508L26.113.313A1.066 1.066 0 1127.62 1.82l-.637.636L30.2 5.034c.253.202.4.508.4.832v8c0 .053-.023.099-.03.15.003.024.015.044.016.068.01.196.014.393.014.591v4.25c0 6.703-5.173 12.21-11.733 12.755v10.45c0 6.472 5.263 11.737 11.733 11.737 6.47 0 11.733-5.266 11.733-11.738V28.193c-4.202-.525-7.466-4.094-7.466-8.414 0-4.68 3.828-8.488 8.533-8.488s8.533 3.807 8.533 8.488c0 4.32-3.264 7.89-7.466 8.414v13.936C44.467 49.778 38.246 56 30.6 56c-7.646 0-13.867-6.222-13.867-13.87V31.68C10.173 31.134 5 25.628 5 18.926v-4.251c0-.198.004-.395.014-.59 0-.025.013-.045.016-.069-.007-.05-.03-.097-.03-.15v-8c0-.324.147-.63.4-.832l3.216-2.577-.637-.636a1.066 1.066 0 010-1.508zM28.267 20.98a12.774 12.774 0 01-10.468 5.42c-4.322 0-8.149-2.13-10.468-5.42.96 4.907 5.289 8.62 10.468 8.62 5.18 0 9.508-3.714 10.468-8.62zM43.4 13.424c-3.53 0-6.4 2.85-6.4 6.355 0 3.504 2.87 6.354 6.4 6.354 3.53 0 6.4-2.85 6.4-6.354 0-3.505-2.87-6.355-6.4-6.355zm0 2.108c2.353 0 4.267 1.905 4.267 4.247 0 2.342-1.914 4.248-4.267 4.248-2.353 0-4.267-1.906-4.267-4.248 0-2.342 1.914-4.247 4.267-4.247zm0 2.133a2.126 2.126 0 00-2.133 2.114c0 1.166.957 2.114 2.133 2.114a2.126 2.126 0 002.133-2.114 2.126 2.126 0 00-2.133-2.114z"
    />
  </svg>
);

export default SvgMedical;
