import React from 'react';

const SvgWebinar = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M52.882 4A3.113 3.113 0 0156 7.102v38.38H34.758l2.491 5.814a1.036 1.036 0 01-.953 1.445H19.704a1.038 1.038 0 01-.953-1.445l2.491-5.815H0V7.102A3.113 3.113 0 013.118 4zm-20.38 41.481h-9.004l-2.222 5.186h13.448l-2.222-5.186zm21.424-8.296H2.074v6.222h51.852v-6.222zM28 38.222c1.144 0 2.074.931 2.074 2.074 0 1.144-.93 2.074-2.074 2.074a2.076 2.076 0 01-2.074-2.074c0-1.143.93-2.074 2.074-2.074zM52.882 6.074H3.118c-.575 0-1.044.46-1.044 1.028v28.01h51.852V7.101c0-.567-.469-1.028-1.044-1.028zM14.369 9.943a1.036 1.036 0 111.466 1.466L7.474 19.77a1.033 1.033 0 01-1.466 0 1.036 1.036 0 010-1.466z"
    />
  </svg>
);

export default SvgWebinar;
