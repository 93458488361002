import { css, jsx } from '@emotion/core';
import { Icon as AntIcon } from 'antd';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { bp, colors } from '@/styles';

import DefaultPublicationIcon from './_icons/DefaultPublication';
import ICON_ID_MAP from './map';

const style = {
  default: css``,
  large: css`
    display: none;
    color: ${colors.secondaryBlue};
    @media ${bp.lg} {
      display: block;
      width: 56px;
      height: 56px;
    }
  `,
  medium: css`
    display: none;
    color: ${colors.secondaryBlue};
    @media ${bp.lg} {
      display: block;
      width: 40px;
      height: 40px;
    }
  `,
};

function checkIconName(name) {
  if (name && typeof name === 'string') {
    const first = name.charAt(0);
    if (name.startsWith('social') || first === first.toUpperCase()) {
      return name;
    }
    return first.toUpperCase() + name.slice(1);
  }
  return false;
}

function getIcon(name) {
  const iconName = checkIconName(name);
  if (!iconName) return null;
  return dynamic(() => import(`@/src/shared/components/Icon/_icons/${iconName}`));
}

const IDS = [...Array(Object.keys(ICON_ID_MAP).length)].map((_, i) => i + 1);

const Icon = ({ name, size, ant, id, ..._props }) => {
  const DEFAULT_ICON = (
    <AntIcon {...{ component: DefaultPublicationIcon }} {..._props} css={style[size]} />
  );

  if (!id && !name) {
    return DEFAULT_ICON;
  }
  if (id && !IDS.includes(Number(id))) {
    return DEFAULT_ICON;
  }
  const icon = getIcon(id ? ICON_ID_MAP[id] : name);
  const TagName = ant ? AntIcon : icon;

  return <TagName {...ant && { component: icon }} {..._props} css={style[size]} />;
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large', 'default']),
  css: PropTypes.object,
  ant: PropTypes.bool,
  id: PropTypes.oneOf([...IDS, ...IDS.map(id => id.toString())]),
};

Icon.defaultProps = {
  name: '',
  size: 'default',
  css: css``,
  ant: true,
  id: null,
};

export default memo(Icon);
