import { css, jsx } from '@emotion/core';
import { Form as AntForm } from 'antd';
import React, { useEffect } from 'react';

import { colors } from '@/styles';

const label = css`
  font-weight: bold;
  font-size: 1.6rem !important;
  margin-bottom: 8px;
  line-height: 1.5;
  color: ${colors.secondaryGrayDark};
`;
const asterisk = css`
  line-height: 1;
  font-size: 15px;
  color: ${colors.primaryBlue};
  font-family: SimSun, sans-serif;
`;
const grayInput = css`
  /* width: 100%; */
  background-color: ${colors.accentGrayUltralight};
  border: none;
  border-radius: 4px;
`;
const themeStyle = {
  default: css`
    .ant-input {
      ${grayInput};
      &:focus,
      &:active {
        border: 1px solid ${colors.secondaryBlue};
        box-shadow: none;
      }
    }
  `,
  light: css`
    input {
      font-size: 1.6rem;
      border: 0 !important;
      padding-left: 44px !important;
      border: 1px solid ${colors.accentGrayLight};
      background-color: transparent;
      &::placeholder {
        font-size: 1.6rem;
      }
      &::-webkit-input-placeholder {
        font-size: 1.6rem;
      }
      &::-moz-placeholder {
        font-size: 1.6rem;
      }
      &:-ms-input-placeholder {
        font-size: 1.6rem;
      }
      &:-moz-placeholder {
        font-size: 1.6rem;
      }
      outline: none !important;
      box-shadow: none !important;
    }
  `,
};
const form = css`
  .ant-radio-wrapper {
    font-size: 12px !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.secondaryBlue};
    input {
      &::selection {
        color: #fff;
        background: ${colors.secondaryBlue} !important;
      }
    }
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
  /* ant mobile inputs to prevent zooming */
  @media (max-width: 768px) {
    .ant-form-item .ant-select,
    .ant-form-item .ant-input {
      font-size: 16px;
    }
  }
  .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
    margin: 0 6px; /* keep input string touching border */
    padding: 0; /* remove ios padding */
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-select {
    /* width: 100%; */
  }
  .ant-checkbox {
    &-checked .ant-checkbox-inner {
      background-color: ${colors.secondaryBlue};
      border-color: ${colors.secondaryBlue};
    }
  }
  textarea {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  label {
    ${label};
  }
  label[for] {
    ${label};
    width: 100%;
    display: flex;
    &::before {
      ${asterisk};
      order: 1;

      margin-top: 4px;
    }
  }
`;

const isServer = typeof window === 'undefined';

const getSelectElements = () => {
  if (isServer) return [];
  return Array.from(document.querySelectorAll('.ant-select-selection--single'));
};

const Form = props => {
  const { children, theme, ..._props } = props;
  const singleElements = getSelectElements();
  const singleCount = singleElements.length;

  useEffect(() => {
    const singles = getSelectElements();
    const listener = e => {
      if (e.keyCode === 13 && e.target.classList.contains('ant-select-selection--single')) {
        const parentForm = e.target.closest('form');
        e.stopPropagation();
        parentForm.dispatchEvent(new Event('submit'));
      }
    };
    singles.forEach(select => {
      select.addEventListener('keydown', listener);
    });

    return () => {
      singles.forEach(select => {
        select.removeEventListener('keydown', listener);
      });
    };
  }, [singleCount]);

  return (
    <AntForm colon={false} {..._props} css={[form, themeStyle[theme]]}>
      {children}
    </AntForm>
  );
};

Form.defaultProps = {
  theme: 'default',
};

Form.Item = AntForm.Item;
Form.create = AntForm.create;

export default Form;
