/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  forgotform: {
    emailaddress: '',
  },
  meta: {
    error: false,
    message: '',
  },
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setForgotFormData: (state, action) => {
      state.forgotform[action.payload.field] = action.payload.value;
    },
  },
});

export const { setForgotFormData } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
