export const colXsProps = {
  xs: 24,
  md: 6,
};

export const colThirdProps = {
  lg: 8,
  sm: 24,
  xs: 24,
};

export const colSmProps = {
  xs: 24,
  md: 10,
};

export const colMdProps = {
  xs: 24,
  md: 12,
};

export const colLgProps = {
  xs: 24,
};

export const rowProps = {
  type: 'flex',
  gutter: 16,
};
