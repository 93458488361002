import React from 'react';

const SvgHurricanes = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M12.838 17.692a1.019 1.019 0 011.307-.18l22.04 14.084a1.019 1.019 0 01-.548 1.877h-3.928l1.764 13.236h20.58v2.036H0V46.71h6.983L5.218 33.473h-4.2a1.018 1.018 0 01-.76-1.697zm8.712 21.89h-6.075l1.296 7.127h6.076l-1.297-7.127zm8.104-6.11H7.273L9.038 46.71H14.7l-1.447-7.964a1.017 1.017 0 011.001-1.2H22.4c.492 0 .914.352 1.002.836l1.515 8.328h6.502l-1.765-13.236zM13.78 19.695L3.293 31.436h28.86L13.78 19.694zm41.202 8.688a1.018 1.018 0 110 2.036h-11.2a1.018 1.018 0 110-2.036zM33.127 16.164a1.018 1.018 0 110 2.036c-1.423 0-2.581 1.37-2.581 3.054 0 1.685 1.158 3.055 2.58 3.055.085 0 .16.029.238.048.078-.02.152-.048.236-.048h21.382a1.018 1.018 0 110 2.036H33.6c-.084 0-.158-.028-.236-.047-.079.019-.153.047-.237.047-2.547 0-4.618-2.284-4.618-5.09 0-2.807 2.071-5.091 4.618-5.091zm15.746 4.072a1.018 1.018 0 110 2.037h-11.2a1.018 1.018 0 110-2.037zM35.637 7a1.018 1.018 0 110 2.036H9.164c-.084 0-.159-.028-.237-.047-.078.019-.152.047-.236.047-1.423 0-2.582 1.37-2.582 3.055 0 1.684 1.159 3.054 2.582 3.054a1.018 1.018 0 110 2.037c-2.547 0-4.618-2.284-4.618-5.091C4.073 9.284 6.144 7 8.691 7c.084 0 .158.029.236.048.078-.02.153-.048.237-.048zm-3.055 4.073a1.018 1.018 0 110 2.036H19.346a1.018 1.018 0 110-2.036z"
    />
  </svg>
);

export default SvgHurricanes;
