import React from 'react';

const Play = () => (
  <svg
    width="88"
    height="87"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        fill="#fff"
        d="M43.2639986 70.7979997l26-19.448-26-19.552v39zm8.7360022-62.71199997c7.9040397 0 15.2186333 1.97598027 21.9440004 5.92800007 6.5173661 3.7440188 11.6479815 8.8746343 15.3920003 15.3920004 3.9520199 6.7253671 5.9280002 14.0399607 5.9280002 21.9440004s-1.9759803 15.2186334-5.9280002 21.9440005c-3.7440188 6.517366-8.8746342 11.6479815-15.3920003 15.3920003-6.7253671 3.9520198-14.0399607 5.9280001-21.9440004 5.9280001s-15.2186334-1.9759803-21.9440005-5.9280001C23.5386343 84.8726489 18.4080188 79.7073671 14.664 73.1900011c-3.9520198-6.7253671-5.92800012-14.0052945-5.92800012-21.8400005 0-7.834706 1.97598032-15.1146333 5.92800012-21.8400004 3.8133525-6.5173661 8.9786343-11.6826479 15.4960003-15.4960004 6.7253671-3.9520198 14.0052945-5.92800007 21.8400005-5.92800007z"
        id="a"
      />
    </defs>
    <use fill="#232020" xlinkHref="#a" transform="translate(-8 -8)" fillRule="evenodd" />
  </svg>
);

export default Play;
