export const SAVE_SEARCH_BEGIN = 'SAVE_SEARCH_BEGIN';
export const SAVE_SEARCH_ERROR = 'SAVE_SEARCH_ERROR';
export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS';

export const LOAD_SAVED_SEARCHES_BEGIN = 'LOAD_SAVED_SEARCHES_BEGIN';
export const LOAD_SAVED_SEARCHES_ERROR = 'LOAD_SAVED_SEARCHES_ERROR';
export const LOAD_SAVED_SEARCHES_SUCCESS = 'LOAD_SAVED_SEARCHES_SUCCESS';

export const DELETE_SAVED_SEARCH_BEGIN = 'DELETE_SAVED_SEARCH_BEGIN';
export const DELETE_SAVED_SEARCH_ERROR = 'DELETE_SAVED_SEARCH_ERROR';
export const DELETE_SAVED_SEARCH_SUCCESS = 'DELETE_SAVED_SEARCH_SUCCESS';

export const SET_SELECTED_SAVED_SEARCH_BY_ID = 'SET_SELECTED_SAVED_SEARCH_BY_ID';
export const SET_MODAL_VISIBLE = 'SET_MODAL_VISIBLE';
export const SET_SAVED_SEARCH_INCLUDEONHOMEPAGE_BY_ID = 'SET_SAVED_SEARCH_INCLUDEONHOMEPAGE_BY_ID';

export const RESET_SELECTED_SEARCH = 'RESET_SELECTED_SEARCH';
