import { Modal } from 'antd';
import moment from 'moment';

import { DATE_RANGES_OPTION, validateDateRangeValue } from '@/utils/dateTimeHelpers';

const { confirm } = Modal;

const DATE_FORMAT = 'MM/DD/YYYY';

const deleteSavedSearch = (id, deleteAction) => {
  confirm({
    title: 'Are you sure you want to delete this saved search?',
    onOk() {
      deleteAction();
    },
    onCancel() {},
  });
};

const save = async (form, opts, saveAction, data, successCallback) => {
  await form.validateFieldsAndScroll((err, values) => {
    if (err) return;

    const {
      States: stateOpts,
      LinesOfBusiness: lobOpts,
      Subjects: subjectOpts,
      Publications: pubOpts,
      DayRanges: dayRangeOpts,
      PostingTypes: ptOpts,
      Committees: comOpts,
    } = opts;

    const FieldOptions = {
      ...values,
      States: values.States.map(s => stateOpts[s]),
      LinesOfBusiness: values.LinesOfBusiness.map(l => lobOpts[l]),
      Subjects: values.Subjects.map(s => subjectOpts[s]),
      Publications: values.Publications.map(p => pubOpts[p]),
      DayRanges: values.DayRanges ? [dayRangeOpts[values.DayRanges]] : [],
      PostingTypes: values.PostingTypes.map(t => ptOpts[t]),
      Committees: values.Committees.map(t => comOpts[t]),
      PageSize: null,
      Page: null,
      SortField: null,
      SortOrder: null,
    };

    if (validateDateRangeValue(values.DateRanges)) {
      const [start, end] = values.DateRanges;
      FieldOptions.DateStart = moment(start).format(DATE_FORMAT);
      FieldOptions.DateEnd = moment(end).format(DATE_FORMAT);
      FieldOptions.DateRanges = DATE_RANGES_OPTION;
    } else {
      FieldOptions.DateRanges = [];
    }

    const payload = {
      SearchId: data.SearchId,
      PersonId: data.PersonId,
      IncludeOnHome: values.IncludeOnHome,
      SearchDescription: values.SearchDescription,
      FieldOptions,
    };

    saveAction({
      body: payload,
      successCallback,
    });
  });
};

export { deleteSavedSearch, save };
