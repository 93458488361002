import * as TYPES from './types';

export const LOAD_STATUS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  DONE: 'DONE',
};
export const LOAD_ERROR_TYPES = {
  AUTH_ERROR_EXPIRED: 'AUTH_ERROR_EXPIRED',
  AUTH_ERROR_NOT_ALLOW: 'AUTH_ERROR_NOT_ALLOW',
  APPLICATION: 'APPLICATION',
  [undefined]: 'APPLICATION',
};

const INITIAL_STATE = {
  loadRoutes: {},
  loadRoutesCount: 0,
  loadStatus: LOAD_STATUS.DONE,
  pageCaches: {},
  errorType: null,
};

export default function pageLoadReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.PAGE_LOAD_ERROR: {
      const { path, type } = action.payload;
      const loadRoutesCount = Math.max(0, state.loadRoutesCount - 1);
      return {
        ...state,
        loadRoutes: {
          ...state.loadRoutes,
          [path]: LOAD_STATUS.ERROR,
        },
        loadRoutesCount,
        loadStatus: loadRoutesCount === 0 ? LOAD_STATUS.ERROR : LOAD_STATUS.LOADING,
        errorType: type,
      };
    }
    case TYPES.PAGE_LOAD_START: {
      const { path } = action.payload;
      return {
        ...state,
        loadRoutes: {
          ...state.loadRoutes,
          [path]: LOAD_STATUS.LOADING,
        },
        loadRoutesCount: state.loadRoutesCount + 1,
        loadStatus: LOAD_STATUS.LOADING,
      };
    }
    case TYPES.PAGE_LOAD_SUCCESS: {
      const { path, cache } = action.payload;
      const newCaches = {
        ...state.pageCaches,
        ...(cache ? { [path]: cache } : {}),
      };
      const errorExist = Object.values(state.loadRoutes).includes(LOAD_STATUS.ERROR);
      const loadRoutesCount = Math.max(0, state.loadRoutesCount - 1);
      const loadStatus = loadRoutesCount === 0 ? LOAD_STATUS.DONE : LOAD_STATUS.LOADING;
      return {
        ...state,
        loadRoutes: {
          ...state.loadRoutes,
          [path]: LOAD_STATUS.DONE,
        },
        loadRoutesCount,
        loadStatus: errorExist ? LOAD_STATUS.ERROR : loadStatus,
        pageCaches: newCaches,
      };
    }
    case TYPES.PAGE_LOAD_ERROR_CLEAR: {
      return {
        ...state,
        loadRoutes: {},
        loadRoutesCount: 0,
        loadStatus: LOAD_STATUS.DONE,
        errorType: null,
      };
    }
    default:
      return state;
  }
}
