import { SET_NAMED_PARTY_CURRENT_PAGE } from '../types';

const DEFAULT_CURRENT_PAGE = {
  page: 1,
};

const PageReducer = (state = DEFAULT_CURRENT_PAGE, action) => {
  const { type } = action;
  switch (type) {
    case SET_NAMED_PARTY_CURRENT_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };
    default:
      return state;
  }
};
export default PageReducer;
