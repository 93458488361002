import React from 'react';

const SvgGavel = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M35.745 27.667l-8.888-8.887 4.851-4.851a3.434 3.434 0 00-.008-4.85l-8.07-8.07A3.418 3.418 0 0021.206 0h-.008c-.914 0-1.773.355-2.418 1.001l-4.85 4.85-1.617-1.615a1.142 1.142 0 00-1.615 0L4.233 10.7a1.142 1.142 0 000 1.616l1.616 1.615-4.85 4.85a3.435 3.435 0 00.007 4.85l8.07 8.072a3.43 3.43 0 002.432 1.006 3.41 3.41 0 002.419-1l4.85-4.85 8.888 8.889L46.25 54.332a5.675 5.675 0 004.039 1.672 5.675 5.675 0 004.04-1.673 5.719 5.719 0 00.001-8.078L35.745 27.667zM6.657 11.508l4.849-4.848.807.807-4.848 4.849-.808-.808zm5.654 18.585a1.146 1.146 0 01-1.618-.006l-8.071-8.072a1.146 1.146 0 01-.007-1.618l5.656-5.656h.002l5.104-5.105 7.02-7.02a1.12 1.12 0 01.8-.33c.344 0 .598.12.817.339l8.07 8.07c.449.447.451 1.174.008 1.618l-17.78 17.78zm10.506-7.274l2.424-2.424 8.08 8.08-2.424 2.425-2.424 2.424-8.08-8.08 2.424-2.425zm29.896 29.896c-1.294 1.294-3.553 1.295-4.847.001L30.09 34.94l2.424-2.424 2.424-2.425L52.714 47.87a3.431 3.431 0 010 4.846z"
    />
  </svg>
);

export default SvgGavel;
