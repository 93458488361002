import React from 'react';

const SvgHumanResources = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M55 7a1 1 0 011 1v40a1 1 0 01-1 1H1a1 1 0 01-1-1V8a1 1 0 011-1zm-1 2H2v38h52V9zM38 41v2H18v-2h20zm4-4v2H14v-2h28zm-8.235-25a1 1 0 011 1v4.674A2.45 2.45 0 0136 19.788v.424c0 .933-.536 1.762-1.331 2.169-.368 2.163-1.781 3.947-3.674 4.89l.327.638h2.013c1.745 0 3.165 1.366 3.165 3.045V33a1 1 0 01-1 1h-15a1 1 0 01-1-1v-2.046c0-1.679 1.42-3.045 3.165-3.045h1.725l.384-.748c-1.778-.972-3.09-2.706-3.443-4.782A2.443 2.443 0 0120 20.212v-.424c0-.933.536-1.762 1.331-2.169A6.78 6.78 0 0128 12zm-7.1 15.857l-.775 1.51a1 1 0 01-.89.542h-2.335c-.642 0-1.165.469-1.165 1.045V32h13v-1.046c0-.576-.523-1.045-1.165-1.045h-2.624a1 1 0 01-.89-.543l-.75-1.463A6.72 6.72 0 0128 28c-.457 0-.902-.055-1.335-.143zM32.765 14H28c-2.499 0-4.587 1.947-4.754 4.432a1 1 0 01-.867.924.433.433 0 00-.379.432v.424c0 .213.168.402.382.43a1 1 0 01.864.924C23.413 24.053 25.501 26 28 26c2.499 0 4.587-1.947 4.754-4.432a1 1 0 01.867-.924.432.432 0 00.379-.432v-.424c0-.21-.166-.4-.38-.432a1 1 0 01-.855-.989V14z"
    />
  </svg>
);

export default SvgHumanResources;
