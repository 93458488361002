import React from 'react';

const SvgDrones = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M32.984 43.838v2.015H0v-2.015h32.984zm22.513-3.752V42.1H10.318v-2.015h45.179zM14.657 11c.186 0 .368.051.527.149l19.654 12.045H51.23a4.767 4.767 0 014.77 4.76 4.76 4.76 0 01-4.769 4.76H9.03c-.364 0-.7-.196-.878-.513a705.157 705.157 0 01-1.144-2.038 963.139 963.139 0 01-2.518-4.514l-.092-.165C1.445 20.157.703 18.775.703 18.297c0-.573.453-1.009.994-1.028a1.07 1.07 0 01.494.098 2.5 2.5 0 01.39.221c.22.145.516.364.892.657.64.498 1.506 1.205 2.543 2.072l.18.15a306.54 306.54 0 013.208 2.727h11.975L9.309 12.77c-.707-.61-.275-1.77.658-1.77zm-.284 2.015h-1.698l12.07 10.424c.707.61.275 1.77-.658 1.77H9.029c-.242 0-.476-.087-.659-.245a185.887 185.887 0 00-1.073-.923 304.586 304.586 0 00-2.396-2.03l-.177-.148-.04-.033c.436.797.931 1.694 1.476 2.678l.091.164a1139.809 1139.809 0 003.367 6.027h41.613a2.745 2.745 0 002.754-2.745 2.752 2.752 0 00-2.754-2.745H34.553c-.185 0-.368-.051-.526-.148L14.373 13.015z"
    />
  </svg>
);

export default SvgDrones;
