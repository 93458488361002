import {
  ADD_COMPANY_CONTACT_BEGIN,
  ADD_COMPANY_CONTACT_ERROR,
  ADD_COMPANY_CONTACT_SUCCESS,
  LOAD_COMPANY_CONTACTS_BEGIN,
  LOAD_COMPANY_CONTACTS_ERROR,
  LOAD_COMPANY_CONTACTS_SUCCESS,
  UPDATE_COMPANY_CONTACT_BEGIN,
  UPDATE_COMPANY_CONTACT_ERROR,
  UPDATE_COMPANY_CONTACT_SUCCESS,
} from './types';

const DEFAULT_STATE = {
  isLoadingCompanyContacts: true,
  companyContactsList: [],
  selectedCompanyContactTypeId: null,
  isRemovingContact: false,
  isAddingCompanyContact: false,
};

const CompanyContactsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOAD_COMPANY_CONTACTS_BEGIN:
      return {
        ...state,
        isLoadingCompanyContacts: action.payload.isLoadingCompanyContacts,
        companyContactsList: action.payload.companyContactsList,
      };
    case LOAD_COMPANY_CONTACTS_ERROR:
      return {
        ...state,
        isLoadingCompanyContacts: action.payload.isLoadingCompanyContacts,
        companyContactsList: action.payload.companyContactsList,
      };
    case LOAD_COMPANY_CONTACTS_SUCCESS:
      return {
        ...state,
        isLoadingCompanyContacts: action.payload.isLoadingCompanyContacts,
        companyContactsList: action.payload.companyContactsList,
      };
    case ADD_COMPANY_CONTACT_BEGIN:
      return {
        ...state,
        isAddingCompanyContact: action.payload.isAddingCompanyContact,
      };
    case ADD_COMPANY_CONTACT_ERROR:
      return {
        ...state,
        isAddingCompanyContact: action.payload.isAddingCompanyContact,
      };
    case ADD_COMPANY_CONTACT_SUCCESS:
      return {
        ...state,
        isAddingCompanyContact: action.payload.isAddingCompanyContact,
        companyContactsList: action.payload.companyContactsList,
      };
    case UPDATE_COMPANY_CONTACT_BEGIN:
      return {
        ...state,
        isUpdatingContact: action.payload.isUpdatingContact,
      };
    case UPDATE_COMPANY_CONTACT_ERROR:
      return {
        ...state,
        isUpdatingContact: action.payload.isUpdatingContact,
        companyContactsList: action.payload.companyContactsList,
      };
    case UPDATE_COMPANY_CONTACT_SUCCESS:
      return {
        ...state,
        isUpdatingContact: action.payload.isUpdatingContact,
        companyContactsList: action.payload.companyContactsList,
      };
    default:
      return state;
  }
};
export default CompanyContactsReducer;
