import { css } from '@emotion/core';

import { colors, easing } from '@/styles';

const style = {
  link: css`
    color: ${colors.primaryBlue};
    // font-weight: 900;
    font-weight: normal;
    line-height: inherit;
    text-shadow: 0px 0px 1px rgb(0, 61, 166);
    appearance: none;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    // line-height: 2.8rem;
    font-family: 'Lato', sans-serif;
    transition: color 0.3s ${easing.easeInOut};
    box-shadow: none !important;
    word-break: break-word;
    hyphens: auto;
    &:focus,
    &:active,
    &:hover {
      outline: none;
      color: ${colors.primaryRed};
      box-shadow: none !important;
    }
  `,
};

export default style;
