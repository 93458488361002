export const easeBaseOut = `cubic-bezier(0.7, 0.3, 0.1, 1)`;
export const easeBaseIn = `cubic-bezier(0.9, 0, 0.3, 0.7)`;
export const easeOut = `cubic-bezier(0.215, 0.61, 0.355, 1)`;
export const easeIn = `cubic-bezier(0.55, 0.055, 0.675, 0.19)`;
export const easeInOut = `cubic-bezier(0.645, 0.045, 0.355, 1)`;
export const easeOutBack = `cubic-bezier(0.12, 0.4, 0.29, 1.46)`;
export const easeInBack = `cubic-bezier(0.71, -0.46, 0.88, 0.6)`;
export const easeInOutBack = `cubic-bezier(0.71, -0.46, 0.29, 1.46)`;
export const easeOutCirc = `cubic-bezier(0.08, 0.82, 0.17, 1)`;
export const easeInCirc = `cubic-bezier(0.6, 0.04, 0.98, 0.34)`;
export const easeInOutCirc = `cubic-bezier(0.78, 0.14, 0.15, 0.86)`;
export const easeOutQuint = `cubic-bezier(0.23, 1, 0.32, 1)`;
export const easeInQuint = `cubic-bezier(0.755, 0.05, 0.855, 0.06)`;
export const easeInOutQuint = `cubic-bezier(0.86, 0, 0.07, 1)`;
