/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { normalize } from 'normalizr';

import request from '@/utils/axios';

import savedSearchSchema from './schema/index';

const initialState = {
  savedSearches: {
    isFetchingCompliassistSavedSearches: false,
    savedSearches: {},
  },
  savedSearch: {
    isSavingSearch: false,
    isDeletingSearch: false,
    selectedSearch: {
      SearchId: null,
      PersonId: null,
      SearchDescription: '',
      FieldOptions: {
        Categories: [],
        Charts: [],
        States: [],
        SearchText: '',
        Page: null,
        PageSize: null,
        SortField: null,
        SortOrder: null,
      },
    },
  },
  searchOptions: {
    isLoadingOptions: true,
    FieldOptions: {
      Categories: [],
      Charts: [],
      States: [],
      SearchText: '',
    },
  },
  searchFiltersValues: {
    Categories: null,
    Charts: [],
    States: [],
    SearchText: '',
  },
  searchResults: {
    isFetchingCharts: false,
    groupByChart: true,
    searchResults: {
      Charts: [],
      Count: 0,
    },
    currentPage: 1,
  },
  stateChart: {
    isFetchingStateChart: true,
    data: {
      ChartJurId: null,
      ChartId: null,
      ChartTitle: '',
      StateCode: '',
      StateDescription: '',
      StateNotes: '',
      Topics: [],
    },
    error: false,
  },
  chartDetail: {
    isFetchingChartData: true,
    chartData: {
      ChartDescription: '',
      ChartNotes: '',
      ChartTitle: '',
      Contact: {
        PersonId: null,
        FirstName: '',
        MiddleName: '',
        LastName: '',
        FullName: '',
        EmailAddress: '',
        Phone: '',
      },
    },
  },
  chartDescriptions: {
    isLoadingChartDescriptions: false,
    chartDescriptions: [],
  },
};

export const requestSavedSearches = createAsyncThunk(
  'compliance/requestSavedSearches',
  async ({ personId, includeOnHome }, { rejectWithValue }) => {
    try {
      const params = { personId };
      if (includeOnHome === 'Y') {
        params.includeOnHome = includeOnHome;
      }
      const response = await request({
        url: '/compliassist/savedsearches',
        method: 'GET',
        params,
        triggerLoad: false,
      });
      const {
        data: { Searches },
      } = response;
      if (Array.isArray(Searches) && Searches[0].SearchId) {
        const normalizedData = normalize(Searches, [savedSearchSchema]);
        const {
          entities: { searches },
        } = normalizedData;

        return searches;
      }
      return {};
    } catch (err) {
      console.warn(err);
      return rejectWithValue(err);
    }
  }
);

export const requestSearchOptions = createAsyncThunk(
  'compliance/requestSearchOptions',
  async (props, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/compliassist/searchoptions',
        method: 'GET',
        triggerLoad: false,
      });
      const { data } = response;
      return data.FieldOptions;
    } catch (err) {
      console.warn(err);
      return rejectWithValue(err);
    }
  }
);

export const requestCharts = createAsyncThunk(
  'compliance/requestCharts',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: '/compliassist/search',
        data: payload,
        triggerLoad: false,
      });

      const { Charts, Count } = response.data;
      if (Count === 0) {
        return {
          Charts: [],
          Count,
        };
      }

      if (Count > 0) {
        return {
          Charts,
          Count,
        };
      }

      return {
        Charts: [],
        Count: 0,
      };
    } catch (err) {
      console.warn(err);
      rejectWithValue(err);
    }
  }
);

export const requestStateChart = createAsyncThunk(
  'compliance/requestStateChart',
  async ({ chartId, stateCode }, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/compliassist/chartstate',
        method: 'GET',
        params: {
          chartId,
          stateCode,
        },
      });

      const { data } = response;
      return data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue(err);
    }
  }
);

export const setSearchandFetchResults = createAsyncThunk(
  'compliance/setSearchandFetchResults',
  async ({ searchId, personId }, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/compliassist/savedsearches',
        method: 'GET',
        params: {
          personId,
        },
        triggerLoad: false,
      });
      const {
        data: { Searches },
      } = response;

      if (Array.isArray(Searches) && Searches[0].SearchId) {
        const normalizedData = normalize(Searches, [savedSearchSchema]);
        const {
          entities: { searches },
        } = normalizedData;
        const selectedSearch = searches[searchId];
        const searchPayload = {
          ...selectedSearch,
        };

        const chartResults = await request({
          method: 'POST',
          url: '/compliassist/search',
          data: searchPayload,
        });

        const searchFiltersPayload = searchPayload.FieldOptions;

        const formattedPayload = {
          SearchText: searchFiltersPayload.SearchText,
          Categories:
            Array.isArray(searchFiltersPayload.Categories) && searchFiltersPayload.Categories.length
              ? searchFiltersPayload.Categories.map(c => c.CategoryId)[0]
              : undefined,
          Charts:
            Array.isArray(searchFiltersPayload.Charts) && searchFiltersPayload.Charts.length
              ? searchFiltersPayload.Charts.map(c => c.ChartId)
              : [],
          States:
            Array.isArray(searchFiltersPayload.States) && searchFiltersPayload.States.length
              ? searchFiltersPayload.States.map(s => s.StateCode)
              : [],
        };

        const { Charts, Count } = chartResults.data;
        if (Count === 0) {
          return {
            searchFilters: formattedPayload,
            Charts: [],
            Count,
          };
        }

        if (Count > 0) {
          return {
            searchFilters: formattedPayload,
            Charts,
            Count,
          };
        }
      } else {
        return {
          searchFilters: {},
          Charts: [],
          Count: 0,
        };
      }
    } catch (err) {
      console.warn(err);
      return rejectWithValue(err);
    }
  }
);

export const chartDetailAction = createAsyncThunk(
  'compliance/chartDetailAction',
  async ({ chartId }, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/compliassist/chart',
        method: 'GET',
        params: {
          chartId,
        },
        triggerLoad: false,
      });

      const { data } = response;
      return data;
    } catch (err) {
      console.warn(err);
      rejectWithValue(err);
    }
  }
);

export const requestChartDescriptions = createAsyncThunk(
  'compliance/requestChartDescriptions',
  async (selectedCategories, { rejectWithValues }) => {
    try {
      const response = await request({
        url: '/compliassist/chartdescriptions',
        method: 'GET',
        triggerLoad: false,
        ...(selectedCategories
          ? {
              params: {
                chartCategories: Array.isArray(selectedCategories)
                  ? selectedCategories.join(',')
                  : selectedCategories,
              },
            }
          : { params: { chartCategories: '' } }),
      });
      const { data } = response;
      return data;
    } catch (err) {
      console.warn(err);
      return rejectWithValues(err);
    }
  }
);

export const setSelectedSearch = createAsyncThunk(
  'compliance/setSelectedSearch',
  async (id, { rejectWithValue }) => {
    try {
      return id;
    } catch (err) {
      console.warn(err);
      return rejectWithValue(err);
    }
  }
);

export const saveCompliassistSearch = createAsyncThunk(
  'saveCompliassistSearch',
  async ({ payload, successCallback }, { rejectWithValue, getState }) => {
    try {
      const currentState = getState().compliance;
      const {
        savedSearches: { savedSearches },
      } = currentState;
      const _savedSearches = { ...savedSearches };
      if (payload.SearchId) {
        _savedSearches[payload.SearchId] = payload;
      } else {
        _savedSearches['new'] = payload;
      }

      const response = await request({
        method: 'POST',
        url: '/compliassist/savesearch',
        data: payload,
      });

      const { data } = response;
      if (data.Success === 'true') {
        if (_savedSearches['new']) {
          delete _savedSearches['new'];
          _savedSearches[data.SearchId] = {
            ...payload,
            SearchId: data.SearchId,
          };
        }

        notification.success({
          message: 'Saved Search',
          description: 'Search successfully saved.',
        });
        if (successCallback) {
          successCallback(data.SearchId);
        }
        return _savedSearches;
      }
      return rejectWithValue(data);
    } catch (err) {
      notification.error({
        message: 'Saved Search',
        description: 'Search unsuccessfully saved.',
      });
      console.warn(err);
      return rejectWithValue(err);
    }
  }
);

export const deleteCompliassistSavedSearch = createAsyncThunk(
  'compliance/deleteCompliassistSavedSearch',
  async (searchId, { rejectWithValue, getState }) => {
    try {
      const currentState = getState().compliance;
      const {
        savedSearches: { savedSearches },
      } = currentState;
      const _savedSearches = { ...savedSearches };
      delete _savedSearches[searchId];

      const response = await request({
        url: '/compliassist/deletesearch',
        method: 'DELETE',
        params: {
          searchId,
        },
        triggerLoad: false,
      });

      const { data } = response;
      if (data.Success === 'true') {
        notification.success({
          message: 'Saved Search',
          description: 'Search successfully deleted.',
        });

        return _savedSearches;
      }
      notification.error({
        message: 'Saved Search',
        description: 'Search unsuccessfully deleted.',
      });
      return savedSearches;
    } catch (err) {
      console.warn(err);
    }
  }
);

export const resetResults = createAsyncThunk(
  'compliance/resetResults',
  async (payload, { rejectWithValue }) => {
    return [];
  }
);

const complianceSlice = createSlice({
  name: 'compliance',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.searchResults.currentPage = action.payload;
    },
    setGroupBy(state, action) {
      state.searchResults.groupByChart = action.payload;
    },
    setSearchFilters(state, action) {
      state.searchFiltersValues = action.payload;
    },
    resetSearchFilters(state, action) {
      state.searchFiltersValues = {
        Categories: null,
        Charts: [],
        States: [],
        SearchText: '',
      };
    },
    updateSearchFilters(state, action) {
      state.searchFiltersValues[action.payload.field] = action.payload.value;
    },
  },
  extraReducers: builder => {
    builder.addCase(requestSavedSearches.pending, (state, action) => {
      state.savedSearches.isFetchingCompliassistSavedSearches = true;
    });

    builder.addCase(requestSavedSearches.fulfilled, (state, action) => {
      state.savedSearches.savedSearches = action.payload;
      state.savedSearches.isFetchingCompliassistSavedSearches = false;
    });

    builder.addCase(requestSavedSearches.rejected, (state, action) => {
      state.savedSearches.isFetchingCompliassistSavedSearches = false;
    });

    builder.addCase(requestSearchOptions.pending, (state, action) => {
      state.searchOptions.isLoadingOptions = true;
    });

    builder.addCase(requestSearchOptions.fulfilled, (state, action) => {
      state.searchOptions.isLoadingOptions = false;
      state.searchOptions.FieldOptions = action.payload;
      state.searchOptions.FieldOptions.Charts = action.payload.Charts.map(c => {
        return {
          ...c,
          ChartDescription: c.ChartTitle,
        };
      });
    });

    builder.addCase(requestSearchOptions.rejected, (state, action) => {
      state.searchOptions.isLoadingOptions = false;
    });

    builder.addCase(requestCharts.pending, (state, action) => {
      state.searchResults.isFetchingCharts = true;
    });

    builder.addCase(requestCharts.fulfilled, (state, action) => {
      state.searchResults.searchResults = action.payload;
      state.searchResults.isFetchingCharts = false;
    });

    builder.addCase(requestCharts.rejected, (state, action) => {
      state.searchResults.isFetchingCharts = false;
    });

    builder.addCase(requestStateChart.pending, (state, action) => {
      state.stateChart.isFetchingStateChart = true;
    });

    builder.addCase(requestStateChart.fulfilled, (state, action) => {
      state.stateChart.isFetchingStateChart = false;
      state.stateChart.data = action.payload;
    });

    builder.addCase(requestStateChart.rejected, (state, action) => {
      state.stateChart.isFetchingStateChart = false;
      state.stateChart.error = true;
    });

    builder.addCase(chartDetailAction.pending, (state, action) => {
      state.chartDetail.isFetchingChartData = true;
    });
    builder.addCase(chartDetailAction.fulfilled, (state, action) => {
      state.chartDetail.isFetchingChartData = false;
      state.chartDetail.chartData = action.payload;
    });
    builder.addCase(chartDetailAction.rejected, (state, action) => {
      state.chartDetail.isFetchingChartData = false;
    });

    builder.addCase(requestChartDescriptions.pending, (state, action) => {
      state.chartDescriptions.isLoadingChartDescriptions = true;
    });
    builder.addCase(requestChartDescriptions.fulfilled, (state, action) => {
      state.chartDescriptions.chartDescriptions = action.payload;
      state.chartDescriptions.isLoadingChartDescriptions = false;
    });
    builder.addCase(requestChartDescriptions.rejected, (state, action) => {
      state.chartDescriptions.isLoadingChartDescriptions = false;
    });

    builder.addCase(setSearchandFetchResults.pending, (state, action) => {
      state.savedSearches.isFetchingCompliassistSavedSearches = true;
    });
    builder.addCase(setSearchandFetchResults.fulfilled, (state, action) => {
      state.savedSearches.isFetchingCompliassistSavedSearches = false;
      state.searchResults.searchResults = {
        Charts: action.payload.Charts,
        Count: action.payload.Count,
      };
      state.searchFiltersValues = action.payload.searchFilters;
    });
    builder.addCase(setSearchandFetchResults.rejected, (state, action) => {
      state.savedSearches.isFetchingCompliassistSavedSearches = false;
    });

    builder.addCase(setSelectedSearch.fulfilled, (state, action) => {
      state.savedSearch.selectedSearch.SearchId = action.payload;
    });

    builder.addCase(saveCompliassistSearch.pending, (state, action) => {
      state.savedSearches.isFetchingCompliassistSavedSearches = true;
    });
    builder.addCase(saveCompliassistSearch.fulfilled, (state, action) => {
      state.savedSearches.savedSearches = action.payload;
      state.savedSearches.isFetchingCompliassistSavedSearches = false;
    });
    builder.addCase(saveCompliassistSearch.rejected, (state, action) => {
      state.savedSearches.isFetchingCompliassistSavedSearches = false;
    });

    builder.addCase(deleteCompliassistSavedSearch.fulfilled, (state, action) => {
      state.savedSearch.savedSearch = action.payload;
    });

    builder.addCase(resetResults.fulfilled, (state, action) => {
      state.searchResults.searchResults = {
        Charts: [],
        Count: 0,
      };
      state.searchResults.isFetchingCharts = false;
    });
  },
});

export const {
  setCurrentPage,
  setGroupBy,
  setSearchFilters,
  resetSearchFilters,
  updateSearchFilters,
} = complianceSlice.actions;

export default complianceSlice.reducer;
