import request from '@/utils/axios';

import { getSearchItemsByCount } from '../../utils';
import {
  LOAD_SEARCH_RESULTS_BEGIN,
  LOAD_SEARCH_RESULTS_ERROR,
  LOAD_SEARCH_RESULTS_SUCCESS,
} from '../types/searchResultsTypes';

function loadSearchResultsBegin() {
  return {
    type: LOAD_SEARCH_RESULTS_BEGIN,
    payload: {
      isLoadingResults: true,
      lastLoadSearchId: null,
    },
  };
}

function loadSearchResultsError() {
  return {
    type: LOAD_SEARCH_RESULTS_ERROR,
    payload: {
      isLoadingResults: false,
    },
  };
}

function loadSearchResultsSuccess(Results, Count, searchItems) {
  return {
    type: LOAD_SEARCH_RESULTS_SUCCESS,
    payload: {
      isLoadingResults: false,
      Results,
      Count,
      searchItems,
    },
  };
}

export const resetResults = () => {
  return async dispatch => {
    dispatch(loadSearchResultsSuccess([], 0));
  };
};

export const fetchSearchResults = payload => {
  return async dispatch => {
    try {
      dispatch(loadSearchResultsBegin());
      await request({
        method: 'POST',
        url: '/cms/search',
        data: payload.SearchOptions,
        triggerLoad: false,
      })
        .then(response => {
          const { data } = response;
          const { Results, Count } = data;
          // debugger;
          if (!Count) {
            dispatch(loadSearchResultsSuccess([], 0));
          } else if (Count && Count > 0) {
            const activeSearchItems = getSearchItemsByCount(payload.SearchOptions, Count);
            dispatch(loadSearchResultsSuccess(Results, Count, activeSearchItems));
          } else {
            dispatch(loadSearchResultsError([], 0));
          }
        })
        .catch(err => {
          console.warn(err);
          dispatch(loadSearchResultsError([], 0));
        });
    } catch (err) {
      console.warn(err);
      dispatch(loadSearchResultsError([], 0));
    }
  };
};
