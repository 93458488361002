import React from 'react';

const SvgWorkersCompensation = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M53 10a3 3 0 013 3v30a3 3 0 01-3 3H3a3 3 0 01-3-3V13a3 3 0 013-3h50zm0 2H3a1 1 0 00-1 1v30a1 1 0 001 1h50a1 1 0 001-1V13a1 1 0 00-1-1zm-9.472 27.283a1 1 0 11.784 1.84A10.967 10.967 0 0140 42a1 1 0 010-2c1.23 0 2.423-.246 3.528-.717zm-10.342-.896a1 1 0 011.4-.197 8.959 8.959 0 003.249 1.548 1 1 0 11-.48 1.942 10.959 10.959 0 01-3.973-1.893 1 1 0 01-.196-1.4zm15.23-4.192a1 1 0 011.87.71 11.005 11.005 0 01-2.355 3.717 1 1 0 01-1.441-1.386 9.005 9.005 0 001.927-3.04zm-4.7-7.077a1 1 0 011.756.956l-4.9 9.005a1 1 0 01-1.622.191l-3.61-4.01a1 1 0 111.487-1.338l2.664 2.96zm-13.764 4.06a1 1 0 011.109.877 8.934 8.934 0 001.126 3.416 1 1 0 11-1.735.995 10.934 10.934 0 01-1.378-4.18 1 1 0 01.878-1.109zM21 14a1 1 0 011 1v18a1 1 0 01-.883.993L21 34H5a1 1 0 01-1-1V15a1 1 0 011-1h16zm-8 14c-3.422 0-6.206 1.763-6.856 4h13.712c-.65-2.237-3.434-4-6.856-4zm35.614-2.18a1 1 0 011.332.475c.63 1.33.988 2.779 1.046 4.276a1 1 0 11-1.999.076 8.948 8.948 0 00-.855-3.495 1 1 0 01.476-1.332zm-17.129-1.784a1 1 0 111.548 1.267 8.974 8.974 0 00-1.678 3.184 1 1 0 01-1.92-.557 10.973 10.973 0 012.05-3.894zM20 16H6v12.585c.924-.885 2.12-1.586 3.482-2.033a5 5 0 117.035.001c1.364.447 2.56 1.148 3.484 2.033L20 16zm-7 4a3 3 0 100 6 3 3 0 000-6zm29.226 1.197a1 1 0 011.265-.631A10.99 10.99 0 0147.3 22.77a1 1 0 01-1.327 1.495 8.99 8.99 0 00-3.115-1.803 1 1 0 01-.631-1.266zm-3.084-1.164a1 1 0 11.154 1.994 8.938 8.938 0 00-3.459.991 1 1 0 01-.926-1.772 10.937 10.937 0 014.231-1.213z"
    />
  </svg>
);

export default SvgWorkersCompensation;
