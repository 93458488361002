import { css, jsx } from '@emotion/core';
import { Icon, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from '@/src/shared/components/Heading';
import { colors, space } from '@/styles';

const { m } = space;
const spinner = <Icon type="loading" style={{ fontSize: 36 }} spin />;

const style = {
  container: css`
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
  `,
  text: css`
    color: ${colors.secondaryBlue};
  `,
  spinner: css`
    color: ${colors.secondaryBlue};
  `,
  loadingPage: css`
    height: 100vh;
    width: 100vw;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

const LoadingPage = ({ text = 'Loading...', height }) => {
  return (
    <div css={style.loadingPage}>
      <div css={style.container} style={{ height }}>
        <Spin indicator={spinner} css={[style.spinner, m.t24]} />
        <Heading type={4} tag={2} weight="bolder" css={[m.b24, m.t32, style.text]}>
          {text}
        </Heading>
      </div>
    </div>
  );
};

LoadingPage.propTypes = {
  text: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LoadingPage.defaultProps = {
  text: 'Loading...',
  height: 'auto',
};

export default LoadingPage;
