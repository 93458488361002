import { css, jsx } from '@emotion/core';

import { colors } from '@/styles';

const style = {
  h: {
    '1': css`
      font-family: 'Lato', sans-serif;
      color: ${colors.secondaryGray};
      font-size: 3.6rem;
      line-height: 4.8rem;
    `,
    '2': css`
      font-family: 'Lato', sans-serif;
      color: ${colors.secondaryGray};
      font-size: 2.8rem;
      line-height: 3.6rem;
    `,
    '3': css`
      font-family: 'Lato', sans-serif;
      color: ${colors.secondaryGray};
      font-size: 2rem;
      line-height: 3.2rem;
    `,
    '4': css`
      font-family: 'Lato', sans-serif;
      color: ${colors.secondaryGray};
      font-size: 1.6rem;
      line-height: 2.4rem;
    `,
    '5': css`
      font-family: 'Lato', sans-serif;
      color: ${colors.secondaryGray};
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 2rem;
    `,
    '6': css`
      font-family: 'Lato', sans-serif;
      color: ${colors.secondaryGray};
      font-size: 1.2rem;
      line-height: 2rem;
    `,
  },
  weight: {
    normal: css`
      font-weight: 400;
    `,
    bold: css`
      font-weight: 700;
    `,
    bolder: css`
      font-weight: 900;
    `,
  },
  theme: {
    primary: css`
      color: ${colors.secondaryBlue};
      line-height: 1.33;
    `,
    gray: css`
      color: ${colors.secondaryGray};
      line-height: 1.33;
    `,
    primaryGray: css`
      color: ${colors.secondaryGrayDark};
      line-height: 1.33;
    `,
    twoBold: css`
      font-size: 2.8rem;
      line-height: 3.6rem;
      font-weight: 900;
    `,
    white: css`
      color: ${colors.white};
      font-weight: 900;
    `,
    primaryBlue: css`
      color: ${colors.primaryBlue};
    `,
    primaryRed: css`
      color: ${colors.primaryRed};
    `,
    jumbo: css`
      font-size: 4.2rem;
      line-height: 4.8rem;
      font-weight: 900;
      word-break: break-word;
    `,
    none: css``,
  },
  size: {
    default: css``,
    lg: css`
      font-size: 2.8rem;
      line-height: 3.6rem;
      margin: 0;
    `,
    md: css`
      font-size: 2rem;
      line-height: 3.2rem;
    `,
    xs: css`
      font-size: 1.2rem;
    `,
  },
};

export default style;
