import moment from 'moment';

export function momentize(datetimestring) {
  return moment(datetimestring, 'MM/DD/YYYY LTS');
}

/**
 * @param {object} dt - moment datetime
 * @returns {string} formatted
 */
export function convertMomentToDate(dt) {
  return dt && momentize(dt).format('MM/DD/YYYY');
}

/**
 * @param {object} dt - moment datetime
 * @returns {string} formatted
 */
export function convertMomentToDateTime(dt) {
  return momentize(dt).format('MM/DD/YYYY LTS');
}

/**
 * @param {string} datetimestring - date time string from api
 * @returns {string} formatted
 */
export function convertToFullMonth(datetimestring) {
  return momentize(datetimestring).format('MMMM D, YYYY');
}

/**
 * @returns {object} moment
 */
export function today() {
  return moment();
}

export function yesterday() {
  return moment().subtract(1, 'day');
}

export function getCurrentDate() {
  return moment().format('YYYY-MM-DD');
}

export const URL_DATE_FORMAT = 'YYYY-MM-DD';

export const datePickerProps = {
  size: 'large',
  format: ['MM/DD/YYYY', 'M/D/YYYY'],
  placeholder: 'Select Date',
};

export const DATE_RANGES_OPTION = [{ DayRangeCode: '6' }];

export const disableDayAfter = current => current && current > moment().endOf('day');

export const validateCustomRange = (rule, value, callback) => {
  const defaultValue = [null, null];
  const [start, end] = value || defaultValue;
  if (!start !== !end) {
    callback(rule.message);
    return;
  }
  callback();
};

export const validateDateRangeValue = dateRanges => {
  if (dateRanges && Array.isArray(dateRanges)) {
    return dateRanges.filter(v => v).length === 2;
  }
  return false;
};

export const transformDateRange = dateRanges => {
  if (dateRanges && Array.isArray(dateRanges)) {
    const len = dateRanges.filter(v => v).length;
    return len >= 1 ? DATE_RANGES_OPTION : [];
  }
  return [];
};

export const handleDatePickerBlur = () => {
  const calendarInputs = [...document.querySelectorAll('.ant-calendar-input')];
  calendarInputs.forEach(calendarInput => {
    if (calendarInput && !calendarInput.readOnly) {
      Object.assign(calendarInput, { readOnly: true });
    }
  });
};

export const fromNow = date => {
  if (date) {
    const diff = moment().diff(moment(date), 'days');
    return diff <= 1 ? moment(date).fromNow() : moment(date).format('MMM DD, YYYY');
  }
  return null;
};
