import React from 'react';

const SvgLaw = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M42.764 44.745c.297 0 .58.13.773.356l6.109 7.127a1.02 1.02 0 01-.773 1.681H7.127a1.02 1.02 0 01-.773-1.68l6.11-7.128c.193-.226.475-.356.772-.356zM56 50.855v2.036h-5.09v-2.036H56zm-50.91 0v2.036H0v-2.036h5.09zm37.205-4.073h-28.59l-4.364 5.09H46.66l-4.364-5.09zM7.127 45.764V47.8h-5.09v-2.036h5.09zm46.837 0V47.8h-5.091v-2.036h5.09zM18.327 18.273c.51 0 .94.376 1.009.88.042.308 1.028 7.579 1.028 11.338 0 3.76-.986 11.03-1.028 11.338-.069.504-.5.88-1.009.88h-6.109c-.509 0-.94-.376-1.009-.88-.042-.308-1.027-7.579-1.027-11.338 0-3.76.985-11.03 1.027-11.338.07-.504.5-.88 1.01-.88zm13.237 0c.509 0 .94.376 1.008.88.043.308 1.028 7.579 1.028 11.338 0 3.76-.985 11.03-1.028 11.338-.068.504-.5.88-1.008.88h-6.11c-.509 0-.94-.376-1.008-.88-.042-.308-1.028-7.579-1.028-11.338 0-3.76.986-11.03 1.028-11.338.068-.504.5-.88 1.009-.88zm13.236 0c.51 0 .94.376 1.009.88.042.308 1.027 7.579 1.027 11.338 0 3.76-.985 11.03-1.027 11.338-.069.504-.5.88-1.009.88h-6.11c-.508 0-.94-.376-1.008-.88-.042-.308-1.027-7.579-1.027-11.338 0-3.76.985-11.03 1.027-11.338.069-.504.5-.88 1.009-.88zm-27.367 2.036h-4.321c-.265 2.075-.894 7.308-.894 10.182 0 2.874.63 8.107.894 10.182h4.321c.265-2.075.894-7.308.894-10.182 0-2.875-.629-8.108-.894-10.182zm13.237 0h-4.322c-.265 2.075-.893 7.308-.893 10.182 0 2.874.628 8.107.893 10.182h4.322c.265-2.075.894-7.308.894-10.182 0-2.875-.63-8.108-.894-10.182zm13.236 0h-4.321c-.265 2.075-.894 7.308-.894 10.182 0 2.874.629 8.107.894 10.182h4.321c.265-2.075.894-7.308.894-10.182 0-2.875-.629-8.108-.894-10.182zM28.004 3.134a1.018 1.018 0 011.01 0l21.382 12.218a1.018 1.018 0 01-.505 1.903H7.127a1.018 1.018 0 01-.505-1.903zm.505 2.057L10.961 15.218h35.096L28.509 5.191z"
    />
  </svg>
);

export default SvgLaw;
