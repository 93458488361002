import {
  GET_NAMED_PARTY_FIELD_OPTIONS_BEGIN,
  GET_NAMED_PARTY_FIELD_OPTIONS_ERROR,
  GET_NAMED_PARTY_FIELD_OPTIONS_SUCCESS,
} from '../types';

const DEFAULT_AMICUS_OPTIONS_STATE = {
  options: {
    statuses: [],
    caseNames: [],
    issues: [],
    states: [],
  },
  isFetchingOptions: true,
};

const FieldOptionsReducer = (state = DEFAULT_AMICUS_OPTIONS_STATE, action) => {
  const { type } = action;
  switch (type) {
    case GET_NAMED_PARTY_FIELD_OPTIONS_BEGIN:
      return {
        ...state,
        isFetchingOptions: action.payload.isFetchingOptions,
      };
    case GET_NAMED_PARTY_FIELD_OPTIONS_ERROR:
      return {
        ...state,
        isFetchingOptions: action.payload.isFetchingOptions,
      };
    case GET_NAMED_PARTY_FIELD_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingOptions: action.payload.isFetchingOptions,
        options: {
          ...state.options,
          ...action.payload.options,
        },
      };
    default:
      return state;
  }
};
export default FieldOptionsReducer;
