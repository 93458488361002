import React from 'react';

const SvgLegislativepriorities = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M51.873 29.527h-1.266l-4.225-13.236h.4a1.018 1.018 0 100-2.036H28.455V8c1.751-.455 3.054-2.036 3.054-3.928A4.077 4.077 0 0027.436 0a4.077 4.077 0 00-4.072 4.073c0 1.892 1.302 3.473 3.054 3.928v6.254H8.091a1.018 1.018 0 100 2.036h1.43l-4.19 13.236H4.018c-.563 0-1.018.456-1.018 1.018 0 4.492 3.654 8.146 8.145 8.146 4.492 0 8.146-3.654 8.146-8.146 0-.562-.456-1.018-1.018-1.018h-1.266l-4.225-13.236h13.636v32.674c-2.884.487-5.09 2.996-5.09 6.017 0 .562.455 1.018 1.017 1.018h10.182c.563 0 1.018-.456 1.018-1.018 0-3.021-2.206-5.53-5.09-6.017V16.29h14.667L38.93 29.527h-1.313c-.563 0-1.018.456-1.018 1.018 0 4.492 3.654 8.146 8.145 8.146 4.492 0 8.146-3.654 8.146-8.146 0-.562-.456-1.018-1.018-1.018zM25.4 4.073a2.04 2.04 0 012.036-2.037 2.04 2.04 0 012.037 2.037 2.039 2.039 0 01-2.037 2.036A2.039 2.039 0 0125.4 4.073zM11.145 36.655a6.12 6.12 0 01-6.024-5.091H17.17a6.12 6.12 0 01-6.025 5.09zm-3.679-7.128l3.686-11.641 3.716 11.641H7.466zM31.38 53.964h-7.887a4.077 4.077 0 013.905-3.052c.014 0 .025.008.038.008.014 0 .025-.007.038-.008a4.077 4.077 0 013.906 3.052zm13.372-36.078l3.716 11.641h-7.402l3.686-11.641zm-.007 18.769a6.12 6.12 0 01-6.024-5.091H50.77a6.12 6.12 0 01-6.025 5.09z"
    />
  </svg>
);

export default SvgLegislativepriorities;
