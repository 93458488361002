/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setUser } from '@sentry/nextjs';

import request from '@/utils/axios';

import { auth0DatabaseClient } from '../utils';

const initialState = {
  isLoading: true,
  isLoggedIn: false,
  token: 'none',
  role: [],
  userinfo: {
    name: '',
    emailaddress: '',
    PersonID: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    FullName: '',
    StreetAddress1: '',
    StreetAddress2: '',
    City: '',
    State: '',
    PostalCode: '',
    Country: '',
    WorkPhone: '',
    Extension: '',
    Fax: '',
    Company: '',
    CompanyID: '',
    DeptID: '',
    Department: '',
    SupervisorID: '',
    Supervisor: '',
    Title: '',
    EmailAddress: '',
    Audience: '',
  },
  error: false,
};

export const fillAuth0User = createAsyncThunk(
  'auth/fillAuth0User',
  async ({ user }, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/profile/detailsauth0?auth0Id=${user.sub}`,
        triggerLoad: false,
      });
      const userInfo = {
        roles: user['https://apcia.org/roles'],
        profile: response.data,
      };

      if (response?.data?.PersonID) {
        setUser({
          id: response.data.PersonID,
        });
      }

      return userInfo;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({
        error: 'There was an error processing the request',
      });
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetUserState(state, action) {
      state = initialState;
    },
    stopLoading(state, action) {
      state.isLoading = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fillAuth0User.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(fillAuth0User.fulfilled, (state, action) => {
      state.role = action.payload.roles;
      state.userinfo = action.payload.profile;
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = false;
    });
    builder.addCase(fillAuth0User.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

export const { resetUserState, stopLoading } = authSlice.actions;

export default authSlice.reducer;
