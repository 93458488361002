import React from 'react';

const Twitter = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx="20" cy="20" r="20" />
      <path
        d="M30.5 13.775a9.019 9.019 0 0 1-2.159 2.226 12.227 12.227 0 0 1-.493 4.017 12.496 12.496 0 0 1-1.539 3.311 13.017 13.017 0 0 1-2.458 2.805c-.95.813-2.097 1.461-3.438 1.946-1.341.484-2.776.726-4.304.726-2.407 0-4.61-.644-6.609-1.932.31.035.657.053 1.04.053 1.998 0 3.78-.613 5.343-1.839a4.196 4.196 0 0 1-2.505-.86 4.21 4.21 0 0 1-1.52-2.125c.294.045.565.067.813.067.382 0 .76-.049 1.133-.147a4.234 4.234 0 0 1-2.472-1.485 4.158 4.158 0 0 1-.98-2.739v-.053a4.27 4.27 0 0 0 1.946.547A4.299 4.299 0 0 1 10.9 16.76a4.194 4.194 0 0 1-.52-2.052c0-.782.196-1.506.587-2.172a12.2 12.2 0 0 0 3.924 3.178 11.992 11.992 0 0 0 4.95 1.326 4.78 4.78 0 0 1-.106-.986c0-1.19.42-2.205 1.259-3.045.84-.84 1.854-1.259 3.044-1.259 1.244 0 2.292.453 3.145 1.36a8.44 8.44 0 0 0 2.732-1.04c-.329 1.021-.96 1.812-1.892 2.372a8.576 8.576 0 0 0 2.478-.667z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Twitter;
