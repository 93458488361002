import {
  SET_CURRENT_PAGE,
  SET_DEFAULT_SORT_SEARCH,
  SET_SORT_FIELD,
  SET_SORT_ORDER,
} from '../types/searchOptionsTypes';

export function setSortField(fieldName) {
  return async dispatch => {
    await dispatch({
      type: SET_SORT_FIELD,
      payload: {
        SortField: fieldName,
      },
    });
  };
}

export function setSortOrder(orderName) {
  return async dispatch => {
    await dispatch({
      type: SET_SORT_ORDER,
      payload: {
        SortOrder: orderName,
      },
    });
  };
}

export function setDefaultSortSearch(defaultSort = true) {
  return async dispatch => {
    await dispatch({
      type: SET_DEFAULT_SORT_SEARCH,
      payload: defaultSort,
    });
  };
}

function _setCurrentPageNumber(pageNumber) {
  return {
    type: SET_CURRENT_PAGE,
    payload: {
      Page: pageNumber,
    },
  };
}
export function setCurrentPageNumber(pageNumber) {
  return async dispatch => {
    try {
      await dispatch(_setCurrentPageNumber(pageNumber));
    } catch (err) {
      console.warn(err);
    }
  };
}
