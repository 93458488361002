/* eslint-disable import/prefer-default-export */
import { get } from 'lodash';

import sitemap from '@/sitemap';

/**
 *
 * @param {string} path - current path
 * @returns {string} title
 */
export function getTitleFromPath(path) {
  const parts = path.split('/').filter(Boolean);
  const partsLength = parts.length;
  parts.forEach((_part, i) => {
    const last = i === partsLength - 1;
    if (!last) parts.splice(i * 2 + 1, 0, 'children');
    if (last) parts.push('title');
  });
  return get(sitemap, parts, null);
}
