import React, { memo } from 'react';

import { MenuItem, NavItem, SubMenu, SubTitle, TopMenuItem } from './components';

const NavPress = memo(props => {
  return (
    <TopMenuItem {...props} title={<span>Media</span>} className="hideIfClosed">
      <NavItem type="default" path="/media/news-releases" locked={false} />
      <NavItem type="default" path="/media/public-affairs-staff" locked={false} />
    </TopMenuItem>
  );
});

export default NavPress;
