import {
  GET_NAMED_PARTY_LEGAL_CASE_BEGIN,
  GET_NAMED_PARTY_LEGAL_CASE_ERROR,
  GET_NAMED_PARTY_LEGAL_CASE_SUCCESS,
} from '../types';

const DEFAULT_LEGAL_CASE_STATE = {
  isFetchingLegalCase: true,
  error: false,
  legalCase: {
    LegalCaseId: null,
    DateCreated: '',
    CaseName: '',
    StatusCode: 1,
    Status: '',
    CaseTypeCode: 1,
    CaseType: '',
    StateCode: '',
    StateDescription: '',
    Issues: [],
    CaseSummaryAttachments: [],
    CaseUpdates: [],
    SourceOfRequest: '',
    Plaintiff: '',
    FilingDeadline: '',
    PolicyType: '',
    BriefFiledDate: '',
    DecisionDate: '',
    Court: {
      CourtCode: '',
      CourtDescription: '',
    },
    Defendant: '',
    CaseSummary: '',
    CoParticipants: '',
    OutsideCounsel: {
      Name: '',
      FirmName: '',
      Address: '',
      Phone: '',
      EmailAddress1: '',
      EmailAddress2: '',
    },
  },
};

const LegalCaseReducer = (state = DEFAULT_LEGAL_CASE_STATE, action) => {
  const { type } = action;
  switch (type) {
    case GET_NAMED_PARTY_LEGAL_CASE_BEGIN:
      return {
        ...state,
        isFetchingLegalCase: action.payload.isFetchingLegalCase,
        error: action.payload.error,
      };
    case GET_NAMED_PARTY_LEGAL_CASE_ERROR:
      return {
        ...state,
        isFetchingLegalCase: action.payload.isFetchingLegalCase,
        error: action.payload.error,
      };
    case GET_NAMED_PARTY_LEGAL_CASE_SUCCESS:
      return {
        ...state,
        isFetchingLegalCase: action.payload.isFetchingLegalCase,
        error: action.payload.error,
        legalCase: {
          ...state.legalCase,
          ...action.payload.legalCase,
        },
      };
    default:
      return state;
  }
};
export default LegalCaseReducer;
