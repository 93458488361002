import React from 'react';

const SvgMeeting = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M27.509 16.754c4.918 0 9.24 3.832 10.461 9.192a3.936 3.936 0 012.31 3.58 3.929 3.929 0 01-2.61 3.704c-1.014 3.19-3.1 5.668-5.71 7 4.3 1.76 7.338 5.985 7.338 10.91 0 .543-.44.983-.982.983H16.702a.982.982 0 01-.983-.983c0-4.925 3.039-9.15 7.338-10.91-2.61-1.332-4.695-3.81-5.71-7a3.929 3.929 0 01-2.61-3.704c0-1.559.924-2.954 2.31-3.58 1.222-5.36 5.544-9.192 10.462-9.192zm0 24.562c-5.086 0-9.282 3.885-9.776 8.842h19.551c-.494-4.957-4.69-8.842-9.775-8.842zm0-22.597c-4.14 0-7.776 3.424-8.645 8.14a.984.984 0 01-.71.77 1.967 1.967 0 00.157 3.83.982.982 0 01.771.708c1.173 4.297 4.56 7.184 8.427 7.184 3.866 0 7.253-2.887 8.426-7.184.1-.365.4-.64.772-.707a1.967 1.967 0 00.157-3.83.984.984 0 01-.71-.77c-.87-4.717-4.506-8.14-8.645-8.14zM47.158 3c3.38 0 6.353 2.531 7.253 6.093A2.904 2.904 0 0156 11.671a2.904 2.904 0 01-1.81 2.681c-1.112 3.222-3.878 5.35-7.032 5.35-3.155 0-5.92-2.128-7.033-5.35a2.904 2.904 0 01-1.81-2.68c0-1.1.63-2.087 1.59-2.579C40.805 5.531 43.778 3 47.158 3zM8.842 3c3.38 0 6.353 2.531 7.253 6.093a2.904 2.904 0 011.59 2.578 2.904 2.904 0 01-1.81 2.681c-1.113 3.222-3.878 5.35-7.033 5.35-3.154 0-5.92-2.128-7.033-5.35A2.904 2.904 0 010 11.672c0-1.1.63-2.087 1.589-2.579C2.489 5.531 5.462 3 8.842 3zm38.316 1.965c-2.604 0-4.892 2.116-5.442 5.031a.982.982 0 01-.715.769.95.95 0 00-.72.906c0 .452.336.84.8.924a.98.98 0 01.773.704c.742 2.654 2.874 4.438 5.304 4.438s4.562-1.784 5.304-4.438a.98.98 0 01.772-.704.952.952 0 00.801-.924.95.95 0 00-.72-.906.982.982 0 01-.715-.769c-.55-2.915-2.838-5.031-5.442-5.031zm-38.316 0C6.238 4.965 3.95 7.08 3.4 9.996a.982.982 0 01-.715.769.95.95 0 00-.72.906c0 .452.337.84.801.924a.98.98 0 01.772.704c.742 2.654 2.874 4.438 5.304 4.438s4.562-1.784 5.304-4.438a.98.98 0 01.772-.704.952.952 0 00.801-.924.95.95 0 00-.72-.906.982.982 0 01-.715-.769c-.55-2.915-2.838-5.031-5.442-5.031z"
    />
  </svg>
);

export default SvgMeeting;
