/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import Router from 'next/router';

import request from '@/utils/axios';

const initialState = {
  isLoadingAdvocacyPeople: true,
  advocacyPeople: [],
  selectedCommitteeCode: null,
};

export const getAdvocacyPeople = createAsyncThunk(
  'advocacyParticipation/getAdvocacyPeopleStatus',
  async (companyId, { rejectWithValue }) => {
    try {
      const { data } = await request({
        url: 'profile/advocacy',
        params: {
          companyId,
        },
        triggerLoad: false,
      });
      try {
        return data.Committees.map(c => ({ ...c, Members: c.Members.filter(m => Boolean(m.PersonId)) }));
      } catch (error) {
        return data.Committees
      }
    } catch (err) {
      console.warn(err);
      return rejectWithValue(err);
    }
  }
);

export const saveAdvocacyPerson = createAsyncThunk(
  'advocacyParticipation/saveAdvocacyPerson',
  async ({ name, personId, committeeId, editorId }, { rejectWithValue }) => {
    try {
      const newPerson = {
        PersonId: personId,
        FullName: name,
        CommitteeCode: committeeId,
        DesignatedMember: 'N',
        MemberTypeCode: 'A',
      };

      const response = await request({
        method: 'POST',
        url: `profile/addadvocacymember`,
        triggerLoad: false,
        data: {
          PersonId: personId,
          CommitteeCode: committeeId,
          EditorId: editorId,
        },
      });
      const { data } = response;
      if (data.Success === 'true') {
        notification.success({
          message: 'Success',
          description: data.Message,
        });
        return newPerson;
      }
      notification.error({
        message: 'Error',
        description: 'Error adding person',
      });
      throw new Error('Something went wrong');
    } catch (err) {
      console.warn(err);
      return rejectWithValue(err);
    }
  }
);

export const deleteAdvocacyPerson = createAsyncThunk(
  'advocacyParticipation/deleteAdvocacyPerson',
  async ({ personId, committeeId, editorId }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const personToDelete = {
        PersonId: personId,
        CommitteeCode: committeeId,
        EditorId: editorId || state.auth.userinfo.PersonID,
      };
      const response = await request({
        method: 'DELETE',
        url: 'profile/removeadvocacymember',
        triggerLoad: false,
        data: personToDelete,
      });

      const { data } = response;
      if (data.Success === 'true') {
        notification.success({
          message: 'Success',
          description: data.Message,
        });
        return personToDelete;
      }
      throw new Error(data.message);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error removing person.',
      });
      return rejectWithValue(err);
    }
  }
);

export const updateDesignatedMember = createAsyncThunk(
  'advocacyParticipation/updateDesignatedMember',
  async (
    { name, personId, committeeId, editorId, isDesignated },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const editedPerson = {
        PersonId: personId,
        FullName: name,
        CommitteeCode: committeeId,
        MemberTypeCode: isDesignated ? 'M' : 'A',
      };

      const response = await request({
        method: 'POST',
        url: 'profile/updateadvocacymember',
        triggerLoad: false,
        data: {
          PersonId: personId,
          CommitteeCode: committeeId,
          EditorId: editorId || state.auth.userinfo.PersonID,
          DesignatedMember: isDesignated ? 'Y' : 'N',
        },
      });
      const { data } = response;
      if (data.Success === 'true') {
        notification.success({
          message: 'Success',
          description: data.Message,
        });
        return editedPerson;
      }
      throw new Error('Could not update designated member');
    } catch (err) {
      console.warn(err);
      notification.error({
        message: 'Error',
        description: 'Error updating designated member.',
      });
      return rejectWithValue(err);
    }
  }
);

const advocacyParticipationSlice = createSlice({
  name: 'advocacyParticipation',
  initialState,
  reducers: {
    setSelectedCommittee(state, action) {
      state.selectedCommitteeCode = action.payload;
    },
  },
  extraReducers: builder => {
    // Get Actions

    builder.addCase(getAdvocacyPeople.fulfilled, (state, action) => {
      state.isLoadingAdvocacyPeople = false;
      state.advocacyPeople = action.payload;
    });

    builder.addCase(getAdvocacyPeople.rejected, (state, action) => {
      notification.error({
        message: 'Error',
        description: 'There was an error loading the page. Try again later',
      });
      Router.push('/');
    });

    // Save Actions
    builder.addCase(saveAdvocacyPerson.fulfilled, (state, action) => {
      const oldState = JSON.parse(JSON.stringify(state.advocacyPeople));
      const targetCommittee = oldState.find(c => {
        return c.CommitteeCode === action.payload.CommitteeCode;
      });
      const targetCommitteeIndex = oldState.indexOf(targetCommittee);
      const isPersonAlreadyAssigned = targetCommittee.Members.some(member => {
        return member.PersonId === action.payload.PersonId;
      });
      if (!isPersonAlreadyAssigned) {
        state.advocacyPeople[targetCommitteeIndex].Members.push(action.payload);
      }
    });

    builder.addCase(getAdvocacyPeople.pending, (state, action) => {
      state.isLoadingAdvocacyPeople = true;
    });

    // Delete Actions
    builder.addCase(deleteAdvocacyPerson.fulfilled, (state, action) => {
      const oldState = JSON.parse(JSON.stringify(state.advocacyPeople));
      const targetCommitteeIndex = oldState.findIndex(c => {
        return c.CommitteeCode === action.payload.CommitteeCode;
      });
      const personToDelete = state.advocacyPeople[targetCommitteeIndex].Members.findIndex(
        member => {
          return member.PersonId === action.payload.PersonId;
        }
      );
      state.advocacyPeople[targetCommitteeIndex].Members.splice(personToDelete, 1);
    });

    builder.addCase(deleteAdvocacyPerson.rejected, (state, action) => {});

    // Update Designated Member Actions
    builder.addCase(updateDesignatedMember.fulfilled, (state, action) => {
      const oldState = JSON.parse(JSON.stringify(state.advocacyPeople));
      const targetCommittee = oldState.find(c => {
        return c.CommitteeCode === action.payload.CommitteeCode;
      });
      const targetCommitteeIndex = oldState.indexOf(targetCommittee);

      const oldDesignatedMember = targetCommittee.Members.findIndex(member => {
        return member.MemberTypeCode === 'M';
      });

      const newDesignatedMember = targetCommittee.Members.findIndex(member => {
        return member.PersonId === action.payload.PersonId;
      });

      if (oldDesignatedMember !== -1) {
        state.advocacyPeople[targetCommitteeIndex].Members[oldDesignatedMember].MemberTypeCode =
          'A';
      }
      if (
        oldDesignatedMember === -1 ||
        oldState[targetCommitteeIndex].Members[oldDesignatedMember].PersonId !==
          action.payload.PersonId
      ) {
        state.advocacyPeople[targetCommitteeIndex].Members[newDesignatedMember].MemberTypeCode =
          'M';
      }
    });

    builder.addCase(updateDesignatedMember.rejected, (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Error saving person.',
      });
    });
  },
});

export const { setSelectedCommittee } = advocacyParticipationSlice.actions;

export default advocacyParticipationSlice.reducer;
