import { css, jsx } from '@emotion/core';
import { Divider } from 'antd';
import React, { memo } from 'react';
import { connect } from 'react-redux';

import CustomIcon from '@/src/shared/components/Icon';
import { colors } from '@/styles';

import { COMPLIANCE_ACCESS } from '../../constants';
import { MenuItem, NavItem, PadLock, SubMenu, SubTitle, TopMenuItem } from './components';

const style = {
  divider: css`
    background-color: ${colors.primaryBlue};
  `,
};

const NavCompliance = memo(props => {
  const { isLoggedIn, role, dispatch, ..._props } = props;

  return (
    <>
      <TopMenuItem {..._props} title={<span>Compliance</span>} className="hideIfClosed">
        <NavItem type="default" path="/compliance" memberLevels={COMPLIANCE_ACCESS.COMPLIANCE} />
        <SubMenu title={<SubTitle title="Regulatory Changes" />}>
          <NavItem path="/compliance/regulatory-changes/enacted-law-bulletin" />
          <NavItem path="/compliance/regulatory-changes/adopted-regulation-bulletin" />
          <NavItem path="/compliance/regulatory-changes/new-law-alert" />
          <NavItem path="/advocacy/state/legislative-wrap-up" />
        </SubMenu>
        <SubMenu title={<SubTitle title="Legislative &amp; Regulatory Activity" />}>
          <MenuItem type="subItem">
            <a
              href={`${process.env.API_ROOT}/pciwebsite/StateNetRedirect.aspx?type=maptrack`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PadLock isLoggedIn={isLoggedIn} role={role} />
              <span>Legislation and Regulation Database</span>
              <CustomIcon name="External" />
            </a>
          </MenuItem>
          <NavItem path="/advocacy/state/capital-bulletin" />
          <NavItem path="/compliance/legislative-regulatory-activity/legislative-tracking-report" />
          <NavItem path="/compliance/legislative-regulatory-activity/proposed-regulation-bulletin" />
          <NavItem path="/compliance/legislative-regulatory-activity/regulatory-tracking-report" />
          <NavItem path="/advocacy/state/environment-report" />
        </SubMenu>
        <SubMenu title={<SubTitle title="State Filings" />}>
          <NavItem
            path="/compliance/state-filings/state-filing-guide"
            memberLevels={COMPLIANCE_ACCESS.STATE_FILINGS}
          />
          <NavItem
            path="/compliance/state-filings/large-commercial-risk-exemptions-tool"
            memberLevels={COMPLIANCE_ACCESS.STATE_FILINGS}
          />
        </SubMenu>
        <SubMenu title={<SubTitle title="Claims Compliance" />}>
          <NavItem
            path="/compliance/claims-compliance/claims-handling-unfair-practices-guide"
            memberLevels={COMPLIANCE_ACCESS.CLAIMS_COMPLIANCE}
          />
          <NavItem
            path="/compliance/claims-compliance/claims-unfair-handling-laws-regulations"
            memberLevels={COMPLIANCE_ACCESS.CLAIMS_COMPLIANCE}
          />
          <NavItem
            path="/compliance/claims-compliance/claims-bulletin"
            memberLevels={COMPLIANCE_ACCESS.CLAIMS_COMPLIANCE}
          />
         <NavItem
            path="/compliance/claims-compliance/catastrophe-claims-guide"
            memberLevels={COMPLIANCE_ACCESS.CLAIMS_COMPLIANCE}
          />
        </SubMenu>
        <SubMenu title={<SubTitle title="Market Conduct" />}>
          <NavItem
            path="/compliance/market-conduct/annual-review"
            title="Market Conduct Annual Review"
          />
          <NavItem
            path="/compliance/market-conduct/bulletin"
            memberLevels={COMPLIANCE_ACCESS.MARKET_CONDUCT}
          />
          <NavItem path="/compliance/market-conduct/forum" />
          <NavItem
            path="/compliance/market-conduct/laws-regulations"
            memberLevels={COMPLIANCE_ACCESS.MARKET_CONDUCT}
          />
        </SubMenu>
        <SubMenu title={<SubTitle title="Automobile Insurance Compliance" />}>
          <NavItem
            path="/compliance/automobile-insurance/law-enforcement-procedures-guide"
            memberLevels={COMPLIANCE_ACCESS.AUTO_INSURANCE_COMPLIANCE}
          />
          <NavItem
            path="/compliance/automobile-insurance/physical-damage-guide"
            memberLevels={COMPLIANCE_ACCESS.AUTO_INSURANCE_COMPLIANCE}
          />
          <NavItem
            path="/compliance/automobile-insurance/accident-reparations-laws-regulations"
            memberLevels={COMPLIANCE_ACCESS.AUTO_INSURANCE_COMPLIANCE}
          />
          <NavItem
            path="/compliance/automobile-insurance/um-uim-coverage-laws-regulations"
            memberLevels={COMPLIANCE_ACCESS.AUTO_INSURANCE_COMPLIANCE}
          />
          <NavItem
            path="/compliance/automobile-insurance/um-uim-case-law-analysis-comment"
            memberLevels={COMPLIANCE_ACCESS.AUTO_INSURANCE_COMPLIANCE}
          />
        </SubMenu>
        <SubMenu
          title={
            <SubTitle
              title="Compliance Resources"
              memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
            />
          }
        >
          <NavItem
            path="/compliance/compliassist"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
          <NavItem
            path="/compliance/resources/bulletin"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
          <MenuItem type="subItem">
            <a href="/66876/">
              <PadLock
                isLoggedIn={isLoggedIn}
                role={role}
                memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
              />
              <span>Compliance Webinars</span>
            </a>
          </MenuItem>
          <NavItem
            path="/compliance/resources/data-security-laws-quick-reference"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
          <NavItem
            path="/compliance/resources/agents-licensing-guide"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
          <NavItem
            path="/compliance/resources/cancellation-nonrenewal-guide"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
          <NavItem
            path="/compliance/resources/insurance-fraud-guide"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
          <NavItem
            path="/compliance/resources/state-tax-guide"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
          <NavItem
            path="/compliance/resources/accident-prevention-compliance"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
          <NavItem
            path="/compliance/resources/record-retention-requirements"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
          <NavItem
            path="/compliance/resources/risk-based-pricing-laws-and-regulations"
            memberLevels={COMPLIANCE_ACCESS.COMPLIANCE_RESOURCES}
          />
        </SubMenu>
        <Divider css={style.divider} />
        <NavItem type="default" path="/research-papers" locked={false} />
      </TopMenuItem>
    </>
  );
});

export default connect(
  state => {
    const { auth } = state;
    const { isLoggedIn, role } = auth;
    return { isLoggedIn, role };
  },
  null
)(NavCompliance);
