import React from 'react';

const Avatar = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="currentColor"
    style={{ transform: 'translateY(-1px)' }}
  >
    <path
      d="M26.067554,24.804608 C25.5414383,23.5613466 24.7867129,22.4433509 23.8284308,21.4850688 C22.8701488,20.5267867 21.752153,19.7720613 20.5088916,19.2459456 C20.0861201,19.0674421 19.6508221,18.9171234 19.2123923,18.7949894 C20.4525221,18.3753495 21.5893077,17.673862 22.5413265,16.7218432 C24.1697797,15.09339 25.0685606,12.9262946 25.0685606,10.6245386 C25.0685606,8.32278269 24.1729113,6.15568728 22.5413265,4.52723409 C20.9128733,2.8987809 18.7457779,2 16.4440219,2 C14.142266,2 11.9751706,2.89564926 10.3467174,4.52723409 C8.71826418,6.15568728 7.81948328,8.32278269 7.81948328,10.6245386 C7.81948328,12.9262946 8.71513254,15.09339 10.3467174,16.7218432 C11.2987362,17.673862 12.4355218,18.3753495 13.6756515,18.7949894 C13.2372218,18.9171234 12.8019237,19.0643105 12.3791522,19.2459456 C11.1358908,19.7720613 10.0178951,20.5267867 9.05961302,21.4850688 C8.10133095,22.4433509 7.34660553,23.5613466 6.82048988,24.804608 C6.27558439,26.094844 6,27.4602393 6,28.8726093 C6,29.4958058 6.50419416,30 7.12739067,30 C7.75058718,30 25.1437199,30 25.7669165,30 C26.390113,30 26.8943071,29.4958058 26.8943071,28.8726093 C26.8880438,27.4602393 26.6124595,26.094844 26.067554,24.804608 Z"
      fillRule="evenodd"
    />
  </svg>
);

export default Avatar;
