export const LOAD_COMPANY_CONTACTS_BEGIN = 'LOAD_COMPANY_CONTACTS_BEGIN';
export const LOAD_COMPANY_CONTACTS_ERROR = 'LOAD_COMPANY_CONTACTS_ERROR';
export const LOAD_COMPANY_CONTACTS_SUCCESS = 'LOAD_COMPANY_CONTACTS_SUCCESS';

export const REMOVE_COMPANY_CONTACT_BEGIN = 'REMOVE_COMPANY_CONTACT_BEGIN';
export const REMOVE_COMPANY_CONTACT_ERROR = 'REMOVE_COMPANY_CONTACT_ERROR';
export const REMOVE_COMPANY_CONTACT_SUCCESS = 'REMOVE_COMPANY_CONTACT_SUCCESS';

export const ADD_COMPANY_CONTACT_BEGIN = 'ADD_COMPANY_CONTACT_BEGIN';
export const ADD_COMPANY_CONTACT_ERROR = 'ADD_COMPANY_CONTACT_ERROR';
export const ADD_COMPANY_CONTACT_SUCCESS = 'ADD_COMPANY_CONTACT_SUCCESS';

export const UPDATE_COMPANY_CONTACT_BEGIN = 'UPDATE_COMPANY_CONTACT_BEGIN';
export const UPDATE_COMPANY_CONTACT_ERROR = 'UPDATE_COMPANY_CONTACT_ERROR';
export const UPDATE_COMPANY_CONTACT_SUCCESS = 'UPDATE_COMPANY_CONTACT_SUCCESS';
