import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import style from './style';

const TAGS = [undefined, 1, 2, 3, 4, 5, 6, '1', '2', '3', '4', '5', '6', 'a'];

const Heading = memo(props => {
  const { children, type, tag, theme, weight, size, overrideStyles = null, ..._props } = props;
  if ((tag && !TAGS.includes(tag)) || !TAGS.includes(type)) {
    // eslint-disable-next-line no-console
    console.error('Tag must be 1-6');
    return null;
  }
  const tagNumber = type || tag;
  const HeadingTag = props.tag === 'a' ? `a` : `h${tagNumber}`;
  const hcss = style.h[tagNumber];
  const weightcss = style.weight[weight];
  const themecss = style.theme[theme];
  const textSize = style.size[size];
  return (
    <HeadingTag css={[hcss, weightcss, themecss, overrideStyles, textSize]} {..._props}>
      {children}
    </HeadingTag>
  );
});

Heading.propTypes = {
  type: PropTypes.oneOf(TAGS).isRequired,
  tag: PropTypes.oneOf(TAGS), // this will default to the type if not provided
  theme: PropTypes.oneOf([
    'primaryBlue',
    'primary',
    'secondaryGrayDark',
    'gray',
    'primaryGray',
    'white',
    'twoBold',
    'jumbo',
    'none',
    'default',
  ]), // overrides size
  weight: PropTypes.oneOf(['normal', 'bold', 'bolder']),
  size: PropTypes.oneOf(['lg', 'md', 'default']),
};

Heading.defaultProps = {
  theme: 'primary',
  tag: undefined,
  weight: 'normal',
  size: 'default',
};

export default Heading;
