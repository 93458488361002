import React from 'react';

const SvgRegulatoryClimate = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M53.203 5A2.802 2.802 0 0156 7.8c0 1.544-1.255 2.8-2.797 2.8h-.936v23.334A4.676 4.676 0 0147.59 38.6H30.2l4.68 11.2h2.587a.933.933 0 110 1.867H34.26a.934.934 0 01-.86-.574L28.176 38.6h-.706l-5.223 12.493a.934.934 0 01-.861.574h-3.208a.933.933 0 110-1.867h2.586l4.682-11.2H9.342a4.676 4.676 0 01-4.675-4.666V10.6h-1.87A2.801 2.801 0 010 7.8C0 6.256 1.255 5 2.797 5zM50.4 10.6H6.533v23.334c0 1.543 1.26 2.8 2.81 2.8H47.59a2.807 2.807 0 002.809-2.8V10.6zm-37.333 7.343v15.99H11.2v-15.99h1.867zm9.333-2.676v18.666h-1.867V15.267H22.4zm9.333 8.348v10.318h-1.866V23.615h1.866zm9.334-7.415v17.733H39.2V16.2h1.867zm-23.334 6.533v11.2h-1.866v-11.2h1.866zm9.334 4.667v6.533H25.2V27.4h1.867zm9.333-9.457v15.99h-1.867v-15.99H36.4zm9.333 3.815v12.175h-1.866V21.758h1.866zm7.47-14.891H2.797a.933.933 0 00-.93.933c0 .523.408.933.93.933h50.406c.513 0 .93-.418.93-.933a.922.922 0 00-.93-.933z"
    />
  </svg>
);

export default SvgRegulatoryClimate;
