import React from 'react';

const SvgConstruction = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M52.678 6.898A3.327 3.327 0 0156 10.223v32.743c0 .19-.07.358-.167.507-.423 1.35-1.67 2.34-3.155 2.34h-39.39v4.746a.95.95 0 01-.949.95H9.966a3.33 3.33 0 01-3.322-3.332V22.085a.949.949 0 111.898 0v22.164a3.273 3.273 0 011.424-.334h42.241a1.9 1.9 0 001.895-1.9v-.463a1.43 1.43 0 00-1.424-1.433h-2.373a.949.949 0 01-.95-.95V13.542h-32.27a.949.949 0 110-1.898h32.27V7.847a.95.95 0 01.95-.949zM11.39 45.814H9.966a1.43 1.43 0 00-1.424 1.433v.93c0 .79.639 1.433 1.424 1.433h1.424v-3.796zm30.373-27.526v1.898H28.475v5.695h-1.899v-5.695h-8.542V38.22h8.542v-9.49h1.899v9.491h11.39v-7.593h-8.543V28.73h8.542v-5.695h1.899V38.22h.018v1.899H16.136v-21.83h25.627zm10.915-9.491h-1.424V38.22h1.424c.511 0 .99.126 1.424.335V10.223c0-.786-.639-1.426-1.424-1.426zM7.593 5c4.187 0 7.593 3.406 7.593 7.593 0 4.187-3.406 7.593-7.593 7.593C3.406 20.186 0 16.78 0 12.593 0 8.406 3.406 5 7.593 5zm0 1.898a5.701 5.701 0 00-5.695 5.695 5.701 5.701 0 005.695 5.695 5.701 5.701 0 005.695-5.695 5.701 5.701 0 00-5.695-5.695zm1.999 2.423a.95.95 0 011.698.85l-2.848 5.694a.947.947 0 01-1.375.365l-2.848-1.898a.948.948 0 111.053-1.58l1.953 1.303z"
    />
  </svg>
);

export default SvgConstruction;
