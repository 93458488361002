import { css, jsx } from '@emotion/core';
import { Icon } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

import Button from '@/src/shared/components/Button';
import Container from '@/src/shared/components/Container';
import { bp, colors } from '@/styles';
import { COOKIE_KEY, getCookie, setCookie } from '@/utils/utils';

const style = {
  cookiebanner: css`
    padding: 8px 0;
    font-size: 1.4rem;
    background-color: ${colors.secondaryBlue};
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    @media print {
      display: none;
    }
  `,
  cookieinfo: css`
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 2.4rem;
    margin-bottom: 0;
  `,
  cookieinner: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media ${bp.md} {
      flex-direction: row;
    }
  `,
  cookieicon: css`
    margin-right: 16px;
    font-size: 24px;
  `,
  cookieactions: css`
    margin-left: auto;
    flex-shrink: 0;
  `,
  morecookies: css`
    font-size: 1.2rem;
    padding: 4px 6px;
    line-height: 1;
    margin-right: 10px;
    font-weight: bold;
    color: white !important;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: white !important;
    }
  `,
};

function CookieBanner(props) {
  const { isLoggedIn } = props;
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    const hasAccepted = getCookie(COOKIE_KEY);
    setAccepted(hasAccepted === 'true');
  }, []);

  const handleAcceptClick = () => {
    setCookie(COOKIE_KEY, true);
    setAccepted(true);

    const { GA_ID } = process.env;
    if (!window.GA_INITIALIZED && GA_ID) {
      ReactGA.initialize(GA_ID);
      window.GA_INITIALIZED = true;
    }
  };

  return accepted || isLoggedIn ? null : (
    <div css={style.cookiebanner}>
      <Container>
        <div css={style.cookieinner}>
          <p css={style.cookieinfo}>
            <Icon type="exclamation-circle" css={style.cookieicon} theme="filled" />
            This website uses cookies to ensure you get the best experience. By continuing to use
            our site, you accept our use of cookies.
          </p>
          <div css={style.cookieactions}>
            <a href="/privacy-policy" css={style.morecookies}>
              Read More
            </a>
            <Button onClick={handleAcceptClick} theme="white" size="small">
              Accept
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CookieBanner;
