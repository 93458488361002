import { Modal } from 'antd';

const { confirm } = Modal;

const links = [
  { url: 'http://pcievents.cvent.com', speedbump: 'N' },
  { url: 'https://pciaa.webex.com', speedbump: 'N' },
  { url: 'https://news.cision.com', speedbump: 'N' },
  { url: 'https://www2.smartbrief.com', speedbump: 'N' },
  { url: 'http://custom.statenet.com', speedbump: 'N' },
  { url: 'https://www.securingourfuture.com', speedbump: 'N' },
  { url: 'https://voiceofamericasinsurers.org', speedbump: 'N' },
  { url: 'https://apciaevents.cventevents.com', speedbump: 'N' },
  { url: 'pciaa.net', speedbump: 'N' },
  { url: 'pcipac.com', speedbump: 'N' },
  { url: 'pcipoliticalaction.net', speedbump: 'N' },
  { url: 'pcivotes.net', speedbump: 'N' },
  { url: 'dev.pciaa.net', speedbump: 'N' },
  { url: 'dev.apci.org', speedbump: 'N' },
  { url: 'front.pciaa.net', speedbump: 'N' },
  { url: 'preprod.pciaa.net', speedbump: 'N' },
  { url: 'preprod.apci.org', speedbump: 'N' },
  { url: 'test.pciaa.net', speedbump: 'N' },
  { url: 'testapci.org', speedbump: 'N' },
  { url: 'www2.pcipoliticalaction.net/westfield_group', speedbump: 'N' },
  { url: 'https://www2.pcipoliticalaction.net/church_mutual', speedbump: 'N' },
  { url: 'https://www2.pcipoliticalaction.net/AAACarolinas', speedbump: 'N' },
  { url: 'https://www2.pcipoliticalaction.net/sentry', speedbump: 'N' },
  { url: 'https://www2.pcipoliticalaction.net/american-modern', speedbump: 'N' },
  { url: 'https://www2.pcipoliticalaction.net/state_auto', speedbump: 'N' },
  { url: 'https://www2.pcipoliticalaction.net/emc_insurance', speedbump: 'N' },
  { url: 'https://www2.pcipoliticalaction.net/shelter_insurance', speedbump: 'N' },
  { url: 'acicnet.org', speedbump: 'N' },
  { url: 'apci.org', speedbump: 'N' },
  { url: 'acic-1.com', speedbump: '' },
  { url: 'acic-1.info', speedbump: '' },
  { url: 'acic-1.org', speedbump: '' },
  { url: 'acicnet.com', speedbump: '' },
  { url: 'acicnet.net', speedbump: '' },
  { url: 'americanpcinsuranceassociation.com', speedbump: '' },
  { url: 'americanpcinsuranceassociation.net', speedbump: '' },
  { url: 'americanpcinsuranceassociation.org', speedbump: '' },
  { url: 'americanpcinsurers.com', speedbump: '' },
  { url: 'americanpcinsurers.net', speedbump: '' },
  { url: 'americanpcinsurers.org', speedbump: '' },
  { url: 'americanpropertycasualtyassociation.com', speedbump: '' },
  { url: 'americanpropertycasualtyassociation.net', speedbump: '' },
  { url: 'americanpropertycasualtyassociation.org', speedbump: '' },
  { url: 'americanpropertycasualtyinsuranceassociation.com', speedbump: '' },
  { url: 'americanpropertycasualtyinsuranceassociation.net', speedbump: '' },
  { url: 'americanpropertycasualtyinsuranceassociation.org', speedbump: '' },
  { url: 'americanpropertycasualtyinsurers.com', speedbump: '' },
  { url: 'americanpropertycasualtyinsurers.net', speedbump: '' },
  { url: 'americanpropertycasualtyinsurers.org', speedbump: '' },
  { url: 'americasinsurance.net', speedbump: '' },
  { url: 'americasinsurance.org', speedbump: '' },
  { url: 'americasinsurers.com', speedbump: '' },
  { url: 'americasinsurers.net', speedbump: '' },
  { url: 'americasinsurers.org', speedbump: '' },
  { url: 'apciassoc.com', speedbump: '' },
  { url: 'apciassoc.net', speedbump: '' },
  { url: 'apciassoc.org', speedbump: '' },
  { url: 'autobodychoice.com', speedbump: '' },
  { url: 'autobodychoice.net', speedbump: '' },
  { url: 'autobodychoice.org', speedbump: '' },
  { url: 'autoglasschoice.com', speedbump: '' },
  { url: 'caautobodaychoice.com', speedbump: '' },
  { url: 'caautobodychoice.com', speedbump: '' },
  { url: 'calcrashtax.com', speedbump: '' },
  { url: 'calcrashtax.net', speedbump: '' },
  { url: 'calcrashtax.org', speedbump: '' },
  { url: 'pcipacdonate.com', speedbump: '' },
  { url: 'crashtax.com', speedbump: '' },
  { url: 'crashtax.net', speedbump: '' },
  { url: 'crashtax.org', speedbump: '' },
  { url: 'ctautobodychoice.com', speedbump: '' },
  { url: 'cvent.com', speedbump: '' },
  { url: 'fairnessforgooddrivers.net', speedbump: '' },
  { url: 'gulfstateinsurancecenter.com', speedbump: '' },
  { url: 'gulfstateinsurancecenter.net', speedbump: '' },
  { url: 'gulfstateinsurancecenter.org', speedbump: '' },
  { url: 'insuraceamerica.net', speedbump: '' },
  { url: 'insuraceamerica.org', speedbump: '' },
  { url: 'insuranceamerica.org', speedbump: '' },
  { url: 'insurersforaction.com', speedbump: '' },
  { url: 'iss-statistical.com', speedbump: '' },
  { url: 'iss-statistical.net', speedbump: '' },
  { url: 'iss-statistical.org', speedbump: '' },
  { url: 'maautobodychoice.com', speedbump: '' },
  { url: 'makeauturnnm.com', speedbump: '' },
  { url: 'makeauturnnm.net', speedbump: '' },
  { url: 'makeauturnnm.org', speedbump: '' },
  { url: 'naii.org', speedbump: '' },
  { url: 'natcatcentral.com', speedbump: '' },
  { url: 'natcatcentral.net', speedbump: '' },
  { url: 'natcatcentral.org', speedbump: '' },
  { url: 'pciaa.org', speedbump: '' },
  { url: 'pciaa-dc.net', speedbump: '' },
  { url: 'pciaa-il.net', speedbump: '' },
  { url: 'pciadvocacy.com', speedbump: '' },
  { url: 'pciadvocacy.net', speedbump: '' },
  { url: 'pciadvocacy.org', speedbump: '' },
  { url: 'pciadvocacyinaction.com', speedbump: '' },
  { url: 'pciadvocacyinaction.net', speedbump: '' },
  { url: 'pciadvocacyinaction.org', speedbump: '' },
  { url: 'pciaffiliates.com', speedbump: '' },
  { url: 'pciaffiliates.net', speedbump: '' },
  { url: 'pciaffiliates.org', speedbump: '' },
  { url: 'pcipac.net', speedbump: '' },
  { url: 'pcipac.org', speedbump: '' },
  { url: 'pcipacdonate.net', speedbump: '' },
  { url: 'pcipacdonate.org', speedbump: '' },
  { url: 'pcipoliticalaction.com', speedbump: '' },
  { url: 'pcipoliticalaction.org', speedbump: '' },
  { url: 'pcivideocontest.com', speedbump: '' },
  { url: 'pcivideocontest.net', speedbump: '' },
  { url: 'pcivideocontest.org', speedbump: '' },
  { url: 'plan2bready.net', speedbump: '' },
  { url: 'plan2bready.org', speedbump: '' },
  { url: 'rebuildwithresiliance.com', speedbump: '' },
  { url: 'rebuildwithresiliance.net', speedbump: '' },
  { url: 'rebuildwithresiliance.org', speedbump: '' },
  { url: 'rebuildwithresilience.com', speedbump: '' },
  { url: 'rebuildwithresilience.net', speedbump: '' },
  { url: 'rebuildwithresilience.org', speedbump: '' },
  { url: 'riautobodychoice.com', speedbump: '' },
  { url: 'stophigherautobodyrepaircosts.com', speedbump: '' },
  { url: 'stophigherautobodyrepaircosts.net', speedbump: '' },
  { url: 'stophigherautobodyrepaircosts.org', speedbump: '' },
  { url: 'stoptexaslawsuitabuse.com', speedbump: '' },
  { url: 'voteprobusiness.com', speedbump: '' },
  { url: 'wildfirerealitycheck.com', speedbump: '' },
  { url: 'wildfirerealitycheck.info', speedbump: '' },
  { url: 'wildfirerealitycheck.net', speedbump: '' },
  { url: 'wildfirerealitycheck.org', speedbump: '' },
  { url: 'wildfirerealitychecklist.com', speedbump: '' },
  { url: 'wildfirerealitychecklist.net', speedbump: '' },
  { url: 'wildfirerealitychecklist.org', speedbump: '' },
  { url: 'http://pciaa.activismcentral.com/ri-auto-body-choice', speedbump: '' },
  { url: 'http://pciaa.activismcentral.com/wildfire-checklist', speedbump: '' },
  { url: 'media.api2pdf.com', speedbump: '' },
  { url: 'https://apci.wistia.com/medias/r8kedfcvvm', speedbump: '' },
];

const linksRegEx = links.map(link => {
  const { url } = link;
  const regex = new RegExp(`^(https?://)?(www2?.)?${url}(?![.A-Za-z1-9])`, 'i');
  return { ...link, regex };
});

const findIncludeLink = href => {
  const link = linksRegEx.find(({ regex }) => regex.test(href));
  return link; // && link.speedbump === 'N';
};

export const validateExternalUrl = href => {
  const url = href && href.trim();
  return url && /^https?:\/\//.test(url);
};

export const linkClickHandler = e => {
  const tag = e && e.target && e.target.tagName;
  if (tag && tag.toLowerCase() === 'a') {
    const href = e.target.getAttribute('href');
    const download = e.target.getAttribute('download');
    const target = e.target.getAttribute('target');
    if (validateExternalUrl(href) && !download) {
      e.preventDefault();
      if (!findIncludeLink(href)) {
        confirm({
          title: `
            By clicking Continue below, you will leave the APCIA website. 
            APCIA does not control the content, privacy policy, 
            and security practices of this this third-party site.
          `,
          keyboard: true,
          okText: 'Continue',
          cancelText: 'Cancel',
          onOk: () => {
            window.open(href, '_blank');
          },
        });
      } else if (target === '_blank') {
        window.open(href, '_blank');
      } else {
        window.location.href = href;
      }
    }
  }
};
