import React from 'react';

const XIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="0.75em" height="0.75em">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.01728147 7.01720129L0.5 7.01720129 0.5 8.98271853 7.01728147 8.98271853 7.01728147 15.5 8.98279871 15.5 8.98279871 8.98271853 15.5 8.98271853 15.5 7.01720129 8.98279871 7.01720129 8.98279871 0.5 7.01728147 0.5z"
      transform="rotate(45 9.414 4.586)"
    />
  </svg>
);

export default XIcon;
