import { css } from '@emotion/core';

import linkStyle from '@/src/shared/components/Link/style';
import { colors } from '@/styles';

const buttonStyle = css`
  font-weight: 600;
  border: 3px;
  border-radius: 20px !important;
  box-shadow: none !important;
  text-align: center !important;
  align-items: center;
  display: inline-flex;
  > span {
    line-height: 1;
  }
`;

const style = {
  size: {
    default: css`
      font-size: 1.8rem;
      line-height: 1;
      padding: 11px 30px;
      letter-spacing: 2px;
      text-transform: uppercase;
      height: 100%;
    `,
    small: css`
      font-size: 1.2rem !important;
      line-height: 2rem;
      padding: 6px 21px;
      height: 32px !important;
      .anticon {
        font-size: 1.2em !important;
      }
      svg {
        margin: 0 !important;
        transform: translateY(1px) !important;
      }
    `,
    medium: css`
      font-size: 1.8rem;
      line-height: 1;
      padding: 8px 30px !important;
      display: inline-flex;
      height: 100%;
    `,
    large: css`
      padding: 8px 65px !important;
      font-size: 1.8rem !important;
      height: 40px !important;
      font-weight: 600;
      letter-spacing: 2px;
    `,
    full: css`
      width: 100%;
      font-size: 1.6rem !important;
      justify-content: center;
      height: 40px !important;
      > i.anticon {
        font-size: 2rem;
        margin-right: 0.8rem;
      }
    `,
    normal: css`
      font-size: 1.6rem;
      line-height: 2.8rem !important;
      padding: 10px 24px;
      font-weight: 900 !important;
      height: 48px;
    `,
    link: css``,
  },
  type: {
    default: css`
      ${buttonStyle};
      background-color: ${colors.secondaryBlue} !important;
      color: ${colors.white} !important;
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.secondaryBlueDark} !important;
        color: ${colors.white} !important;
      }
    `,
    gray: css`
      ${buttonStyle};
      background-color: ${colors.accentGrayUltralight};
      color: ${colors.secondaryBlue};
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.accentGrayUltralight};
        color: ${colors.primaryBlue};
      }
    `,
    red: css`
      ${buttonStyle};
      background-color: ${colors.primaryRed};
      color: ${colors.white};
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.accentRustDark};
        color: ${colors.white};
      }
    `,
    transparent: css`
      background-color: transparent;
      padding: initial;
      &:hover,
      &:focus,
      &:active {
        color: ${colors.accentRustDark};
      }
    `,
    outlined: css`
      ${buttonStyle};
      background-color: transparent;
      color: ${colors.secondaryBlue} !important;
      border-style: solid !important;
      border-color: ${colors.secondaryBlue};
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.secondaryBlue} !important;
        border-color: ${colors.secondaryBlue} !important;
        color: ${colors.white} !important;
      }
    `,
    inverseOutlined: css`
      ${buttonStyle}
      background-color: transparent;
      border-style: solid !important;
      border-color: ${colors.white} !important;
      color: ${colors.white} !important;
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.white} !important;
        border-color: ${colors.white} !important;
        color: ${colors.black} !important;
      }
    `,
    darkOutlined: css`
      ${buttonStyle};
      background-color: ${colors.primaryBlue};
      color: ${colors.white} !important;
      border: 2px solid ${colors.primaryBlue} !important;
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.white} !important;
        border-color: ${colors.primaryBlue} !important;
        color: ${colors.primaryBlue} !important;
      }
    `,
    darkOutlinedInverse: css`
      ${buttonStyle};
      background-color: transparent;
      color: ${colors.primaryBlue} !important;
      border: 2px solid ${colors.primaryBlue} !important;
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.primaryBlue} !important;
        border-color: ${colors.primaryBlue} !important;
        color: ${colors.white} !important;
    `,
    link: css`
      ${linkStyle.link};
      color: ${colors.secondaryBlue};
    `,
    white: css`
      ${buttonStyle};
      background-color: ${colors.white};
      color: ${colors.secondaryBlue};
      border-style: solid !important;
      &:hover,
      &:focus,
      &:active {
        border-color: ${colors.white} !important;
        background-color: transparent !important;
        color: ${colors.white} !important;
      }
    `,
    whiteNoPsuedo: css`
      ${buttonStyle};
      background-color: ${colors.white};
      color: ${colors.secondaryBlue};
      border-style: solid !important;
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.accentGrayUltralight};
        color: ${colors.primaryBlue};
      }
    `,
    groupWhite: css`
      ${buttonStyle};
      background-color: ${colors.white} !important;
      color: ${colors.secondaryBlue};
      border-style: solid !important;
      border-color: ${colors.accentGrayLight};
      &:hover,
      &:focus,
      &:active {
        border-color: ${colors.secondaryBlue} !important;
      }
    `,
    disabled: css`
      background-color: ${colors.accentGrayMedium} !important;
      color: ${colors.white} !important;
      border-radius: 9999px;
      border: none !important;
    `,
  },
  inGroupStyle: css`
    padding: 0px 8.75px !important;
    font-size: 1.2rem !important;
    font-weight: bold;
    border-radius: 0;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
  `,
  group: css`
    display: flex;
    align-items: center;
    .ant-btn {
      padding: 0px 8.75px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 0 !important;
      box-shadow: none;
      border-color: ${colors.accentGrayLight};
      border-style: solid;
      border-width: 1px;
      color: ${colors.secondaryBlue};
      background-color: ${colors.accentGrayUltralight};
      &:first-of-type {
        border-top-left-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
      }
      &:last-child {
        border-top-right-radius: 2px !important;
        border-bottom-right-radius: 2px !important;
      }
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.accentGrayUltralight};
        color: ${colors.primaryBlue};
      }
    }
  `,
};

export default style;
