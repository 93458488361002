import { Icon } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { checkAccess } from '@/src/shared/constants';

import { getTitleFromPath } from '../utils';
import MenuItem from './MenuItem';

export const PadLock = ({ locked = true, isLoggedIn, role, memberLevels }) => {
  const hasAccess = checkAccess(memberLevels, role);
  if (!locked) return null;
  return isLoggedIn && hasAccess ? (
    <Icon type="unlock" theme="filled" />
  ) : (
    <Icon type="lock" theme="filled" />
  );
};

const NavItem = ({ memberLevels, role, isLoggedIn, as, path, type, locked, title, ...props }) => {
  const { dispatch, ..._props } = props;
  const router = useRouter();
  return (
    <MenuItem type={type} {..._props} {...path === router.route && { 'data-selected': true }}>
      <Link href={as || path} as={path}>
        <a>
          <PadLock
            locked={locked}
            isLoggedIn={isLoggedIn}
            role={role}
            memberLevels={memberLevels}
          />
          {title || getTitleFromPath(path)}
        </a>
      </Link>
    </MenuItem>
  );
};

NavItem.propTypes = {
  path: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['default', 'subTitle', 'subItem', 'bold']),
  locked: PropTypes.bool,
  title: PropTypes.string,
  memberLevels: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

NavItem.defaultProps = {
  type: 'subItem',
  locked: true,
  title: undefined,
  memberLevels: false,
};

export default connect(
  state => {
    const { auth } = state;
    const { isLoggedIn, role } = auth;
    return { isLoggedIn, role };
  },
  null
)(NavItem);
