import { useAuth0 } from '@auth0/auth0-react';
import { css, jsx } from '@emotion/core';
import Head from 'next/head';
import Router from 'next/router';
import React from 'react';

import { auth0DatabaseClient } from '@/src/auth/utils';
import Button from '@/src/shared/components/Button';
import Heading from '@/src/shared/components/Heading';
import Logo404 from '@/src/shared/components/Icon/_icons/404icon';
import { bp, colors } from '@/styles';

const backgroundImagePath = '/static/images/404Message_Image.jpg';
const apciaFlag = '/static/APCIAFlag.png';

const style = {
  error: css`
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media ${bp.md} {
      height: 739px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  `,
  errorContent: css`
    @media ${bp.md} {
      display: flex;
      padding: 0 20px;
    }
    svg {
      display: none;
      @media ${bp.md} {
        display: inline-block;
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 1;
      }
    }
  `,
  errorContentRight: css`
    margin-left: 0px;
    text-align: center;
    max-width: 500px;
    @media ${bp.md} {
      margin-left: 80px;
      text-align: left;
    }
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
    vertical-align: top;
    position: relative;
    z-index: 2;
  `,
  logoFlag: css`
    padding: 39px 0;
  `,
  errorHeading: size => css`
    font-size: ${size}rem;
    line-height: 1;
    padding-bottom: 16px;
    a {
      font-size: ${size}rem;
      color: ${colors.secondaryBlue};
    }
    p {
      line-height: 1.6;
      padding-bottom: 8px;
    }
  `,
  errorSubheading: css`
    font-size: 2rem;
    font-weight: normal;
    line-height: 1.6;
    padding-bottom: 24px;
  `,
  btnWrapper: css`
    display: flex;
    justify-content: center;
    @media ${bp.md} {
      justify-content: flex-start;
    }
    > button:nth-child(2) {
      margin-left: 16px;
    }
  `,
};

const ERROR_CONTENTS = {
  401.4: {
    bgImage: apciaFlag,
    header: (
      <div>
        <p>Restricted Access</p>
        <p>
          This content is not included in your company’s APCIA subscription. If you believe this is
          an error, please email
          <a href="mailto:membership@apci.org"> membership@apci.org</a>.
        </p>
      </div>
    ),
    headerSize: 2.1,
    subHeader: 'Click the button below to return to the APCIA home page.',
    buttonText: 'Go Home',
    handleClick: () => Router.push('/', '/', { shallow: true }),
    extraBtn: false,
  },
  401.5: {
    bgImage: apciaFlag,
    header: (
      <div>
        <p>
          The invoice portal is only available to members with the appropriate level of access. If
          you believe this is an error, please email{' '}
          <a href="mailto:membership@apci.org"> membership@apci.org</a>.
        </p>
      </div>
    ),
    headerSize: 2.1,
    subHeader: 'Click the button below to return to the APCIA home page.',
    buttonText: 'Go Home',
    handleClick: () => Router.push('/', '/', { shallow: true }),
    extraBtn: false,
  },
  401.3: {
    bgImage: apciaFlag,
    header: 'This page contains an added layer of security. A security code is required.',
    headerSize: 2.1,
    subHeader: 'Click the button below to return to the APCIA home page.',
    buttonText: 'Go Home',
    handleClick: () => Router.push('/', '/', { shallow: true }),
    extraBtn: false,
  },
  401.2: {
    bgImage: apciaFlag,
    header: 'This invoice is not associated with your account.',
    headerSize: 2.1,
    subHeader: 'Click the button below to return to the Invoice Portal.',
    buttonText: 'Invoice Portal',
    handleClick: () =>
      Router.push('/account/invoice-portal', '/account/invoice-portal', { shallow: true }),
    extraBtn: false,
  },
  401.1: {
    bgImage: apciaFlag,
    header: 'Your Membership level does not allow access to this document.',
    headerSize: 2.1,
    subHeader: 'Click the button below to return to the APCIA home page.',
    buttonText: 'Go Home',
    handleClick: () => Router.push('/', '/', { shallow: true }),
    extraBtn: false,
  },
  401: {
    bgImage: apciaFlag,
    header: 'This page is for APCIA members with an active login.',
    headerSize: 2.1,
    subHeader: 'Click the button below to register for an account or to login.',
    buttonText: 'Register',
    handleClick: () => Router.push('/register', '/register', { shallow: true }),
    extraBtn: true,
  },
  404: {
    bgImage: backgroundImagePath,
    header: 'Sorry',
    headerSize: 10,
    subHeader: `Unlike the other pages on the APCIA website, this page does not contain
      mission-critical information for property casualty insurance professionals.`,
    buttonText: 'Go Back',
    handleClick: () => Router.back(),
    extraBtn: false,
  },
  500: {
    bgImage: backgroundImagePath,
    header: 'Sorry',
    headerSize: 10,
    subHeader: `There was an error loading the page, please reach out to APCIA support
    for more information.`,
    buttonText: 'Return home',
    handleClick: () =>
      auth0DatabaseClient.logout({
        returnTo: window.location.origin,
        client_id: process.env.AUTH0_CLIENT_ID,
      }),
    extraBtn: false,
  },
};

function ErrorPage(props) {
  const { status = 404 } = props;
  const { loginWithRedirect } = useAuth0();

  const {
    bgImage,
    header,
    headerSize,
    subHeader,
    buttonText,
    extraBtn,
    handleClick,
  } = ERROR_CONTENTS[status];

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <div css={style.error} style={{ backgroundImage: `url('${bgImage}')` }}>
        <div css={style.errorContent}>
          <Logo404 />
          <div css={style.errorContentRight}>
            <Heading type={1} theme="white" weight="bolder" css={style.errorHeading(headerSize)}>
              {header}
            </Heading>
            <Heading type={2} theme="white" css={style.errorSubheading}>
              {subHeader}
            </Heading>
            <div css={style.btnWrapper}>
              {extraBtn && (
                <Button
                  htmlType="button"
                  theme="gray"
                  onClick={() =>
                    loginWithRedirect({
                      appState: {
                        returnTo: '/',
                      },
                    })
                  }
                >
                  Login
                </Button>
              )}
              <Button htmlType="button" onClick={handleClick}>
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
