import React from 'react';

const LinkedIn = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx="20" cy="20" r="20" />
      <path
        d="M14.271 15.771v13.55H9.76V15.77h4.511zm.288-4.183c.009.665-.221 1.221-.69 1.668-.47.446-1.088.67-1.853.67h-.028c-.747 0-1.349-.224-1.804-.67-.456-.447-.684-1.003-.684-1.668 0-.675.235-1.233.704-1.675.47-.442 1.082-.663 1.839-.663.756 0 1.363.221 1.818.663.456.442.688 1 .698 1.675zM30.5 21.555v7.765h-4.498v-7.246c0-.957-.185-1.706-.554-2.249-.369-.542-.945-.813-1.73-.813-.573 0-1.054.157-1.442.471a2.908 2.908 0 0 0-.868 1.17c-.1.273-.15.642-.15 1.107v7.56H16.76c.018-3.636.027-6.585.027-8.845 0-2.26-.004-3.61-.014-4.047l-.013-.657h4.498v1.97h-.028a5.95 5.95 0 0 1 .561-.766 5.83 5.83 0 0 1 .772-.711c.324-.256.72-.454 1.19-.595a5.42 5.42 0 0 1 1.565-.212c1.559 0 2.812.517 3.76 1.552.948 1.034 1.422 2.55 1.422 4.546z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default LinkedIn;
