import { CLEAR_NAMED_PARTY_SEARCH_CRITERIA, SET_NAMED_PARTY_SEARCH_CRITERIA } from '../types';

const DEFAULT_SEARCH_CRITERIA_STATE = {
  CaseNames: [],
  States: [],
  Issues: [],
  Statuses: [],
};

const SearchCriteriaReducer = (state = DEFAULT_SEARCH_CRITERIA_STATE, action) => {
  const { type } = action;
  switch (type) {
    case SET_NAMED_PARTY_SEARCH_CRITERIA:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_NAMED_PARTY_SEARCH_CRITERIA:
      return DEFAULT_SEARCH_CRITERIA_STATE;
    default:
      return state;
  }
};
export default SearchCriteriaReducer;
