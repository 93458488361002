import React from 'react';

const SvgBank = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M19.957.122a1.024 1.024 0 011.227.197c1.388 1.455 4.205 3.764 5.855 3.764 1.548 0 5.888-2.405 8.26-3.921a1.028 1.028 0 011.557 1.089l-1.849 8.32c1.566-1.555 3.89-3.246 6.61-3.246a1.028 1.028 0 010 2.056c-2.56 0-4.811 2.15-6.03 3.585 9.19.48 11.178-2.862 11.198-2.898.273-.487.89-.678 1.383-.417.493.26.694.857.444 1.357-.23.46-2.323 4.067-11.217 4.067-.26 0-.548-.015-.82-.022 9.17 6.198 15.718 22.2 15.718 40.92 0 .567-.46 1.027-1.027 1.027H5.028C4.46 56 4 55.54 4 54.972c0-20.233 7.65-37.291 17.992-42.226L19.437 1.25c-.1-.45.113-.913.52-1.13zm12.779 14.15c-3-1.212-6.178-1.213-9.18.001-.004.002-.01.001-.015.003C13.735 18.211 6.314 34.43 6.06 53.945h44.17c-.252-19.518-7.674-35.738-17.481-39.67zm-5.103 11.416c.567 0 1.027.46 1.027 1.028v1.173c1.768.46 3.083 2.055 3.083 3.964a1.028 1.028 0 01-2.055 0 2.057 2.057 0 00-2.055-2.055 2.057 2.057 0 00-2.055 2.055c0 1.134.922 2.055 2.055 2.055a4.115 4.115 0 014.11 4.11c0 1.91-1.315 3.505-3.083 3.965v1.173a1.028 1.028 0 01-2.055 0v-1.173c-1.767-.46-3.082-2.055-3.082-3.965a1.028 1.028 0 012.055 0c0 1.134.922 2.055 2.055 2.055a2.057 2.057 0 002.055-2.055 2.057 2.057 0 00-2.055-2.055 4.115 4.115 0 01-4.11-4.11c0-1.91 1.315-3.504 3.082-3.964v-1.173c0-.568.46-1.028 1.028-1.028zM34.329 3.15c-2.187 1.261-5.507 2.988-7.29 2.988-1.622 0-3.464-1.107-4.903-2.217l1.786 8.04c.17-.054.343-.095.514-.142l.082-.022c.326-.087.654-.163.982-.226.1-.02.2-.04.3-.058.261-.045.523-.08.786-.109.14-.016.28-.037.422-.049.261-.021.523-.03.784-.036.119-.003.236-.016.355-.016.118 0 .235.013.353.016.262.007.524.015.786.036.14.012.28.033.42.049.263.03.527.064.79.109.098.017.195.037.293.057.332.064.663.14.992.228l.07.02c.174.047.348.088.52.142z"
    />
  </svg>
);

export default SvgBank;
