import { css, Global, jsx } from '@emotion/core';
import { Menu, Modal } from 'antd';
import React, { PureComponent } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';

import buttonStyle from '@/src/shared/components/Button/style';
import Container from '@/src/shared/components/Container';
import { assessmentUser, pciEveryone } from '@/src/shared/constants';
import { bp, colors } from '@/styles';
import { primaryBlue } from '@/styles/utilities/colors';

import AccountMenu from './components/AccountMenu';
import About from './NavAbout';
import Account from './NavAccount';
import Advocacy from './NavAdvocacy';
import Compliance from './NavCompliance';
import Events from './NavEvents';
import Home from './NavHome';
import Press from './NavPress';
import Search from './NavSearch';

const isServer = typeof window === 'undefined';
const navHeight = '80px';
const style = {
  global: shouldlockScroll => css`
    .ant-menu-submenu-popup {
      @media ${bp.lg} {
        max-height: calc(100vh - 145px);
        overflow-y: auto;
      }
      &:not(.account-dropdown) {
        margin-top: 30px;
        border-radius: 0;
        box-shadow: 0 6px 12px 0 rgba(35, 32, 32, 0.1);
        > .ant-menu-sub {
          width: 413px;
          padding: 24px 52px;
        }
        &.ant-menu-large > .ant-menu-sub {
          width: 613px;
        }
        &.ant-menu-xlarge {
          overflow-y: visible;
          > .ant-menu-sub {
            width: 768px;
          }
          &::after {
            position: absolute;
            content: '';
            width: 0px;
            height: 0px;
            border-right: 40px solid transparent;
            border-bottom: 50px solid transparent;
            border-left: 300px solid transparent;
            top: -50px;
            right: 0px;
          }
        }
      }
      .anticon-export {
        margin-left: 8px;
      }
      .external-link-icon {
        margin-left: 8px;
      }
    }
    .ant-menu-inline {
      border-right: 0;
      .ant-menu-submenu-title {
        &:hover {
          color: ${colors.primaryRed};
        }
        .ant-menu-submenu-arrow {
          right: 5px;
          top: calc(50% + 4px);
          &::before,
          &::after {
            width: 12px !important;
            background: ${colors.primaryBlue};
          }
          &::before {
            transform: translate(3px, -2px) rotate(-50deg);
          }
          &::after {
            transform: translate(-3px, -2px) rotate(50deg);
          }
        }
        &:hover {
          .ant-menu-submenu-arrow {
            &::before,
            &::after {
              background: ${colors.primaryRed};
            }
          }
        }
      }
      > .ant-menu-submenu-open {
        > .ant-menu-submenu-title {
          > .ant-menu-submenu-arrow {
            &::before {
              transform: translate(3px, -2px) rotate(50deg) !important;
            }
            &::after {
              transform: translate(-4px, -2px) rotate(-50deg) !important;
            }
          }
        }
      }
    }
    .ant-menu-item > a {
      color ${primaryBlue}
    }
    .ant-menu-item-selected {
      background-color: transparent !important;
      &::after {
        display: none;
      }
    }
    .ant-menu-overflowed-submenu {
      display: none !important;
    }
    ${
      shouldlockScroll
        ? css`
            @media (max-width: 991px) {
              html,
              body {
                overflow: hidden;
              }
            }
          `
        : css``
    }
  `,
  nav: (hide, noExpand) => css`
    box-shadow: 0 4px 8px 0 rgba(35, 32, 32, 0.06);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: white;
    overflow: hidden auto;
    height: 100vh;
    padding-bottom: 8px;
    li {
      padding: 0;
      margin: 0;
    }
    ${hide
      ? css`
          display: none !important;
          & + * + * {
            margin-top: 0 !important;
          }
        `
      : css``};

    .ant-scrolling-effect & {
      position: absolute;
    }
    @media ${bp.lg} {
      padding-bottom: 0;
      height: auto;
      overflow: hidden;
    }
    @media print {
      display: none;
    }
    ${noExpand
      ? css`
          @media (max-width: 991px) {
            padding-bottom: 0 !important;
            height: auto;
            .hideIfClosed {
              display: none !important;
            }
          }
        `
      : css``}
  `,
  navOpen: css`
    padding-bottom: 130px;
  `,
  menu: css`
    display: flex;
    flex-direction: column;
    overflow: visible;
    @media ${bp.lg} {
      height: ${navHeight};
      align-items: center;
      flex-direction: row;
    }
    border-bottom: none;
  `,
  mobileAccountMenu: css`
    @media ${bp.lg} {
      display: none;
    }
  `,
};

class Nav extends PureComponent {
  state = {
    isOpen: false,
    routeStart: '',
    isLg: false,
  };

  handleResize = () => {
    this.matchMedia = window.matchMedia(bp.lg);
    this.setState({
      isLg: this.matchMedia.matches,
    });
  };

  componentDidMount() {
    const { router } = this.props;
    this.setRouteStart(router.route);
    router.events.on('routeChangeStart', this.handleRouteChangeStart);

    if (typeof window !== 'undefined') {
      this.matchMedia = window.matchMedia(bp.lg);
      this.matchMedia.addEventListener('change', this.handleResize);
      this.setState({
        isLg: this.matchMedia.matches,
      });
    }
  }

  componentWillUnmount() {
    this.matchMedia.removeEventListener('change', this.handleResize);
  }

  handleRouteChangeStart = r => {
    this.setRouteStart(r);
    this.setState({ isOpen: false });
  };

  setRouteStart = route => {
    const [, routeStart] = /^\/([^/]*)/.exec(route);
    this.setState({ routeStart });
  };

  handleToggleMenuClick = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  render() {
    const { isOpen, routeStart, isLg } = this.state;
    const {
      isLoggedIn,
      role,
      router: { route },
    } = this.props;
    const isPreview = route.indexOf('/preview') === 0;

    const isAdmin = Array.isArray(role) && role.includes(pciEveryone);
    const isAssessmentUser = Array.isArray(role) && role.includes(assessmentUser);

    // merge styles and hide nav if mobile and nav is closed
    const shouldHide = !isLg && !isOpen;
    const navcss = [style.nav(isPreview, shouldHide || isServer)];

    // merge styles and lock the body if mobile and nav is open
    const shouldlockScroll = !isLg && isOpen;
    if (shouldlockScroll) {
      navcss.push(style.navOpen);
    }
    return (
      <nav css={navcss}>
        <Global styles={style.global(shouldlockScroll)} />
        <Container>
          <Menu
            mode={isLg ? 'horizontal' : 'inline'}
            css={style.menu}
            forceSubMenuRender
            onClick={(item, key, keyPath, domEvent) => {
              console.log({ item, key, keyPath, domEvent });
            }}
            selectedKeys={[routeStart]}
          >
            <Home
              key="home"
              isSingle
              isLg={isLg}
              isOpen={isOpen}
              handleToggleMenuClick={this.handleToggleMenuClick}
            />
            <Advocacy isLg={isLg} key="advocacy" isSelected={routeStart === 'advocacy'} />
            <Compliance isLg={isLg} key="compliance" isSelected={routeStart === 'compliance'} />
            <Events isLg={isLg} key="events" isSelected={routeStart === 'events'} />
            <Press isLg={isLg} key="media" isSelected={routeStart === 'media'} />
            <About
              isLg={isLg}
              key="about"
              isSelected={routeStart === 'about'}
              isLoggedIn={isLoggedIn}
            />
            <Search isLg={isLg} key="search" isSelected={routeStart === 'search'} />
            <Account
              isLg={isLg}
              isLoggedIn={isLoggedIn}
              handleLogInOutCLick={this.handleLogInOutCLick}
              key="account"
              role={role}
              isAdmin={isAdmin}
              isAssessmentUser={isAssessmentUser}
            />
            <div css={style.mobileAccountMenu} className="hideIfClosed">
              <AccountMenu
                isAdmin={isAdmin}
                isAssessmentUser={isAssessmentUser}
                handleLogInOutCLick={this.handleLogInOutCLick}
                isLoggedIn={isLoggedIn}
              />
            </div>
          </Menu>
        </Container>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  const {
    auth: { isLoggedIn, role },
  } = state;
  return { isLoggedIn, role };
}

export default connect(mapStateToProps)(Nav);
