import React from 'react';

const SvgPlans = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M55.034 9c.534 0 .966.432.966.966v36.69a.965.965 0 01-.966.965H.966A.965.965 0 010 46.655V9.965C0 9.433.432 9 .966 9zm-.965 1.931H1.931V45.69h52.138V10.93zm-8.61 1.931a.966.966 0 01.953 1.126 4.833 4.833 0 004.76 5.633c.534 0 .966.432.966.965v15.448a.965.965 0 01-.966.966 4.833 4.833 0 00-4.76 5.633.965.965 0 01-.953 1.126H10.541a.966.966 0 01-.953-1.126A4.833 4.833 0 004.828 37a.965.965 0 01-.966-.966V20.586c0-.533.432-.965.966-.965a4.833 4.833 0 004.76-5.633.965.965 0 01.953-1.126zm-1.045 1.931H11.586c0 3.4-2.522 6.22-5.793 6.69v13.655c3.271.47 5.793 3.29 5.793 6.69h32.828c0-3.4 2.522-6.22 5.793-6.69V21.483c-3.271-.47-5.793-3.29-5.793-6.69zM38.62 36.034v1.932H16.414v-1.932H38.62zm0-4.827v1.93H16.414v-1.93H38.62zm3.878-8.69v1.931H12.552v-1.93h29.947zm-8.706-3.862v1.931H22.207v-1.93h11.586z"
    />
  </svg>
);

export default SvgPlans;
