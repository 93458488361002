import { isEmpty, isObject } from 'lodash';

import request from '@/utils/axios';

import * as TYPES from '../types/recentSearchTypes';

const start = () => ({ type: TYPES.GET_RECENT_SEARCH_START });
const fail = () => ({ type: TYPES.GET_RECENT_SEARCH_FAILURE });
const err = () => ({ type: TYPES.GET_RECENT_SEARCH_ERROR });
const succeed = recent => ({
  type: TYPES.GET_RECENT_SEARCH_SUCCESS,
  payload: {
    recentSearch: recent,
  },
});

/**
 * @param {object} response -
 * @param {number} response.status -
 * @param {object} response.data -
 * @returns {boolean} isBad
 */
function hasBadResponse(response) {
  const { status, data } = response;
  // Check for errors in the response and handle before saving data
  return (
    status < 200 || // response status is not in 200 range
    status >= 300 ||
    !isObject(data) || // expecting { Searches: [{ SearchId: Number }] }
    !('Searches' in data) ||
    !Array.isArray(data.Searches)
  );
}

/**
 *
 * @param {*} personId -
 * @returns {function} handleAction
 */
export function loadRecentSearch() {
  return async function handleAction(dispatch, getState) {
    try {
      const { home, auth } = getState();
      const { userinfo } = auth;
      const { PersonID } = userinfo;
      // const PersonID = 47561;
      const userInfoIsEmpty = isEmpty(userinfo);
      const personIdIsFalsey = !PersonID;
      const recentSearchIsCached = !isEmpty(home.recentSearch);

      if (userInfoIsEmpty || personIdIsFalsey || recentSearchIsCached) {
        // Not following through with the action will be handled with the UI, not errors.
        return;
      }

      // fetch the recent and saved searches
      dispatch(start());

      const recentSearchResponse = await request({
        url: `cms/recentsearch`,
        params: { personId: PersonID },
        triggerLoad: false,
        allow401: true,
      });

      if (hasBadResponse(recentSearchResponse)) {
        dispatch(fail());
      } else {
        const recentSearch =
          recentSearchResponse.data.Searches.length &&
          recentSearchResponse.data.Searches[0].SearchId
            ? recentSearchResponse.data.Searches[0]
            : {};
        dispatch(succeed(recentSearch));
      }
    } catch (error) {
      dispatch(err());
      console.log(error);
    }
  };
}
