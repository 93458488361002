import { clearPageLoadError } from '@/src/pageLoad/redux/actions';
import { LOAD_STATUS } from '@/src/pageLoad/redux/reducers';

const PageRouteHandler = () => {
  if (window.__NEXT_REDUX_STORE__) {
    const {
      pageLoad: { loadStatus },
    } = window.__NEXT_REDUX_STORE__.getState();
    if (loadStatus === LOAD_STATUS.ERROR) {
      window.__NEXT_REDUX_STORE__.dispatch(clearPageLoadError());
    }
  }
};
export default PageRouteHandler;
