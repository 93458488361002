import { schema } from 'normalizr';

// prettier-ignore-start
const stateEntity = new schema.Entity('States', {}, { idAttribute: 'StateCode' });
const lobEntity = new schema.Entity('LinesOfBusiness', {}, { idAttribute: 'LobCode' });
const subjectEntity = new schema.Entity('Subjects', {}, { idAttribute: 'SubjectCode' });
const publicationEntity = new schema.Entity('Publications', {}, { idAttribute: 'PublicationCode' });
const dayRangeEntity = new schema.Entity('DayRanges', {}, { idAttribute: 'DayRangeCode' });
const dateRangeEntity = new schema.Entity('DateRanges', {}, { idAttribute: 'DayRangeCode' });
const postingTypeEntity = new schema.Entity('PostingTypes', {}, { idAttribute: 'Cat1Id' });
const committeesEntity = new schema.Entity('Committees', {}, { idAttribute: 'CommitteeCode' });
// prettier-ignore-end

const fieldOptionsSchema = {
  States: [stateEntity],
  LinesOfBusiness: [lobEntity],
  Subjects: [subjectEntity],
  Publications: [publicationEntity],
  DayRanges: [dayRangeEntity],
  DateRanges: [dateRangeEntity],
  PostingTypes: [postingTypeEntity],
  Committees: [committeesEntity],
};

export default fieldOptionsSchema;
