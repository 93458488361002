import {
  LOAD_SEARCH_RESULTS_BEGIN,
  LOAD_SEARCH_RESULTS_ERROR,
  LOAD_SEARCH_RESULTS_SUCCESS,
} from '../types/searchResultsTypes';

const DEFAULT_SEARCH_RESULTS_STATE = {
  isLoadingResults: false,
  lastLoadSearchId: null,
  Results: [],
  Count: 0,
  activeSearchItems: null,
  hasSearched: false,
};

// eslint-disable-next-line import/prefer-default-export
export const searchResults = (state = DEFAULT_SEARCH_RESULTS_STATE, action) => {
  const { type } = action;

  switch (type) {
    case LOAD_SEARCH_RESULTS_BEGIN:
      return {
        ...state,
        isLoadingResults: action.payload.isLoadingResults,
        lastLoadSearchId: action.payload.lastLoadSearchId,
      };
    case LOAD_SEARCH_RESULTS_ERROR:
      return {
        ...state,
        isLoadingResults: action.payload.isLoadingResults,
        Results: [],
        Count: 0,
        hasSearched: true,
      };
    case LOAD_SEARCH_RESULTS_SUCCESS:
      // debugger;
      return {
        ...state,
        isLoadingResults: action.payload.isLoadingResults,
        Results: action.payload.Results,
        Count: action.payload.Count,
        activeSearchItems: action.payload.searchItems,
        hasSearched: true,
      };
    default:
      return state;
  }
};
