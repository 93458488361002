import React from 'react';

const SvgTech = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <g fill={fill} fillRule="evenodd">
      <path d="M43.897 39.897a4 4 0 01-4 4h-8.103v2.413l2.739 4.105-.016-.001c-.744 0-1.383.454-1.654 1.1l-2.901-4.346a1 1 0 01-.168-.555v-2.716h-3.587v2.716a1 1 0 01-.11.453l-.059.102-2.9 4.347a1.794 1.794 0 00-1.655-1.101h-.015l2.739-4.104v-2.413h-8.104a4 4 0 01-4-4v-8.104H9.69l-4.105 2.74.001-.016c0-.744-.454-1.383-1.1-1.654l4.346-2.901a1 1 0 01.555-.169h2.716v-3.587H9.387a1 1 0 01-.454-.108l-.101-.06-4.346-2.901c.646-.271 1.1-.91 1.1-1.654v-.017l4.104 2.74h2.413v-8.103a4 4 0 014-4h8.104V9.69l-2.74-4.105.016.001c.744 0 1.383-.454 1.654-1.1l2.901 4.346a1 1 0 01.169.555v2.716h3.587V9.387a1 1 0 01.108-.454l.06-.101 2.901-4.346c.271.646.91 1.1 1.654 1.1h.017l-2.74 4.104v2.413h8.103a4 4 0 014 4v8.103h2.413l4.104-2.74v.017c0 .744.454 1.383 1.1 1.654l-4.346 2.901-.101.06a1 1 0 01-.454.108h-2.716v3.587h2.716a1 1 0 01.454.11l.101.059 4.346 2.901c-.646.271-1.1.91-1.1 1.654l.001.017-4.105-2.74-2.413-.001v8.104zm-4-25.794H16.103a2 2 0 00-2 2v23.794a2 2 0 002 2h23.794a2 2 0 002-2V16.103a2 2 0 00-2-2z" />
      <path d="M34.517 7.586a3.793 3.793 0 100-7.586 3.793 3.793 0 000 7.586zm0-2a1.793 1.793 0 110-3.586 1.793 1.793 0 010 3.586zm-13.034 2a3.793 3.793 0 110-7.586 3.793 3.793 0 010 7.586zm0-2a1.793 1.793 0 100-3.586 1.793 1.793 0 000 3.586zm13.034 42.828a3.793 3.793 0 110 7.586 3.793 3.793 0 010-7.586zm0 2a1.793 1.793 0 100 3.586 1.793 1.793 0 000-3.586zm-13.034-2a3.793 3.793 0 100 7.586 3.793 3.793 0 000-7.586zm0 2a1.793 1.793 0 110 3.586 1.793 1.793 0 010-3.586zM3.793 25.276a3.793 3.793 0 100-7.586 3.793 3.793 0 000 7.586zm0-2a1.793 1.793 0 110-3.586 1.793 1.793 0 010 3.586zm0 15.03a3.793 3.793 0 110-7.586 3.793 3.793 0 010 7.586zm0-2a1.793 1.793 0 100-3.586 1.793 1.793 0 000 3.586zm48.49-11.03a3.793 3.793 0 100-7.586 3.793 3.793 0 000 7.586zm0-2a1.793 1.793 0 110-3.586 1.793 1.793 0 010 3.586zm0 15.03a3.793 3.793 0 110-7.586 3.793 3.793 0 010 7.586zm0-2a1.793 1.793 0 100-3.586 1.793 1.793 0 000 3.586z" />
    </g>
  </svg>
);

export default SvgTech;
