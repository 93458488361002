import { useAuth0 } from '@auth0/auth0-react';
// import { useUser } from '@auth0/nextjs-auth0/client';
import { css, jsx } from '@emotion/core';
import Router from 'next/router';
import NProgress from 'nprogress';
import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';

import Error from '@/pages/_error';
import { fillAuth0User, stopLoading } from '@/src/auth/redux/authSlice';
import { LOAD_ERROR_TYPES, LOAD_STATUS } from '@/src/pageLoad/redux/reducers';
import BackToTop from '@/src/shared/components/BackToTop';
import CookieBanner from '@/src/shared/components/CookieBanner';
import Footer from '@/src/shared/components/Footer';
import Nav from '@/src/shared/components/Nav';
import SavedSearchModal from '@/src/shared/components/SavedSearchModal';
import { bp } from '@/styles';
import { linkClickHandler } from '@/utils/linkHelper';
import pageRouteHandler from '@/utils/pageRouteHandler';
import { COOKIE_KEY, getCookie } from '@/utils/utils';

import LoadingPage from './shared/components/LoadingPage/LoadingPage';

const style = {
  printlogo: css`
    width: 250px;
  `,
  printlogoContainer: css`
    display: none;
    @media print {
      display: block;
      padding-bottom: 0px;
      padding-right: 16px;
      max-width: 1200px;
      margin: 0px 0px 0;
    }
  `,
  componentContainer: show => css`
    display: ${show ? 'block' : 'none'};
    height: 100%;
    @media screen {
      margin-top: 72px;
    }
    @media screen and ${bp.lg} {
      margin-top: 80px;
    }
  `,
  loadingContainer: show => css`
    display: ${show ? 'block' : 'none'};
    height: 100vh;
    width: 100vw;
    background: white;
  `,
  loadingPage: css`
    height: 100vh;
    width: 100vw;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

const isBrowser = typeof window !== 'undefined';

const hashchangeListener = () => {
  const offset = window.innerWidth > 992 ? 83 : 75;
  window.scrollBy(0, -offset);
};

const removeFinalSlash = url => url.replace(/\/$/, '');
let prevpage = '';

const cachedScrollPositions = [];
let shouldScrollRestore;

Router.events.on('beforeHistoryChange', () => {
  if (window.GA_INITIALIZED) {
    prevpage = removeFinalSlash(window.location.pathname);
  }
});

Router.events.on('routeChangeStart', () => {
  cachedScrollPositions.push([window.scrollX, window.scrollY]);
});

Router.events.on('routeChangeComplete', url => {
  pageRouteHandler();

  const _url = removeFinalSlash(url);
  if (window.GA_INITIALIZED && prevpage !== _url) {
    setTimeout(() => {
      ReactGA.set({
        page: removeFinalSlash(window.location.pathname),
        title: document.title,
      });
      ReactGA.pageview(_url);
      prevpage = _url;
    }, 1500);
  }

  if (shouldScrollRestore) {
    const { x, y } = shouldScrollRestore;
    setTimeout(() => {
      window.scrollTo(x, y);
    }, 1000);
    shouldScrollRestore = false;
  }
});

function App(props) {
  const { Component, pageProps, router, store } = props;

  const [timeout, setTimeout] = useState(new Date().getTime());
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuth0();
  const authLoading = useAuth0().isLoading;

  const {
    pageLoad: { errorType, loadRoutesCount, loadStatus },
    auth: { isLoggedIn, isLoading, error, role },
  } = useSelector(state => state);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fillAuth0User({ user }));
    }

    if (!authLoading && !isAuthenticated) {
      dispatch(stopLoading());
    }
  }, [isAuthenticated, authLoading]);

  const { pathname, query } = router;
  const loadingUser = isBrowser && isLoading;

  useEffect(() => {
    if (!isLoading && !authLoading && isAuthenticated && isLoggedIn && role && role.length === 0) {
      window.location.reload();
    }
  }, [isLoading, authLoading, isAuthenticated, isLoggedIn, role]);

  useEffect(() => {
    smoothscroll.polyfill();

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';

      Router.beforePopState(() => {
        const [x, y] = cachedScrollPositions.pop();
        shouldScrollRestore = { x, y };
        return true;
      });
    }

    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css?family=Lato:400,400i,700,900&display=swap';
    link.rel = 'stylesheet';
    document.body.appendChild(link);

    const { search } = window.location;
    const windowPathname = window.location.pathname;

    const searchQueries = search
      ? search
          .split('?')[1]
          .split('&')
          .reduce((acc, cur) => {
            const [key, val] = cur.split('=');
            acc[key] = val;
            return acc;
          }, {})
      : {};

    if (!isLoggedIn) {
      // GTM login event
      if (localStorage.getItem('loggingIn')) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'login' });
        localStorage.removeItem('loggingIn');
      }
      delete searchQueries.wrap_access_token;
      delete searchQueries.wrap_access_token_expires_in;
      const newSearch = Object.keys(searchQueries).length
        ? `?${Object.entries(searchQueries)
            .map(([key, val], i) => `${i !== 0 ? '&' : ''}${key}=${val}`)
            .join('')}`
        : '';
      if (store.persistor) {
        store.persistor.flush();
      }
      Router.replace(windowPathname + newSearch, windowPathname + newSearch, { shallow: true });
    }

    const hasAcceptedPrivacyNotice = getCookie(COOKIE_KEY);
    const { GA_ID } = process.env;
    if ((hasAcceptedPrivacyNotice && GA_ID && !window.GA_INITIALIZED) || (isLoggedIn && GA_ID)) {
      ReactGA.initialize(GA_ID);
      window.GA_INITIALIZED = true;
      ReactGA.set({ page: windowPathname.replace(/\/$/, '') });
      ReactGA.pageview(windowPathname.replace(/\/$/, ''));
    }

    // initialize GTM
    const tagManagerArgs = {
      gtmId: 'GTM-KMMTV3S',
    };
    TagManager.initialize(tagManagerArgs);

    document.body.addEventListener('click', linkClickHandler);
    if ('onhashchange' in window) {
      window.addEventListener('hashchange', hashchangeListener);
    }

    return () => {
      document.body.removeEventListener('click', linkClickHandler);
      if ('onhashchange' in window) {
        window.removeEventListener('hashchange', hashchangeListener);
      }
    };
  }, []);

  useEffect(() => {
    const INACTIVITY_TIMER_LIMIT = 900000;

    const setActivityTime = () => {
      if (new Date().getTime() - timeout >= INACTIVITY_TIMER_LIMIT) {
        window.location.reload(true);
      } else {
        setTimeout(new Date().getTime());
      }
    };

    document.body.addEventListener('mousemove', setActivityTime);
    document.body.addEventListener('keypress', setActivityTime);
    return () => {
      document.body.removeEventListener('mousemove', setActivityTime);
      document.body.removeEventListener('keypress', setActivityTime);
    };
  }, [timeout]);

  const countRef = useRef();

  useEffect(() => {
    const prevLoadRoutesCount = countRef.current;

    if (loadRoutesCount !== prevLoadRoutesCount) {
      if (loadRoutesCount === 0 && loadStatus === LOAD_STATUS.DONE) {
        NProgress.inc();
        NProgress.done();
      }
      if (
        prevLoadRoutesCount === 0 &&
        loadRoutesCount === 1 &&
        loadStatus === LOAD_STATUS.LOADING
      ) {
        NProgress.start();
      }
      if (prevLoadRoutesCount > loadRoutesCount && loadStatus !== LOAD_STATUS.DONE) {
        NProgress.set(loadRoutesCount / prevLoadRoutesCount);
        NProgress.inc();
      }
    }
  }, [loadRoutesCount]);

  useEffect(() => {
    countRef.current = loadRoutesCount;
  }, [loadRoutesCount]);

  const renderContent = () => {
    if (isBrowser && authLoading) return null;

    if (error) {
      return <Error status="500" />;
    }

    if (loadStatus === LOAD_STATUS.ERROR) {
      return <Error status={errorType === LOAD_ERROR_TYPES.AUTH_ERROR_NOT_ALLOW ? 401 : 404} />;
    }
    return <Component {...pageProps} router={router} />;
  };

  const changingPassword =
    pathname === '/account/change-password' && query && query.fp && query.fp === 'Y';

  return (
    <>
      {!changingPassword ? <Nav router={router} /> : null}

      <div css={style.printlogoContainer}>
        <img src="/static/logo.svg" alt="APCIA logo" css={style.printlogo} />
      </div>
      {!authLoading ? (
        <>
          <div
            css={style.componentContainer(
              [LOAD_STATUS.DONE, LOAD_STATUS.ERROR].includes(loadStatus)
            )}
          >
            {renderContent()}
          </div>
          <div css={style.loadingContainer(loadingUser || loadStatus === LOAD_STATUS.LOADING)} />
          <BackToTop />
          <Footer router={router} />
          <CookieBanner isLoggedIn={isLoggedIn} />
          <SavedSearchModal />
        </>
      ) : (
        <div css={style.loadingPage}>
          <LoadingPage />
        </div>
      )}
    </>
  );
}

export default App;
