import React from 'react';

const SvgClaims = ({ fill = 'currentColor', ...props }) => (
  <svg viewBox="0 0 56 56" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M44.706 0a7.137 7.137 0 017.13 7.13v41.74a7.137 7.137 0 01-7.13 7.13H12.13C8.2 56 5 52.802 5 48.87V7.13C5 3.198 8.199 0 12.13 0zm0 2.036H12.13A5.1 5.1 0 007.036 7.13v27.488h3.075v2.037H7.036V48.87a5.1 5.1 0 005.094 5.094h32.576A5.1 5.1 0 0049.8 48.87V36.655h-5.071v-2.037H49.8V7.13a5.1 5.1 0 00-5.094-5.094zM18.362 45.818v2.037h-6.235v-2.037h6.235zm4.965-4.073v2.037h-11.2v-2.037h11.2zm-5.07-7.127v2.037h-6.11v-2.037h6.11zm8.145 0v2.037h-6.11v-2.037h6.11zm8.145 0v2.037h-6.109v-2.037h6.11zm8.146 0v2.037h-6.11v-2.037h6.11zM27.4 24.436v2.037H12.127v-2.037H27.4zm5.118-4.072V22.4h-20.39v-2.036h20.39zM24.345 5.09c.563 0 1.019.456 1.019 1.018v8.146c0 .562-.456 1.018-1.019 1.018H11.11a1.018 1.018 0 01-1.018-1.018V6.109c0-.562.455-1.018 1.018-1.018zm-1.018 2.036h-11.2v6.11h11.2v-6.11z"
    />
  </svg>
);

export default SvgClaims;
